<div class="euromillones-play-core-wrapper">

  <ng-container *ngIf="device === devices.DESKTOP">
    
    <app-puntos-venta-desktop></app-puntos-venta-desktop>
    
  </ng-container>

  <ng-container *ngIf="device === devices.MOBILE">
    
    <app-puntos-venta-mobile></app-puntos-venta-mobile>

  </ng-container>

</div>