<div class="bottomSheetContainer">
    <div class="button" (click)="dismiss()">
        <div class="btn-ln">
            <div class="line" [ngClass]="{'line-cool': modoGrupo}">

            </div>
            <h4>Repetir jugada</h4>
        </div>
    </div>
    <div class="container">

        <cdk-virtual-scroll-viewport #scroll itemSize="15" class="scroll">
            <div class="card">
                <div class="body1">
                    <div class="titulo">
                        <h4>¿Cuando deseas repetir esta jugada?</h4>
                    </div>
    
                    <div class="body2">
                        <mat-radio-group
                          class="radio-group">
    
                          <mat-radio-button #inputNextRaffle class="radio-button" [labelPosition]="'after'" [checked]="todaSemana == 0" [value]="0" (change)="$event? todaSemana = 0 : ''">
                            Próximo sorteo
                          </mat-radio-button>

                          <mat-radio-button #inputAllWeek class="radio-button" [labelPosition]="'after'" [checked]="todaSemana == 1" [value]="1" (change)="$event? todaSemana = 1 : ''">
                            Durante toda la semana
                          </mat-radio-button>
    
                        </mat-radio-group>
                    </div>
    
                </div>
            </div>

            <div class="card">
                <div class="body1">
                    <div class="titulo">
                        <h4>¿Deseas jugar los mismo números?</h4>
                    </div>
    
                    <div class="body2">
                        <mat-radio-group
                          class="radio-group">
    
                          <mat-radio-button class="radio-button" [labelPosition]="'after'" [checked]="true">
                            Mismos números
                          </mat-radio-button>
    
                        </mat-radio-group>
                    </div>
    
                </div>
            </div>

            <div class="footer" *ngIf="play">

                <div class="grid-container">
                    <div class="saldo border-l">
                        {{saldo | currency: 'EUR'}}
                        <p class="texto">Tu saldo</p> 
                    </div>
    
                    <div class="saldo">
                        {{play.price | currency: 'EUR'}}
                        <p class="texto">Apuesta</p>
                    </div>
    
                    <div class="column">
                        <button class="btn-repeat" [disabled]="disabledButton" [ngClass]="{'btn-repeat-cool': modoGrupo, 'btn-repeat-disabled': disabledButton}" (click)="repeat()">Repetir</button>
                    </div>
                </div>                
            </div>
            
        </cdk-virtual-scroll-viewport>

        

    </div>
</div>