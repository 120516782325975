<div class="modal">
    <h4 mat-dialog-title>{{data.title}}</h4>
    <div class="curpo" mat-dialog-content [innerHTML]="data.texto"></div>
    <!-- Cierra directamente el modal. -->
    <div class="footer">
        <button class="btn ml2 btn-primary" [ngClass]="{'btn-cool': modeGroup}" [mat-dialog-close]="true">{{data.buttonConfirm}}</button>
        <div class="noMostrar" *ngIf="data.showOptionCheck">
            <label class="custom-radio-checkbox">
                <input class="custom-radio-checkbox__input" type="checkbox" name="metodoPago" value="tarjeta"/>
                <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
                <span class="custom-radio-checkbox__text">No volver a mostrar</span>
            </label>
        </div>
    </div>
</div>