<div class="bottomSheetContainer" (click)="dimiss()">
	<div class="button">
		<div class="btn-ln">
			<div class="line">

			</div>
			
		</div>
	</div>
	<div class="container">
		<div class="importe">
			<h4>{{movimiento.tipo_movimiento}}</h4>
			<h3 [ngClass]="{'ingreso': movimiento.importe > 0, 'cobro': movimiento.importe < 0 }"> {{(movimiento.importe > 0? '+':'' )}} {{ movimiento.importe | currency: 'EUR'}}</h3>
		</div>

		<div class="row">
			<p class="title">Concepto:</p>
			<p>{{movimiento.concepto}}</p>
		</div>
		
		<div class="row">
			<p class="title">Fecha:</p>
			<p>{{movimiento.fecha | date: 'dd/MM/Y | H:mm'}}</p>
		</div>
		<div class="space"></div>
	</div>
</div>