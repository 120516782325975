import { AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlaysAPIService } from '@api/services/games/plays-api.service';
import { PlaysService } from '@api/services/play.service';
import { DialogService } from '@api/services/dialog.service';
import { faHeart, faRedo, faShare, faSlidersH, faStar } from '@fortawesome/free-solid-svg-icons';
import { MPlay } from '@models/api-models/plays-api.models';
import { TemplateFilterService } from '@shared/services/template-filter.service';

import { modalYesNoComponent } from '@shared/components/modals/modalYesNo/modalYesNo.component';
import { modalShareComponent } from '@shared/components/modals/modalShare/modalShare.component';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { CustomVirtualScroll } from '@classes/CustomVritualScoll';
import { bottomSheetRegalarComponent } from '@shared/components/bottomSheet/bottomSheetRegalar/bottomSheetRegalar.component';
import { ApplicationStateService } from '@shared/services/application-state.service';
import { EDevice } from '@enums/common/sizes.enum';
import { CCoreComponent } from '@classes/core/core-component.class';
import { filter, map, pairwise, take, throttleTime } from 'rxjs/operators';
import { HeaderService } from '@api/services/header.service';
import { PlaysFilter } from '@models/playsFilter';
import { FiltrosService } from '@api/services/filtros.service';
import { pipe, Subscription } from 'rxjs';
import { Mygroup } from '@models/group.model';
import { GroupService } from '@api/services/group.service';
import { FooterService } from '@api/services/footer.service';

@Component({
	selector: 'app-plays-list',
	templateUrl: './plays-list.component.html',
	styleUrls: [
		'./plays-list.component.scss',
	],
	providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScroll }]
})
export class PlaysListComponent extends CCoreComponent implements AfterViewInit, OnDestroy, OnInit {

	//Variables
	public selectedValue: String;
	public filterPlay: String;
	public filterContainer: boolean;
	public plays: any[];
	public finalPlays: Array<MPlay> = [];
	public repPlay: MPlay;
	loading: boolean;
	//Icons
	public iconHeart = faHeart;
	public iconRepeat = faRedo;
	public iconShare = faShare;
	public iconFilter = faSlidersH;
	public iconStar = faStar;
	public selectedId = 0;
	public spinnerBottomLoadVisibility = false;
	public device: EDevice;
	public page = 1;
	public lastPage = false

	public filtros = new PlaysFilter()

	private jugadasSubscription: Subscription
	private AbonosSubscription: Subscription
	private filterSuscription: Subscription

	public modeGroup: boolean = false;
	public grupo: Mygroup;

	@ViewChild("scroll") scroller: CdkVirtualScrollViewport;
	@Input() abonos: boolean = false;

	constructor(
		private playsService: PlaysAPIService,
		private AuxPlaysService: PlaysService,
		private filterService: TemplateFilterService,
		private router: Router,
		private dialog: MatDialog,
		private dialogSercive: DialogService,
		private bottomSheetRegalar: MatBottomSheet,
		private ngZone: NgZone,
		private headerService: HeaderService,
		private filtrosService: FiltrosService,
		private routerActive: ActivatedRoute,
		applicationStateService: ApplicationStateService,
		private grupoService: GroupService,
		private footerService: FooterService,
	) {

		super(applicationStateService);

		this.loading = true;
		this.plays = [];
		this.initializeSubscriptions();
		headerService.set_filterButtonShow(true);
	}

	/**
	 * Method to initialize subscriptions of the component.
	 */
	private initializeSubscriptions(): void {

		this.applicationStateService.applicationDeviceObservable.subscribe((device: EDevice) => {
			this.device = device;
		});

	}

	ngOnInit(): void {
		this.headerService.modeGrupoCoolOb.subscribe(res => { 
			this.modeGroup = res;
			if (this.modeGroup) {
				this.footerService.set_Show_footer(false);
			} 
		});
		this.filterSuscription = this.filtrosService.filtrosOb.subscribe(res => {
			this.page = 1;
			this.filtros = res;
			this.loading = true
			this.lastPage = false
			this.finalPlays = [];
			this.getPlays(this.page)
		})
	}

	ngAfterViewInit(): void {
		this.onScroll();
		this.reloadAbonos();
		this.reloadPlays();
	}

	public dropAbonoList(abono: MPlay) {
		this.finalPlays = this.finalPlays.filter(x => x.id != abono.id)
	}

	private reloadAbonos() {
		if (this.abonos) {
			this.AbonosSubscription = this.filtrosService.AbonosUpdateOb.subscribe(res => {
				if (res) {
					this.page = 1
					this.finalPlays = []
					this.loading = true
					this, this.lastPage = false
					this.getPlays()
					this.filtrosService.setUpdateAbonos(false)
				}
			})
		}
	}

	private reloadPlays() {
		if (!this.abonos) {
			this.jugadasSubscription = this.filtrosService.jugadasUpdateOb.subscribe(res => {
				if (res) {
					this.page = 1
					this.finalPlays = []
					this.loading = true
					this.lastPage = false
					this.getPlays()
					this.filtrosService.setUpdateJugadas(false)
				}
			})
		}
	}

	public onScroll() {
		this.scroller.elementScrolled().pipe(
			map(() => this.scroller.measureScrollOffset('bottom')),
			pairwise(),
			filter(([y1, y2]) => (y2 < y1 && y2 > 100)),
			throttleTime(1500),
		).subscribe(() => {
			this.ngZone.run(() => {
				if (!this.lastPage) {
					this.page++;

					if (this.page > 1)
						this.spinnerBottomLoadVisibility = true;

					this.getPlays(this.page);
				}
			})
		})
	}

	/**
	 * 
	 * Load the plays from api
	 * @returns void
	 */
	public getPlays(page = 1): void {
		if (this.modeGroup) { 
			this.grupoService.getGroup.pipe(take(1)).subscribe(res => {
				this.grupo = res;
				this.grupoService.getPlaysApi(this.grupo, page, this.filtros, this.abonos);
	
				this.grupoService.playsOb$
					.subscribe((res: any[]) => {
						this.spinnerBottomLoadVisibility = false;
						if (res.length > 0) {
							this.plays = res;
							this.loadPlays();
							this.loading = false;
	
							this.finalPlays = this.finalPlays.filter((x) => { return x.idGame != 9 })
						}
						else {
							this.loading = false;
							this.page--;
							this.lastPage = true
						}
					},
					err => {
						console.log(err)
					});
			});
		}
		else {
			this.playsService.getPlaysApi(page, this.filtros, this.abonos);
			this.playsService.playsApi$.subscribe(res => {
	
				this.spinnerBottomLoadVisibility = false;
				if (res.length > 0) {
					this.plays = res;
					this.loadPlays();
					this.loading = false;
	
					this.finalPlays = this.finalPlays.filter((x) => { return x.idGame != 9 })
				}
				else {
					this.loading = false;
					this.page--;
					this.lastPage = true
				}
			},
			err => {
				console.log(err)
			});
		}
	}

	/**
	 * 
	 * Load the plays to show on template
	 * @returns void
	 */
	private loadPlays(): void {
		if (this.finalPlays == undefined)
			this.finalPlays = [];
		this.finalPlays.push(...this.AuxPlaysService.extractInfoApi(this.plays));
	}

	/**
	 * Returns filtered plays by filterPlay
	 * @returns void
	 */
	public filterPlays(): void {

		let auxFinalPlay = [];
		if (this.filterPlay == 'Lotería Nacional') {
			auxFinalPlay = this.filterService.filterGames(this.finalPlays, 'name', 'Lotería de Navidad');
		}
		this.loadPlays();
		this.finalPlays = this.filterService.filterGames(this.finalPlays, 'name', this.filterPlay).concat(auxFinalPlay);

	}

	/**
	 * Returns all plays without filtering
	 * @returns void
	 */
	public filterAll(): void {

		this.filterPlay = 'Todos';
		this.loadPlays();

	}

	/**
	 * Load the all bought plays and 
	 * put in section bought with filter container closed
	 * @returns void
	 */
	public setUpBought(): void {

		this.selectedValue = 'bought';
		this.filterPlay = 'Todos';
		this.filterContainer = false;
		this.loadPlays();

	}

	/**
	 * Load the all scanned plays and 
	 * put in section scanned with filter container closed
	 * @returns void
	 */
	public setUpScanned(): void {

		this.selectedValue = 'scanned';
		this.filterPlay = 'Todos';
		this.filterContainer = false;
		//this.loadResults();

	}

	/**
	 * Redirect to play detail
	 * @returns void 
	 */
	public detailPlay(play: MPlay): void {

		if (this.modeGroup) {
			this.router.navigate(['/cliente/mis-grupos/detalles',
				this.routerActive.snapshot.parent.params.grupo_id,
				this.routerActive.snapshot.parent.params.nombre,
				'jugada', play.id]);
		}
		else {
			this.router.navigate(['/cliente/jugadas', play.id]);
		}

	}

	printDate(index: number) {

		if (index === 0) {
			return true;
		}

		if (this.finalPlays[index].date === this.finalPlays[index - 1].date) {
			return false;
		}

		return true;

	}

	public compartir() {

		const modalClass = this.device === this.devices.MOBILE ? 'modal-custom' : 'share-modal';

		let compartir = this.dialog.open(modalShareComponent, {
			panelClass: modalClass,
			data: {
				title: 'Compartir Jugada',
				texto: 'dasdasda',
				buttonConfirm: 'Salir',
				btnConfirmColor: 'red',
				buttonCancel: 'Cancelar',
				link: 'https://www.miloto.es',
				showIcon: false,
				showOptionCheck: false
			}
		});
		compartir.afterClosed().subscribe(res => {
			if (res) {
			}
		});
	}


	openRegalarJugada() {

		this.bottomSheetRegalar.open(bottomSheetRegalarComponent, {
			hasBackdrop: true,
			backdropClass: 'backdropBottomSheet',
			panelClass: 'bottomSheetContainerJugadaRegalada',
			data: { jugada_id: this.selectedId }
		});
	}

	ngOnDestroy(): void {
		this.headerService.set_filterButtonShow(false);

		if (this.jugadasSubscription)
			this.jugadasSubscription.unsubscribe();

		if (this.AbonosSubscription)
			this.AbonosSubscription.unsubscribe();

		if (this.filterSuscription)
			this.filterSuscription.unsubscribe();

		if (this.modeGroup) {
			this.footerService.set_Show_footer(true);
		}
	}

}