export class MPlay {

    constructor(
        public id: number = 0,
        public date: Date | String = new Date(),
        public name: String = '',
        public image: String = '',
        public state: number = 0,
        public betsNumbers: Array<Array<any>> = [],
        public valueStars: Array<number> = [],
        public refund: number = 0,
        public shared: boolean = false,
        public paid: boolean = false,
        public price: string = '',
        public deliveryId: number = 0,
        public idGame: number = 0,
        public simulacion: any = null,
        public semanal: boolean = false,
        public multiple: boolean = false,
        public guinda_price: number = 0,
        public relacionadas: any = null,
        public jugada_regalada: any = null,
        public link: any = null,
        public abono: any = null,
        public dateSorteo: any = null,
        public order: any = null,
        public producto: boolean = false,
        public miniatura: any = null,
        public nombre_producto: any = null,
        public valuesLottery: any = null,
        public guinda: any = null,
        public nombre: string = '',
        public validated: number = 0,
        public guinda_info: any = null
    ){}
}
