import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Estado, PlaysFilter } from '@models/playsFilter';
import { ApiRouterService } from '@shared/services/api-router.service';
import { Observable, pipe } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { MPlay } from '@models/api-models/plays-api.models';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

/**
 * Plays API service. Provides the app with plays data from the API.
 */
@Injectable({
	providedIn: 'root'
})
export class PlaysAPIService extends ApiRouterService {

	private selectJugada: MPlay;
	private selectJugada$: BehaviorSubject<MPlay>;
	public selectJugadaOb: Observable<MPlay>;

	public playsApi$: Observable<any>
	public subscriptionApi$: Observable<any>
	public participacionApi$: Observable<any>

	constructor(

		private http: HttpClient,
		private authService: AuthenticationService

	) {

		super();

		this.selectJugada$ = new BehaviorSubject<MPlay>(this.selectJugada);
		this.selectJugadaOb = this.selectJugada$.asObservable();

	}

	set_SelectJugada(jugada: MPlay) {
		this.selectJugada = jugada;
		this.selectJugada$.next(jugada);
	}

	public setNombreJugada(jugada: MPlay) {
		const token = this.authService.loadToken();

		let header = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		return this.http.post(`${this.URL}jugadas/${jugada.id}/cambionombre`, { nombre: jugada.nombre }, { headers: header });
	}

	/**
	 * Gets all content of the plays from the API.
	 * @returns Callback from the API.
	 */
	public getPlaysApi(page = 1, filtros: PlaysFilter, abonos = false): void {

		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };
		if (!abonos)
			this.playsApi$ = this.http.get(`${this.URL}personas/${tokenJWT.idClient}/jugadas?page=${page}&propiedad=${filtros.propiedad}&estado=${filtros.estado}&juego=${filtros.juego_id}`, httpOptions).pipe(shareReplay());
		else
			this.playsApi$ = this.http.get(`${this.URL}personas/${tokenJWT.idClient}/jugadas?page=${page}&estado=${Estado.abonos}`, httpOptions).pipe(shareReplay());

	}

	public getSubscriptionsApi(page = 1, group_id = 0) {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		let group = group_id == 0 ? '' : `&grupo_id=${group_id}`;

		const httpOptions = { headers: this.httpHeaders };
		this.subscriptionApi$ = this.http.get(`${this.URL}productosPenya/listadoAbonos?page=${page}${group}`, httpOptions).pipe(shareReplay());
	}

	public getParticipationsApi(page = 1, group_id = 0) {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);


		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		let group = group_id == 0 ? '' : `&grupo_id=${group_id}`;

		const httpOptions = { headers: this.httpHeaders };
		this.participacionApi$ = this.http.get(`${this.URL}productosPenya/listado?page=${page}${group}`, httpOptions).pipe(shareReplay());
	}

	/**
	 * Gets all content of the play from the API.
	 * @returns Callback from the API.
	 */
	public getPlay(id: number): Observable<any> {

		const token = this.authService.loadToken();

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };

		return this.http.get(`${this.URL}jugadas/${id}`, httpOptions);

	}

	public getGiftedPlay(hash: string): Observable<any> {

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
		});

		const httpOptions = { headers: this.httpHeaders };

		return this.http.get(`${this.URL}jugada_regalada/get_jugada/${hash}`, httpOptions);
	}

	public getSharedPlay(hash: string): Observable<any> {

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
		});

		const httpOptions = { headers: this.httpHeaders };

		return this.http.get(`${this.URL}jugada_compartida/get_jugada/${hash}`, httpOptions);
	}

	/**
	 * Gets all info of the play from the API.
	 * @returns Callback from the API.
	 */
	public getInfoPlay(id: number) {

		const token = this.authService.loadToken();

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };

		return this.http.get(`${this.URL}jugadas/${id}/avanzado`, httpOptions);

	}

	public getTicket(id: number): Observable<any> {

		const token = this.authService.loadToken();

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };

		return this.http.get(`${this.URL}jugadas/${id}/newResguardo`, httpOptions);

	}

	/**
	 * Get Plays data with pedido_id
	 * @param id - pedido_id
	 */
	public getPlayFromPedido(id) {
		const token = this.authService.loadToken();
		this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', token });
		const httpOptions = {
			headers: this.httpHeaders
		};

		return this.http.get(`${this.URL}pedidos/${id}/jugadas`, httpOptions);
	}

	public abonoPlay(idJugada): Observable<any> {
		const token = this.authService.loadToken();
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}abonos`, { jugada_id: idJugada }, httpOptions);
	}

	public desabonoPlay(idJugada): Observable<any> {
		const token = this.authService.loadToken();
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		return this.http.delete(`${this.URL}abonos/${idJugada}`, httpOptions);
	}

	/**
	 * Method to share a play, it connects to the API and sends the data and return the response.
	 * @param id: number - id of the play to share.
	 * @returns Callback from the API.
	 */
	public sharePlay(playId: number): Observable<any> {

		const token = this.authService.loadToken();

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };
		const data = {
			publica: true,
			ids: '-1'
		};
		return this.http.post(`${this.URL}jugadas/${playId}/compartir`, data, httpOptions);
	}

	/**
	 * Method register to connect with the API
	 * @param user User data for register.
	 * @returns post request to API register
	 */
	public giftPlay(email, jugada_id): Observable<any> {
		const token = this.authService.loadToken();
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		const giftData = {
			'jugada_id': jugada_id,
			'email_invitado': email
		};

		let data = giftData
		return this.http.post(`${this.URL}jugada_regalada/invitar`, data, httpOptions);

	}


	public acceptGiftedPlay(data, has_code): Observable<any> {
		const token = this.authService.loadToken();
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };


		return this.http.post(`${this.URL}jugada_regalada/aceptar/${has_code}`, data, httpOptions);
	}


	public acceptSharedPlay(data, has_code): Observable<any> {
		const token = this.authService.loadToken();
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };


		return this.http.post(`${this.URL}invitaciones/aceptar-auth/${has_code}`, data, httpOptions);
	}

	public cancelGiftedPlay(id): Observable<any> {
		const token = this.authService.loadToken();
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };

		const data = {
			'jugada_id': id,
		};
		return this.http.post(`${this.URL}jugada_regalada/delete`, data, httpOptions);
	}

	public socialAcceptGiftedPlay(type, socialToken, has_code, uid = ""): Observable<any> {

		switch (type) {
			case 'g':
				this.httpHeaders = new HttpHeaders({
					'Content-Type': 'application/json',
					'tokenGoogle': socialToken
				});
				break;
			case 'f':
				this.httpHeaders = new HttpHeaders({
					'Content-Type': 'application/json',
					'tokenFacebook': socialToken
				});
				break;
			case 'a':
				this.httpHeaders = new HttpHeaders({
					'Content-Type': 'application/json',
					'tokenApple': socialToken,
					'uid': uid
				});
				break;
		}

		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}jugada_regalada/aceptar/${has_code}`, {}, httpOptions);
	}

	public socialAcceptSharedPlay(type, socialToken, has_code, uid = ""): Observable<any> {

		switch (type) {
			case 'g':
				this.httpHeaders = new HttpHeaders({
					'Content-Type': 'application/json',
					'tokenGoogle': socialToken
				});
				break;
			case 'f':
				this.httpHeaders = new HttpHeaders({
					'Content-Type': 'application/json',
					'tokenFacebook': socialToken
				});
				break;
			case 'a':
				this.httpHeaders = new HttpHeaders({
					'Content-Type': 'application/json',
					'tokenApple': socialToken,
					'uid': uid
				});
				break;
		}

		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}invitaciones/aceptar-auth/${has_code}`, {}, httpOptions);
	}

	public getCertificate(id): Observable<any> {
		const token = this.authService.loadToken();
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };


		return this.http.get(`${this.URL}jugadas/${id}/url-certificado`, httpOptions);
	}

	public asegurarJugada(jugada_id): Observable<any> {
		const token = this.authService.loadToken();
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };

		return this.http.post(`${this.URL}guinda`, { jugada_id: jugada_id }, httpOptions);
	}

	public getFactura(pedido_id): Observable<any> {
		const token = this.authService.loadToken();
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };

		return this.http.get(`${this.URL}pedidos/${pedido_id}/factura`, httpOptions);
	}
}
