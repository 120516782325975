import { Component, OnDestroy } from "@angular/core";
import { FooterService } from "@api/services/footer.service";
import { HeaderService } from "@api/services/header.service";
import { CCoreComponent } from "@classes/core/core-component.class";
import { ApplicationStateService } from "@shared/services/application-state.service";

@Component({
    selector:'app-direccion-compra-core',
    templateUrl:'./direccion-compra-core.component.html',
    styleUrls:['./direccion-compra-core.component.scss']
})
export class direccionCompraCoreComponent extends CCoreComponent implements OnDestroy
{
    constructor(AplicationStateService: ApplicationStateService,
                private headerService: HeaderService,
                private footerService: FooterService
        ){
        super(AplicationStateService);

        headerService.set_title("Confirmar Dirección");
        headerService.set_btn_action_left_Mode("BackArrow");
        headerService.set_btn_right_show(false);
        footerService.set_Show_footer(false);
    }

    ngOnDestroy(){
        this.headerService.set_btn_action_left_Mode("Ajustes");
        this.headerService.set_btn_right_show(true);
        this.footerService.set_Show_footer(true);
    }
}