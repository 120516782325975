<!--<div class="day-wraper">
  <div class="calendar" (click)="switchSelection()" 
  [style.background-image]="date.selected ? 'url(../../../../../../assets/images/icons/calendar-selected.png)' : 'url(../../../../../../assets/images/icons/calendar.png)'">
    <div class="day-name" >
      <span >{{date.dayName}}</span>
    </div>
    <div class="day" [style.color]="date.selected ? 'white' : 'black'" [style.-webkit-text-stroke]="date.selected ? '0.75px blue' : 'inherit'">
      <span>{{date.day}}</span>
    </div>
    <div class="month" [style.color]="date.selected ? 'white' : 'black'">
      <span>{{date.month}}</span>
    </div>
  </div>
</div>-->


<div #day class="new-day-wrapper" [style.color]="selected ? '#FF3880' : '#9FA5AA'" (click)="switchSelection()">
  <div class="data-day-wrapper" [style.border-color]="selected ? '#FF3880' : '#9FA5AA'" [style.background-color]="selected ? '#FFEAF1' : 'white'">
    <span class="new-day">{{date.day}}</span>
    <span class="new-month">{{date.month}}</span>
  </div>
  <div class="new-day-name">
    <span >{{date.dayName}}</span>
  </div>
</div>
