import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EAuthenticationAvailableOptions } from '@enums/common/authentication-options.enum';
import { EToken } from '@enums/common/token.enum';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Observable } from 'rxjs';
import { LoginUserModel } from '@models/login-user.model';
import { ApiRouterService } from '../../shared/services/api-router.service';
import { TokenJWTModel } from '@models/token-JWT.model';
import { Router } from '@angular/router';
import { RecoverPasswordComponent } from 'src/app/layouts/authentication/recover-password/recover-password.component';
import { BrowserCacheLocation, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

/**
 * Authentication service. Provides the app with an authentication service connected with the API.
 */
@Injectable({
	providedIn: 'root'
})
export class AuthenticationService extends ApiRouterService {

	private token: TokenJWTModel;

	private msalInstance: IPublicClientApplication;

	constructor(

		private http: HttpClient,
		private helper: JwtHelperService,
		private authService: SocialAuthService,
		private router: Router

	) {

		super();
		this.token = new TokenJWTModel('', '', '', '', '');
		if (this.readToken()) {

			this.token = this.decodeToken(this.token.authToken);

		}
		if (this.isAuthenticated()) {

			//this.router.navigateByUrl('/');

		}

	}

	/**
	 * Method for login on the app. Connects with the API login.
	 * @param user Authentication user data.
	 * @returns Callback from the API.
	 */
	public login(user: LoginUserModel): Observable<any> {

		//user.playerId = 123456;
		this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
		const httpOptions = { headers: this.httpHeaders };
		const authData = {
			login: user.email,
			password: user.password,
			//playerId: user.playerId
		};
		return this.http.post(`${this.URL}login`, authData, httpOptions);

	}

	/**
	 * Login with crisp
	 * Token is required
	 */
	public loginCrisp(email: String) //Aun por ver si sacarle algun tipo de utilidad real
	{
		let token = this.loadToken();
		this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'token': token });
		const httpOptions = { headers: this.httpHeaders };
		const authData = {
			email,
		};
		return this.http.post(`${this.URL}crisp/findForEmail`, authData, httpOptions);
	}

	/**
	 * Method for send email for change the user password
	 */
	public forguetPasswordComponent(email: string): Observable<any> {
		this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
		const httpOptions = { headers: this.httpHeaders };

		let url = window.location.host + "/acceso/change-password/";

		return this.http.post(`${this.URL}recuperar-password`, JSON.stringify({ email, url }), httpOptions);
	}

	/**
	 * Method for change password
	 */
	public changePassword(clave: string, data: any) {
		this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
		const httpOptions = { headers: this.httpHeaders };

		return this.http.post(`${this.URL}change-password/${clave}`, JSON.stringify(data), httpOptions);
	}

	public getContacts(token: string): Observable<any> {


		const headers = {
			Authorization: `Bearer ${token}`,
			Accept: '*/*'
		};
		return this.http.get('https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses,phoneNumbers', { headers });
	}

	/**
	 * Remove the token from de local storage.
	 * @returns The user token exists.
	 */
	private removeToken(): boolean {

		if (localStorage.getItem(EToken.TOKEN_JWT)) {

			localStorage.removeItem(EToken.TOKEN_JWT);
			return true;

		} else if (localStorage.getItem(EToken.TOKEN_GOOGLE)) {

			localStorage.removeItem(EToken.TOKEN_GOOGLE);
			return true;

		} else if (localStorage.getItem(EToken.TOKEN_FACEBOOK)) {

			localStorage.removeItem(EToken.TOKEN_FACEBOOK);
			return true;

		} else {

			return false;

		}

	}

	/**
	 * Method to logout from the app.
	 * @return Indicates if the logout was possible.
	 */
	public async logout(): Promise<boolean> {

		try {

			if (this.removeToken()) {

				return true;

			} else {

				return false;

			}

		} catch (error) {

			console.log(error);
			return false;

		}

	}

	/**
	 * Method for login on the app. Uses Google for login.
	 * @returns Login on Google.
	 */
	public loginGoogle(): Promise<SocialUser> {

		return this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);

	}

	/**
	 * Sends to the API the token returned from the login on Google System.
	 * @param token Token from the login.
	 * @returns API authentication with Google.
	 */
	public sendLoginGoogleAPI(token: any): Observable<any> {

		this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
		const httpOptions = { headers: this.httpHeaders };
		let params = { access_token: token}
		return this.http.post(`${this.URL}newGoogle`, params, httpOptions);

	}

	/**
	 * Method for login on the app. Uses Facebook for login.
	 * @returns Login on Facebook.
	 */
	public loginFacebook(): Promise<SocialUser> {

		return this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);

	}

	/**
	 * Sends to the API the token returned from the login on Facebook System.
	 * @param token Token from the login.
	 * @returns API authentication with Facebook.
	 */
	public sendLoginFacebookAPI(token: string, id: string): Observable<any> {

		this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}facebook?access_token=${token}&player_id=${id}`, httpOptions);

	}

	public sendLoginAppleAPI(accessToken: String, user_id: string) {
		this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
		const httpOptions = { headers: this.httpHeaders };
		let params = { access_token: accessToken, user_id }
		return this.http.post(`${this.URL}apple`, params, httpOptions)
	}

	public async initializeAzureInstance() {
		const isIE =
			window.navigator.userAgent.indexOf('MSIE ') > -1 ||
			window.navigator.userAgent.indexOf('Trident/') > -1;

		this.msalInstance = new PublicClientApplication({
			auth: {
				clientId: 'e91ade2a-f645-4cfe-bd6c-e61b5a710b2c',
				authority: 'https://login.microsoftonline.com/c3b33ecd-9c1d-4e8e-99c1-aa308d7234c3/v2.0',
				redirectUri: 'https://dev.miloto.com/',
			},
			cache: {
				cacheLocation: BrowserCacheLocation.LocalStorage,
				storeAuthStateInCookie: isIE,
			},
			system: {

			},
		});

		await this.msalInstance.initialize();
		await this.msalInstance.handleRedirectPromise();
	}

	public sendLoginAzureAPI(token: string): Observable<any> {

		this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}google?access_token=${token}`, httpOptions);

	}

	/**
	 * Indicates if a user is authenticated.
	 * @returns User authenticated.
	 */
	public isAuthenticated(): boolean {

		if (this.readToken()) {

			return !this.helper.isTokenExpired(this.token.authToken);

		}

		return false;

	}

	/**
	 * Saves the token into the local storage.
	 * @param idToken The token.
	 * @param login The login service.
	 */
	public saveToken(token: string, login: string): void {

		switch (login) {
			case EAuthenticationAvailableOptions.JWT_LOGIN:

				this.token = this.decodeToken(token);
				localStorage.setItem(EToken.TOKEN_JWT, token);

				break;
			case EAuthenticationAvailableOptions.GOOGLE_LOGIN:

				this.token = this.decodeToken(token);
				localStorage.setItem(EToken.TOKEN_GOOGLE, token);

				break;
			case EAuthenticationAvailableOptions.FACEBOOK_LOGIN:

				this.token = this.decodeToken(token);
				localStorage.setItem(EToken.TOKEN_FACEBOOK, token);
				break;

			default:
				break;
		}

	}

	/**
	 * Loads the token from de local storage.
	 * @returns The user token exists.
	 */
	public loadToken(): string {

		if (localStorage.getItem(EToken.TOKEN_JWT)) {

			return localStorage.getItem(EToken.TOKEN_JWT)!;

		} else if (localStorage.getItem(EToken.TOKEN_GOOGLE)) {

			return localStorage.getItem(EToken.TOKEN_GOOGLE)!;

		} else if (localStorage.getItem(EToken.TOKEN_FACEBOOK)) {

			return localStorage.getItem(EToken.TOKEN_FACEBOOK)!;

		}

		return '';

	}

	/**
	 * Reads the token from de local storage.
	 * @returns The user token exists.
	 */
	public readToken(): boolean {

		if (localStorage.getItem(EToken.TOKEN_JWT)) {

			this.token.authToken = localStorage.getItem(EToken.TOKEN_JWT);
			return true;

		} else if (localStorage.getItem(EToken.TOKEN_GOOGLE)) {

			this.token.authToken = localStorage.getItem(EToken.TOKEN_GOOGLE);
			return true;

		} else if (localStorage.getItem(EToken.TOKEN_FACEBOOK)) {

			this.token.authToken = localStorage.getItem(EToken.TOKEN_FACEBOOK);
			return true;

		} else {

			this.token.authToken = '';
			return false;

		}

	}

	/**
	 * Function for decode the API's token.
	 * @param token The token form the API.
	 * @returns Token model with the auth data.
	 */
	public decodeToken(token: string): TokenJWTModel {

		const decodedToken = this.helper.decodeToken(token);
		const tokenReturn = new TokenJWTModel(decodedToken.cliente_id, decodedToken.exp, decodedToken.iat, decodedToken.jti, token);

		return tokenReturn;

	}

	/**
	 * Prints the token
	 * @param token Token from the API decoded.
	 */
	public printToken(token: TokenJWTModel): void {

		console.log(formatDate(token.tokenInit, 'dd/MM/yyyy, h:mm:ss a', 'en-US'));
		console.log(formatDate(token.tokenExpires, 'dd/MM/yyyy, h:mm:ss a', 'en-US'));
		console.log(token.idClient);
		console.log(token.token);

	}

}
