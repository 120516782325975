import { Injectable } from "@angular/core";
// MODELS
import { MPlay } from "../../models/api-models/plays-api.models";
import { CancerPlay } from "@models/api-models/cancer-play.model";

@Injectable({
    providedIn: 'root'
  })
export class PlaysService   {

    constructor() {}


    /**
     * Extract the info of all plays from api
     * @param playsApi
     * @returns Array<MPlay>
     */
    public extractInfoApi(playsApi: Array<any>): Array<MPlay>{
        let plays: Array<MPlay>;
        plays = [];

        playsApi.forEach(play => {
            plays.push(this.extractInfoPlay(play));

        });

        return plays;
    }

	extractInfoCancer(cancer: Array<any>): Array<CancerPlay> {
		return cancer.map((c) => this.tumor(c));
	}

	public tumor(c: any): CancerPlay {

		var jugada, participacion, fecha, importe, imagen, nombre = null;

		if(c.tipo == 'penyas'){
			imagen = c.participacion.producto.penya.imagen
			importe = c.participacion.importe;
			fecha = (new Date(c.participacion.producto.fecha)).toLocaleDateString('es-ES', {year: 'numeric', month: 'long', day: 'numeric'}).charAt(0).toUpperCase() +
			(new Date(c.participacion.producto.fecha)).toLocaleDateString('es-ES', {year: 'numeric', month: 'long', day: 'numeric'}).slice(1);
			participacion = c.participacion;
			nombre = "Participacion"
		} else {
			jugada = this.extractInfoPlay(c.jugada);
			imagen = `/assets/images/icons/plays-list/icons-list/icono_juego_${jugada.idGame}.png`
			importe = c.jugada.precio
			fecha = (new Date(c.jugada.fecha_sorteo)).toLocaleDateString('es-ES', {year: 'numeric', month: 'long', day: 'numeric'}).charAt(0).toUpperCase() +
			(new Date(c.jugada.fecha_sorteo)).toLocaleDateString('es-ES', {year: 'numeric', month: 'long', day: 'numeric'}).slice(1);
			nombre = jugada.name
		}

		let play = new CancerPlay(
			c.cliente_id,
			c.tipo,
			fecha,
			importe,
			nombre,
			imagen,
			jugada,
			participacion
		);
		return play;

	}

    /**
     * Extract the info of a play from api
     * @param play
     * @returns MPlay
     */
    public extractInfoPlay(play: any): MPlay {
        const formatDate = (dateStr: string) => {
            const date = new Date(dateStr);
            return date.toLocaleDateString('es-ES', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            }).charAt(0).toUpperCase() + 
            date.toLocaleDateString('es-ES', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            }).slice(1);
        };

        const date = formatDate(play['fecha_sorteo']);
        const betsNumbers = play['apuestas']?.map(bet => bet['numeros']) || [];
        const auxStars = play['apuestas']?.map(bet => bet['valores_auxiliares']) || [];
        const valueStars = auxStars?.length > 0 ? auxStars : null;

        const refund = play['valores_jugada'];
        const loteriaSerieFraccion = refund?.serie && refund?.fraccion 
            ? {serie: refund['serie'], fraccion: refund['fraccion']} 
            : null;
        const refundPlay = refund ? (refund['reintegro'] || refund['clave']) : null;

        const state = play['simulacion'] && Number(play['simulacion']['premio']) > 0 ? 3 : play['estado'];
        const shared = play['compartida'] != null;
        const guinda = play['guinda'] != null;

        let playAux: MPlay;

        if (play.producto) {

            playAux = new MPlay(
                play['id'],
                date,
                '',
                '',
                state,
                betsNumbers,
                valueStars,
                refundPlay,
                shared,
                false,
                play['precio'],
                play['pedido_id'],
                play['juego_id'],
                play['simulacion'],
                play['relacionadas'],
                play['jugada_regalada'],
                play['link'],
                play['abono'],
                null
            );
            playAux.producto = true;
            playAux.miniatura = play.miniaturas?.[0];
            playAux.nombre_producto = play.nombre_producto;
        } else {
            playAux = new MPlay(
                play['id'],
                date,
                play['juego']?.['nombre'],
                play['juego']?.['imagen'],
                state,
                betsNumbers,
                valueStars,
                refundPlay,
                shared,
                false,
                play['precio'],
                play['pedido_id'],
                play['juego_id'],
                play['simulacion'],
                play['semanal'],
                play['multiple'],
                play['juego']?.['precio_guinda'],
                play['relacionadas'],
                play['jugada_regalada'],
                play['link'],
                play['abono'],
                `${play['fecha_sorteo']} ${play['sorteo']?.['hora_sorteo']}`,
                play['pedido']
            );

            playAux.valuesLottery = loteriaSerieFraccion;
            playAux.guinda_price = play['juego']?.['precio_guinda'];
        }

        playAux.nombre = play.nombre;
        playAux.guinda_info = play['guinda_info'];
        playAux.semanal = play['semanal'];
        playAux.multiple = play['multiple'];
        playAux.validated = play['validada'];
        playAux.guinda = guinda;

        return playAux;
    }

}
