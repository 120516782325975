import { VIRTUAL_SCROLL_STRATEGY } from "@angular/cdk/scrolling";
import { Component, Inject, Renderer2 } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { FiltrosService } from "@api/services/filtros.service";
import { CustomVirtualScroll } from "@classes/CustomVritualScoll";
import { Estado, PlaysFilter, Propiedad } from "@models/playsFilter";

@Component({
    selector:'app-bottom-sheet-filtros',
    templateUrl:'./bottomSheetFiltros.component.html',
    styleUrls: ['bottomSheetFiltros.component.scss'],
    providers:[{provide:VIRTUAL_SCROLL_STRATEGY,useClass:CustomVirtualScroll}]
})
export class BottomSheetFiltros
{
    private filtros: PlaysFilter;

    public selectedGame: number
    public selectedEstado: string
    public selectPropiedades: string
    public modoGrupo: Boolean

    public estados: Array<any> = [
        {estado:Estado.activos, check: false},
        {estado:Estado.sorteados, check: false},
        {estado:Estado.premiados, check: false},
        {estado:Estado.todos, check: false}
    ]

    public propiedades: Array<any> = [
        {estado:Propiedad.individual, check: false},
        {estado:Propiedad.compartido, check: false},
        {estado:Propiedad.regalada, check: false},
        {estado:Propiedad.todos, check: false}
    ]

    public juegos: Array<any> = [
		{juego: "EuroDreams", value: 2, check: false},
        {juego: "Euromillones", value: 3, check: false},
        {juego: "Primitiva", value: 4, check: false},
        {juego: "Gordo", value: 5, check: false},
        {juego: "Bonoloto", value: 1, check: false},
        {juego: "L.Nacional", value: 7, check: false},
        {juego: "Quiniela", value: 11, check: false},
        {juego: "todos", value: -1, check: false},
    ]

    constructor(
        private bottomSheetFiltros: MatBottomSheetRef<BottomSheetFiltros>,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: {modoGrupo: boolean},
                private renderer: Renderer2,
                private filtrosService: FiltrosService
    ){
        this.modoGrupo = data.modoGrupo
        this.loadFiltros()
    }

    loadFiltros()
    {
        this.filtrosService.filtrosOb.subscribe(res=>{

            this.filtros = res;

            this.estados = this.estados.filter(x=>{
                if(x.estado == res.estado)
                {
                    x.check = true
                    this.selectedEstado = res.estado
                }
                    
                return x;
            });

            this.propiedades = this.propiedades.filter(x=>{
                if(x.estado == res.propiedad)
                {
                    x.check = true
                    this.selectPropiedades = res.propiedad
                }
                return x;
            })

            this.juegos = this.juegos.filter(x=>{
                if(x.value == res.juego_id)
                {
                    x.check = true
                    this.selectedGame = res.juego_id
                }
                return x;
            })
        })
    }

    filtrar()
    {
        this.filtros.estado = this.selectedEstado as Estado
        this.filtros.juego_id = this.selectedGame
        this.filtros.propiedad = this.selectPropiedades as Propiedad
        this.filtrosService.setFiltros(this.filtros)
        this.dismiss()
    }

    reset()
    {
        this.filtrosService.setFiltros(new PlaysFilter())
        this.dismiss()
    }

    dismiss()
    {
        this.bottomSheetFiltros.dismiss()
    }
}