import { Location } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { HeaderService } from "@api/services/header.service";

@Component({
    selector:'app-auth-header',
    templateUrl:'./auth-header.component.html',
    styleUrls:['./auth-header.component.scss']
})
export class authHeaderComponent
{
    @Input() title: string;

    constructor(private headerService: HeaderService,
                private router: Router
        ){}

    back(evente = false)
    {
        this.headerService.rutaBackOb.subscribe(x=>{
            if(evente)
            {
              this.router.navigate([x])
              evente = false
            }
          })
    }
}