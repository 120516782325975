import { AfterContentChecked, Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, NgZone, AfterViewInit } from '@angular/core';
import { ReservasService } from "@api/services/reservas.service";
import { MReservas } from "@models/reservas.model";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { CustomVirtualScroll } from '@classes/CustomVritualScoll';
import { take, debounceTime, filter, map, pairwise, throttleTime } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
	selector: 'app-lista-numeros-loteria-bottom-sheet',
	templateUrl: './lista-numeros-loteria-bottom-sheet.component.html',
	styleUrls: ['./lista-numeros-loteria-bottom-sheet.component.scss']
})

export class listNumerosLoteriaBottomSheetComponent implements OnInit {

	public linesToWrite: Array<any>;
	public reserva: MReservas = new MReservas();
	public page: number = 1;
	public search: string;
	public spinnerBottomLoadVisibility = true;
	public url = "";
	public last: boolean = false;
	public numero: string;
	public allset: boolean = false;
	public error: boolean = false;
	public titulo: String = "";
	private tipo: String = "";
	public empty: boolean = false;
	private limite: number = -1;
	public errorLim: boolean = false;

	@ViewChild(CdkVirtualScrollViewport) scroller: CdkVirtualScrollViewport;

	numberForm = new FormGroup({
		num: new FormControl('')
	});

	constructor(private reservasService: ReservasService,
		private bottomSheetNumeros: MatBottomSheetRef<listNumerosLoteriaBottomSheetComponent>,
		private ngZone: NgZone,
		@Inject(MAT_BOTTOM_SHEET_DATA) public data: { sorteo: any, titulo: String, tipo: String, limite: number }) {
		this.titulo = data.titulo;
		this.tipo = data.tipo;
		this.limite = data.limite != undefined && data.limite != null ? data.limite : -1;

		if (data.sorteo.miniaturas[1])
			this.url = "http://www.loteriaelnegrito.com/uploads/" + data.sorteo.miniaturas[1].path + data.sorteo.miniaturas[1].nombre;
		else
			this.url = "/assets/images/icons/defaultTicket.svg";
	}

	ngOnInit() {
		this.reservasService.allsetOb.subscribe(x => { this.allset = x; });
		this.reservasService.selectReservaOb.subscribe(x => { this.reserva = x; });

		if (this.reserva.cantidad_reservada == null
			|| this.reserva.cantidad_reservada < 1
			|| isNaN(this.reserva.cantidad_reservada)
			|| this.reserva.juego_id == undefined) {
			this.last = true;
			this.error = true;
			this.errorLim = false;
			return;
		}
		else if (this.limite != -1 && this.reserva.cantidad_reservada > this.limite) {
			this.last = true; this.error = false; this.errorLim = true;
			return;
		}
		this.linesToWrite = new Array<any>();
		this.addlines();
	}

	addlines() {
		this.reservasService.getNumerosDisponibles(this.reserva, this.data.sorteo, this.page, this.search, this.tipo).subscribe(
			(response: any) => {
				this.linesToWrite.push(...response.data.data);

				this.spinnerBottomLoadVisibility = false;

				if (response.data.data.length < 15) {
					this.last = true; this.empty = false;
					if (response.data.data.length == 0 && this.linesToWrite.length == 0) {
						this.empty = true;
					}
				}
			}
		);
		this.page++;
	}

	ngAfterViewInit(): void {
		this.onScroll();
	}

	public onScroll() {
		this.scroller.elementScrolled().pipe(
			map(() => this.scroller.measureScrollOffset('bottom')),
			pairwise(),
			filter(([y1, y2]) => (y2 < y1 && y2 > 100)),
			throttleTime(1500),
		).subscribe(() => {
			this.ngZone.run(() => {
				if (!this.last) {
					this.spinnerBottomLoadVisibility = true;
					this.addlines();
				}
			});
		})
	}

	confirmar() {
		this.reserva.numero = this.numero;
		this.checkAllSet();
		this.reservasService.set_allset(this.allset);
		this.dismiss()
	}

	cancelar() {
		this.dismiss();
	}

	dismiss() {
		this.bottomSheetNumeros.dismiss();
	}

	newSearchNumber() {
		if (this.error || this.errorLim) {
			return;
		}
		this.linesToWrite = new Array<any>();
		this.page = 1;
		this.search = this.numberForm.controls.num.value;
		this.last = false;
		this.addlines();
	}

	selectRadio(event: Event) {
		let aux = event.target as HTMLInputElement;
		this.numero = aux.value;
	}

	checkAllSet() {
		this.allset = false;
		if (this.reserva.nombre_reserva == "" || this.reserva.nombre_reserva == null) { return; }
		if (this.reserva.cantidad_reservada == null) { return; }
		if (isNaN(this.reserva.cantidad_reservada)) { return; }
		if (this.reserva.cantidad_reservada < 1 || this.reserva.cantidad_reservada > this.limite) { return; }
		if (this.reserva.numero == "") { return; }
		this.allset = true;
	}
}
