// tslint:disable: max-line-length
// tslint:disable: triple-equals

import { Component} from '@angular/core';

const changeToSimpleBet = '¡Atención! Cambio a apuesta simple';
const changeToMultiple44 = 'Apuesta múltiple de 44.';
const changeToMultipleBet = '¡Atención! Cambio a apuesta múltiple';
const limitMaxBet = 'Ha llegado al límite de apuesta.';

import { PlayLottery } from '../play-lottery';

@Component({
    selector: 'app-manual-play-lottery',
    templateUrl: './manual-play-lottery.component.html',
    styleUrls: ['./manual-play-lottery.component.scss']
  })
export class ManualPlayLotteryComponent extends PlayLottery{

  constructor(){

    super();

  }
}
