import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { EApiUrl } from '@enums/common/api.enum';

/**
 * Main service for API routing-by-environment dependant services.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiRouterService {

  protected URL: EApiUrl;
  protected httpHeaders: HttpHeaders;

  constructor() {

    this.URL = this.getApiUrlByEnvironment(environment.local, environment.intranet);
    this.httpHeaders = new HttpHeaders();

  }

  /**
   * Set the correct API url according to the current environment.
   * @param local [OPTIONAL] Indicates if the API is running in a local environment.
   *
   * @returns void
   */
  private getApiUrlByEnvironment(local?: boolean, intranet?: boolean): EApiUrl {

	if (intranet) {
		return EApiUrl.LOCAL_INTRANET;
	}

    if (local) {

      return EApiUrl.LOCAL;

    } else {

      return environment.production ? EApiUrl.PRODUCTION : EApiUrl.DEVELOP;

    }

  }

}
