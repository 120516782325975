import { Component, OnDestroy } from "@angular/core";
import { CCoreComponent } from "@classes/core/core-component.class";
import { ApplicationStateService } from "@shared/services/application-state.service";
import { FooterService } from "@api/services/footer.service";
import { HeaderService } from "@api/services/header.service";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector:'app-puntos-venta-lottery',
    templateUrl:'./puntos-venta-lottery.component.html',
    styleUrls:['./puntos-venta-lottery.component.scss']
})
export class puntosVentaLotteryComponent extends CCoreComponent implements OnDestroy
{
    constructor(ApplicationStateService: ApplicationStateService,
                public headerService: HeaderService,
                public footerService: FooterService,
                public ActiveRouter: ActivatedRoute
        ){
        super(ApplicationStateService);
        this.loadParams();
        this.headerService.set_btn_right_show(false);
        this.headerService.set_btn_action_left_Mode('BackArrow');
        this.footerService.set_Show_footer(false);
    }

    loadParams()
    {
        this.ActiveRouter.queryParams.subscribe(x=>{
            this.headerService.set_title(x.num)
            if(x.grupo_id)
            {
                this.headerService.set_modeGrupoCool(true)
                this.headerService.set_rutaBack(`/cliente/mis-grupos/detalles/${x.grupo_id}/${x.nombre}/juegos`)
            }
        });
    }

    ngOnDestroy()
    {
        this.headerService.set_btn_right_show(true);
        this.headerService.set_btn_action_left_Mode('Ajustes');
        this.footerService.set_Show_footer(true);
        this.headerService.set_modeGrupoCool(false)
    }
}