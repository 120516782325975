/**
 * Available device types.
 */
export enum EDevice {
  DESKTOP = 'desktop',
  MOBILE = 'mobile'
}

/**
 * Sizes to be watched on the fly to determine the current device.
 */
export enum EDeviceSize {
  MINDESKTOP = 991,
  MAXMOBILE = 990,
}
