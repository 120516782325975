import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SocialUser } from "@abacritt/angularx-social-login";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { faGoogle, faFacebookF, faApple } from '@fortawesome/free-brands-svg-icons';
import { EAuthenticationAvailableOptions } from '@enums/common/authentication-options.enum';
import { AuthenticationService } from '@api/services/authentication.service';
import { LoginUserModel } from '@models/login-user.model';
import { CCoreComponent } from '@classes/core/core-component.class';
import { ApplicationStateService } from '@shared/services/application-state.service';
import { HeaderService } from '@api/services/header.service';
import { logEvent } from '@firebase/analytics';
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import { UserApiService } from '@api/services/user.service';
import { Output, EventEmitter } from '@angular/core';


interface LoginMessage {
	type: 'error' | 'warning' | 'success';
	message: string;
}

@Component({
	selector: 'app-login-desktop',
	templateUrl: './login-desktop.component.html',
	styleUrls: [
		'./login-desktop.component.scss',
	],
})
export class LoginDesktopComponent extends CCoreComponent {

	iconFacebook = faFacebookF;
	iconGoogle = faGoogle;
	iconApple = faApple;

	user: LoginUserModel;
	socialUser: SocialUser;
	loadingLogin: boolean;

	loginMessage: LoginMessage | null;
	loginForm: FormGroup;
	hide = true;

	@Output() closeEvent = new EventEmitter<boolean>();
	@Output() changeActionEvent = new EventEmitter<string>();

	@ViewChild('inputPassword') inputPassword: ElementRef;
	private visible: boolean = false;

	constructor(
		public router: Router,
		public auth: AuthenticationService,
		private renderer: Renderer2,
		private userService: UserApiService,

		headerService: HeaderService,
		applicationStateService: ApplicationStateService
	) {

		super(applicationStateService)

		this.user = new LoginUserModel();
		this.socialUser = new SocialUser();
		this.loadingLogin = false;

		this.loginMessage = null;
		this.loginForm = new FormGroup({
			email: new FormControl(this.user.email, [Validators.required, Validators.email]),
			password: new FormControl(this.user.password, [Validators.required, Validators.minLength(6)])
		});
		if (this.device == this.devices.MOBILE)
			headerService.set_rutaBack("acceso/options/acceder");
		else
			headerService.set_rutaBack("/");

		if (this.auth.isAuthenticated()) {

			this.router.navigateByUrl('');

		}

	}

	public hidePass() {
		this.hide = !this.hide;
	}

	closeModal() {
		this.closeEvent.emit(true);
	}

	changeAction(action: string) {
		this.changeActionEvent.emit(action);
	}

	/**
	 * Method that receives the submitted form from the view.
	 */
	public login(): void {

		if (this.loginForm.valid) {

			this.loginMessage = null;
			this.loadingLogin = true;

			this.user.email = this.loginForm.controls.email.value;
			this.user.password = this.loginForm.controls.password.value;

			this.auth.login(this.user).subscribe((authResponse: any) => {

				this.loadingLogin = false;
				this.loginMessage = { type: 'success', message: 'Bienvenido, [NOMBRE USUARIO]' };
				this.auth.saveToken(authResponse.token, EAuthenticationAvailableOptions.JWT_LOGIN);

				this.userService.getDataUser();

				logEvent(this.analytics, "login")
				if (!localStorage.getItem("last-url"))
					this.router.navigateByUrl('');
				else {
					var url = localStorage.getItem("last-url")

					if (localStorage.getItem("empresa"))
						url = '/cliente/ingresar'
					else
						localStorage.removeItem("last-url")

					this.router.navigate([url])
				}
				this.closeModal();
			}, (error) => {

				this.loadingLogin = false;
				this.loginMessage = { type: 'error', message: 'Email o contraseña no válidos.' };

			});

		} else {

			this.loginMessage = { type: 'error', message: 'Email o contraseña no válidos.' };

		}

	}


	public loginGoogle(): void {

		this.auth.loginGoogle().then(social => {

			this.socialUser = social;
			this.auth.sendLoginGoogleAPI(this.socialUser.authToken).subscribe((resp: any) => {

				this.auth.saveToken(resp.token, EAuthenticationAvailableOptions.GOOGLE_LOGIN);

				this.userService.getDataUser();

				if (!localStorage.getItem("last-url"))
					this.router.navigateByUrl('');
				else {
					var url = localStorage.getItem("last-url")

					if (localStorage.getItem("empresa"))
						url = '/cliente/ingresar'
					else
						localStorage.removeItem("last-url")

					this.router.navigate([url])
				}
				this.closeModal();
			}, (error) => {

				console.log(error);

				this.loginMessage = { type: 'error', message: 'Error al iniciar sesión con Google: ' + error };

			});

		}).catch(err => {

			console.log(err);

			this.loginMessage = { type: 'error', message: 'Error al iniciar sesión con Google '};

		});

	}




	/**
	 * Login on the App with Facebook services.
	 */
	public loginFacebook(): void {

		this.auth.loginFacebook().then(social => {

			this.socialUser = social;
			this.auth.sendLoginFacebookAPI(this.socialUser.authToken, this.socialUser.id).subscribe((resp: any) => {

				this.auth.saveToken(resp.token, EAuthenticationAvailableOptions.FACEBOOK_LOGIN);

				this.userService.getDataUser();

				if (!localStorage.getItem("last-url"))
					this.router.navigateByUrl('');
				else {
					var url = localStorage.getItem("last-url")

					if (localStorage.getItem("empresa"))
						url = '/cliente/ingresar'
					else
						localStorage.removeItem("last-url")

					this.router.navigate([url])
				}
				this.closeModal();
			}, (error) => {

				this.loginMessage = { type: 'error', message: 'Error al iniciar sesión con Facebook: ' + error.error };

			});

		}).catch(err => {

			this.loginMessage = { type: 'error', message: 'Error al iniciar sesión con Facebook: ' + err.error };

		});

	}

	loginApple(event): void {
		event.preventDefault();
		const provider = new OAuthProvider("com.miloto.com") // com.miloto.MilotoWebService apple.com

		const auth = getAuth()
		signInWithPopup(auth, provider)
			.then((res) => {

				//user data
				const user = res.user;

				// Apple credential
				const credential = OAuthProvider.credentialFromResult(res);
				//const accessToken = credential.accessToken;
				const idToken = credential.idToken;

				this.auth.sendLoginAppleAPI(idToken, user.uid).subscribe((res: any) => {
					this.auth.saveToken(res.token, EAuthenticationAvailableOptions.JWT_LOGIN);

					this.userService.getDataUser();
					
					if (!localStorage.getItem("last-url"))
						this.router.navigateByUrl('');
					else {
						var url = localStorage.getItem("last-url")

						if (localStorage.getItem("empresa"))
							url = '/cliente/ingresar'
						else
							localStorage.removeItem("last-url")

						this.router.navigate([url])
					}
					this.closeModal();
				}, err => {
					this.loginMessage = { type: 'error', message: 'Error al iniciar sesión con Apple: ' + err.message };
				})

			})
			.catch((err) => {
				this.loginMessage = { type: 'error', message: 'Error al iniciar sesión con Apple: ' + err.message };
			})
	}

}
