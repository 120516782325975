export enum EGamesId {
    BONOLOTO = 1,
	EURODREAMS = 2,
    EUROMILLONES = 3,
    PRIMITIVA = 4,
    EL_GORDO = 5,
    LOTERIA_DE_NAVIDAD = 6,
    LOTERIA_NACIONAL = 7,
    LOTERIA_DE_EL_NIÑO = 8,
    EL_MILLON = 9,
    LLUVIA_DE_MILLONES = 10,
    QUINIELA = 11,
  }
  