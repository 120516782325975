import { Component, Inject } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { MovimientoDetails } from "@models/MovimientoDetails.model";

@Component({
    selector:'app-bottom-sheet-movimientos',
    templateUrl:'bottomSheetMovimiento.component.html',
    styleUrls:['bottomSheetMovimiento.component.scss']
})
export class BottomSheetMovimientos
{
    public movimiento: MovimientoDetails = new MovimientoDetails()

    constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: {movimiento: MovimientoDetails},
    private bottomSheetMovimientos: MatBottomSheetRef<BottomSheetMovimientos>) 
    {
        this.movimiento = data.movimiento
    }

    dimiss()
    {
        this.bottomSheetMovimientos.dismiss();
    }
}