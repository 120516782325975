import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRouterService } from '@shared/services/api-router.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import * as moment from 'moment';
import { shareReplay } from 'rxjs/operators';
import { url } from 'inspector';

/**
 * Games API service. Provides the app with games data from the API.
 */
@Injectable({
	providedIn: 'root'
})
export class GamesApiservice extends ApiRouterService {

	public drawsByType$: Observable<any>
	public numbersLotteryByDateObservable$: Observable<any>
	public numbersLotteryDayObservable$: Observable<any>

	private sorteosAvanzadoSubject = new BehaviorSubject<any>(null);
	public sorteosAvanzadoObservable$: Observable<any> = this.sorteosAvanzadoSubject.asObservable();

	private packsSubject = new BehaviorSubject<any[]>([]);
	public packsObservable$: Observable<any[]> = this.packsSubject.asObservable();


	constructor(

		private http: HttpClient,
		private authService: AuthenticationService,

	) {

		super();

	}

	/**
	 * Gets the next dates of the game passed as parameter.
	 * @param gameId Id of the game to search.
	 * @returns Callback from the API.
	 */
	public getNextDates(gameId: number): void {

		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const currentDate = new Date();
		const formattedDate = moment(currentDate).format('YYYY-MM-DD');
		const httpOptions = { headers: this.httpHeaders };
		this.sorteosAvanzadoObservable$ = this.http.get(`${this.URL}sorteos/avanzado?sort=fecha&fecha=>=${formattedDate}&limit=-69&juego_id=${gameId}&activos=1`, httpOptions);

	}

	/**
	 * Gets the next dates of the game passed as parameter.
	 * @param gameId Id of the game to search.
	 * @returns Callback from the API.
	 */
	public getNumbersLotteryDay(gameId: number, date: string, q: string = '', page: number = 1): void {

		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const currentDate = new Date();
		const httpOptions = { headers: this.httpHeaders };
		this.numbersLotteryDayObservable$ = this.http.get(`${this.URL}stock?fecha=${date}&juego_id=${gameId}&page=${page}&q=${q}`, httpOptions).pipe(shareReplay());

	}

	/**
	 * Gets the next dates of the game passed as parameter.
	 * @param gameId Id of the game to search.
	 * @returns Callback from the API.
	 */
	public getNumberLottery(drawID: number): Observable<any> {

		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		return this.http.get(`${this.URL}sorteos/${drawID}/numeroRandom?cantidad=12`, httpOptions);

	}

		/**
	 * Gets the available packs for a specific game.
	 * @param gameId Id of the game to get packs for.
	 * @returns Observable with the packs data.
	 */
	public getPacks(gameId: number): Observable<any[]> {
		const token = this.authService.loadToken();
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		return this.http.get<any[]>(`${this.URL}packs?game_id=${gameId}`, httpOptions).pipe(
			shareReplay()
		);
	}

	/**
	 * Purchases a pack for the current user.
	 * @param packId Id of the pack to purchase.
	 * @param gameId Id of the game the pack belongs to.
	 * @returns Observable with the purchase result.
	 */
	public purchasePack(packId: number, gameId: number): Observable<any> {
		const token = this.authService.loadToken();
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}packs/purchase`, { pack_id: packId, game_id: gameId }, httpOptions);
	}

	public getNumberLotteryByDate(date: string, gameId: number): void {

		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		this.numbersLotteryByDateObservable$ = this.http.get(`${this.URL}sorteos/${date}/numeroRandom/${gameId}?cantidad=12`, httpOptions);

	}

	public newSearchLottery(date: string, juego_id: number = 6, q: string = "_____", cantidad: number = 0, capicula: boolean = false, page: number = 1): Observable<any> {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };

		return this.http.get(`${this.URL}newStockSearch?fecha=${date}&juego_id=${juego_id}&q=${q}&cantidad=${cantidad}&capicua=${capicula ? 1 : 0}&page=${page}`, httpOptions);
	}

	public getLottery(date: string, juego_id: number = 7): Observable<any> {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const formattedDate = date;
		const httpOptions = { headers: this.httpHeaders };
		return this.http.get(`${this.URL}sorteos/avanzado?sort=fecha&fecha=>=${formattedDate}&limit=50&juego_id=${juego_id}&activos=1`, httpOptions);
	}

	public getFormasEnvio(empresa_id = 0): Observable<any> {
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
		});
		const httpOptions = { headers: this.httpHeaders };

		let queryParam = empresa_id > 0 ? "?empresa_id=" + empresa_id : "";

		return this.http.get(`${this.URL}envios${queryParam}`, httpOptions);
	}

	/**
	 * Return sell points of a ticket
	 * @param params - parameters of date and number lottery, 
	 */
	public getSellPoints(params: any) {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		let cliente_id = tokenJWT.idClient;

		return this.http.post(`${this.URL}personas/${cliente_id}/tickets/busqueda`, JSON.stringify(params), httpOptions);

	}

	public setPromotionCode(bodyCodigo: any): Observable<any> {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}personas/${tokenJWT.idClient}/descuento`, { codigo: bodyCodigo }, httpOptions);
	}

	public getBannerImg(gameId: number): Observable<any> {
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
		});
		const httpOptions = { headers: this.httpHeaders };

		return this.http.get(`${this.URL}juegos/${gameId}/banner`, httpOptions);
	}

	/**
	 * Gets the numbers associated with a specific pack and sorteo.
	 * @param packId Id of the pack to get numbers for.
	 * @param sorteoId Id of the sorteo.
	 * @returns Observable with the pack numbers data.
	 */
	public getPackNumbers(packId: number, sorteo: any): Observable<any> {
	    const token = this.authService.loadToken();
	    this.httpHeaders = new HttpHeaders({
	        'Content-Type': 'application/json',
	        token
	    });
	    const httpOptions = { headers: this.httpHeaders };
	    return this.http.get(`${this.URL}packs/${packId}?sorteo=${sorteo}`, httpOptions);
	}

}
