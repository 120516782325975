<div class="bottomSheetContainer" (click)="dimiss()">
    <div class="button">
        <div class="btn-ln">
            <div class="line">

            </div>
            <h4>Resultados</h4>
        </div>
    </div>
    <div class="container">
        <span class="match-title">Jª{{draw?.auxValues?.jornada}} {{draw.drawDate}}</span>
        <cdk-virtual-scroll-viewport class="scroll">
            <div class="match-row" *ngFor="let match of draw?.auxValues?.footballMatches; let i=index">
                <div class="match-container">

                    <span class="match-number" [ngClass]="i == draw?.auxValues?.footballMatches.length -1 ? 'match-number-pleno15' : ''">
                        <span class="number">{{i+1}}</span>
                    </span>
                    
                    <span class="match-teams">
                        <span class="team">{{match[0]}}</span>
                        <span class="team">{{match[1]}}</span>
                    </span>

                    <div *ngIf="i != draw?.auxValues?.footballMatches.length -1; then normalMatch else ultimo"></div>
                    <ng-template #normalMatch>
                        <span class="match-results">
                            <span class="result" [ngClass]="draw?.drawNumber[i] == 1 ? 'winner' : ''">
                                <span class="result-text" >1</span>
                            </span>
                            <span class="result" [ngClass]="draw?.drawNumber[i] === 'X' ? 'winner' : ''">
                                <span class="result-text" >X</span>
                            </span>
                            <span class="result" [ngClass]="draw?.drawNumber[i] == 2 ? 'winner' : ''">
                                <span class="result-text" >2</span>
                            </span>
                        </span>
                    </ng-template>
    
                    <ng-template #ultimo>
                        <div class="match-pleno15">
                            <span class="match-results">
                                <span class="result" [ngClass]="draw?.drawNumber[i] == 0 ? 'winner' : ''">
                                    <span class="result-text" >0</span>
                                </span>
                                <span class="result" [ngClass]="draw?.drawNumber[i] == 1 ? 'winner' : ''">
                                    <span class="result-text" >1</span>
                                </span>
                                <span class="result" [ngClass]="draw?.drawNumber[i] === '2' ? 'winner' : ''">
                                    <span class="result-text" >2</span>
                                </span>
                                <span class="result" [ngClass]="draw?.drawNumber[i] == M ? 'winner' : ''">
                                    <span class="result-text" >M</span>
                                </span>
                            </span>
                            <span class="match-results">
                                <span class="result" [ngClass]="draw?.drawNumber[i+1] == 0 ? 'winner' : ''">
                                    <span class="result-text" >0</span>
                                </span>
                                <span class="result" [ngClass]="draw?.drawNumber[i+1] == 1 ? 'winner' : ''">
                                    <span class="result-text" >1</span>
                                </span>
                                <span class="result" [ngClass]="draw?.drawNumber[i+1] === '2' ? 'winner' : ''">
                                    <span class="result-text" >2</span>
                                </span>
                                <span class="result" [ngClass]="draw?.drawNumber[i+1] == M ? 'winner' : ''">
                                    <span class="result-text" >M</span>
                                </span>
                            </span>
                        </div>
                    </ng-template>

                </div>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
</div>