import { NgModule } from '@angular/core';
import { GlobalModule } from 'src/app/shared/modules/global.module';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { BlankComponent } from './blank/blank.component';
import { changePasswordComponent } from './change-password/change-password.component';
import { authModalComponent } from './desktop/authModal/authModal.component';
import { LoginDesktopComponent } from './desktop/login/login-desktop.component';
import { RecoverDesktopComponent } from './desktop/recover/recover-desktop.component';
import { RegisterDesktopComponent } from './desktop/register/register-desktop.component';
import { inicioAuthComponent } from './inicio/inicio-auth.component';
import { authHeaderComponent } from './layouts/components/header/auth-header.component';
import { LoginComponent } from './login/login.component';
import { optionsMenuComponent } from './optionsMenu/options-menu.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { RegisterComponent } from './register/register.component';

import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';

@NgModule({
  declarations: [
    BlankComponent,
    LoginComponent,
    RegisterComponent,
    RecoverPasswordComponent,
    authHeaderComponent,
    optionsMenuComponent,
    inicioAuthComponent,
    changePasswordComponent,
    authModalComponent,
    LoginDesktopComponent,
    RegisterDesktopComponent,
	RecoverDesktopComponent
  ],
  imports: [
    GlobalModule,
    AuthenticationRoutingModule,
	GoogleSigninButtonModule,
  ],
  exports: [],
})
export class AuthenticationModule { }
