<div class="bottomSheetContainer" >
    <div class="button" (click)="dimiss()">
        <div class="btn-ln">
            <div class="line">

            </div>
            <h4>Regalar Jugada</h4>
        </div>
    </div>
    <div class="container">

        <div class="gift-wrapper">
    
            <div class="separator"></div>
    
            <form [formGroup]="giftForm" (ngSubmit)="giftPlay()" class="form-gift">
        
                <mat-form-field class="email-gift-input" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input id="email" name="email" formControlName="email" type="email" matInput
                           placeholder="Ex. pat@example.com">
                    <mat-error *ngIf="email.invalid && (email.dirty || email.touched)">
                      ¡Introduce un email valido!
                    </mat-error>
                    <mat-error *ngIf="email.errors?.required">
                      Es necesario introducir un email
                    </mat-error>
                </mat-form-field>
                
                <mat-checkbox matInput class="checkbox-aceptar"
                    id="aceptar" name="aceptar"
                    type="checkbox"
                    formControlName="aceptar">
                    Acepto regalar la jugada
                </mat-checkbox>
    
                <button mat-button class="regalar-button" [disabled]="!giftForm.valid" type="submit" >Regalar</button>
        
            </form>
        
        
        </div>

    </div>
</div>