import { Component, Input, OnInit } from '@angular/core';
import { GamesApiservice } from '@api/services/games/games-api.service';
import * as moment from 'moment';
import { BetService } from '@modules/games/bet.service';
import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { CustomVirtualScroll } from '@classes/CustomVritualScoll';

/**
 * Component header, contains a list of days that have a draw in them
 */
@Component({
	selector: 'app-primary-header',
	templateUrl: './games-primary-header.component.html',
	styleUrls: [
		'./games-primary-header.component.scss',
	],
	providers: [
		{provide: VIRTUAL_SCROLL_STRATEGY, useClass:CustomVirtualScroll}
	]
})
export class GamesPrimaryHeaderComponent implements OnInit {

	public games: Array<{ date: string, dayName: string, day: number, month: string, gameId: number, selected: boolean }>;
	public addedGames: Array<{}>;
	public firstDay: boolean;
	public selected: boolean = false;
	public manual: boolean = false;
	public dataSelected: string = ""

	private freePlay = false

	@Input() drawDates: any;
	@Input() currentGame: any;

	constructor(
		private gamesApiService: GamesApiservice,
		private betService: BetService
	) {
		this.games = [];
		this.addedGames = [];
	}


	ngOnInit(): void {

		this.loadGames();
		this.loadFreePlay()
		this.betService.manualModeObservable$.subscribe((res: any) => {
			this.manual = res;
		});
	}

	loadFreePlay()
	{
		this.betService.freeBetObservable$.subscribe(res => {
			this.freePlay = res
			if(res)
			{
				this.games = this.games.map((value,index) => {
					if(index == 0)
						value.selected = true
					else
						value.selected == false
	
					return value
				})

				this.addedGames = []
				this.selected = false
			}
		})
	}

	/**
	 * Load the games that are going to be played in the next weeks.
	 */
	loadGames(): void {

		const days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
		const months = ['Ene.', 'Feb.', 'Mar.', 'Abr.', 'May.', 'Jun.', 'Jul.', 'Ago.', 'Sep.', 'Oct.', 'Nov.', 'Dic.'];

		this.gamesApiService.getNextDates(this.currentGame)
		this.gamesApiService.sorteosAvanzadoObservable$.subscribe(response => {
			const d = new Date();

			response.listadoSorteos.forEach((game: { fecha: string | number | Date , id: number}) => {

				const date = new Date(game.fecha);
				const dayName = days[date.getDay()];
				const day = date.getUTCDate();
				const month = months[date.getMonth()];
				const dateString = moment(date).format('YYYY-MM-DD');
				const gameId = game.id
				this.games.push({ date: dateString, dayName, day, month, gameId, selected: false });

			});
			this.betService.setGuindaPrice(response.listadoSorteos[0].juego.precio_guinda);

		});

	}

	/**
	 * Delete a game from de added games array that represents the days that the player will bet
	 * @param date The day of the game
	 */
	public dateSelected(date: string): void{

		if(this.freePlay)
		{
			for(var i = 0; i < this.games.length; i++){

				if(this.games[i].date == date){
				  this.games[i].selected = true;
				}
				else{
				  this.games[i].selected = false;
				}
			}

			this.betService.setDate(date)

			this.addedGames = []
		}

		const index: number = this.addedGames.indexOf(date);
		if (index !== -1) {

			this.selected=false;
			this.addedGames.splice(index, 1);
			this.betService.setAddedDays(this.addedGames);
			//necesito pasar el dato al componente de play-lottery para hacer la llamada correspondiente en el onInit


		} else {
			this.selected=true;
			this.addedGames.push(date);
			this.betService.setAddedDays(this.addedGames);

		}

	}

	scroll(positionDaySelected)
	{
		/*this.perfectScroll.directiveRef.update();
		this.perfectScroll.directiveRef.scrollToLeft(positionDaySelected);*/
	}

}

