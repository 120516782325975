// tslint:disable: max-line-length
// tslint:disable: triple-equals

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BetService } from '@modules/games/bet.service';
import { knuthShuffle } from 'knuth-shuffle';
import { IBet } from 'src/app/interfaces/plays/play-bet.interface';
import { IToast } from 'src/app/interfaces/toast.interface';
import { PlayBet } from '../play-bet';
import { EGamesId } from '@enums/common/games.enum';


const changeToSimpleBet = '¡Atención! Cambio a apuesta simple';
const changeToMultiple44 = 'Apuesta múltiple de 44.';
const changeToMultipleBet = '¡Atención! Cambio a apuesta múltiple';
const limitMaxBet = 'Ha llegado al límite de apuesta.';

import { ToastrService } from 'ngx-toastr';
import { InvitadoJugadaGratis } from '@models/invitado_jugadaGratis.model';
import { playFreeService } from '@api/services/playFree.service';

@Component({
	selector: 'app-manual-play-bet',
	templateUrl: './manual-play-bet.component.html',
	styleUrls: ['./manual-play-bet.component.scss'],
})
export class ManualPlayBetComponent extends PlayBet implements OnInit {

	public isSimpleBet: boolean;
	public multipleBet: IBet;
	public toast: IToast;
	public extraGame: Array<Array<number>>;
	public extraGameChecked: Array<number>;
	public freeBet: boolean;
	public betsAux: IBet[]

	public invitadoFreePlay: InvitadoJugadaGratis = new InvitadoJugadaGratis();

	constructor(

		public dialog: MatDialog,
		private toastrService: ToastrService,
		private betService: BetService,
		private playFreeService: playFreeService

	) {

		super();

	}

	ngOnInit(): void {

		this.freeBet = false;
		this.betService.freeBetObservable$.subscribe((res: any) => {
			this.freeBet = res;
		});
		this.options = this.betService.options;
		this.isSimpleBet = true;
		this.bets = [];
		this.betsAux = []
		this.extraGameChecked = [];
		this.loadBets();

	}

	styleHeaderBet(): object {
		if (this.options.idGame === EGamesId.EUROMILLONES) {
			// Color of color-euromillones, I can't put it like $color-euromillones
			return { 'border-color': '#4287f5', 'color': '#4287f5' };
		}
		if (this.options.idGame === EGamesId.BONOLOTO || this.options.idGame == EGamesId.EL_GORDO) {
			return { 'border-color': '#c92e2e', 'color': '#c92e2e' };
		}
		return {};
	}

	styleNumberBet(): object {

		switch (this.options.idGame) {
			case EGamesId.EUROMILLONES: {
				return { color: '#4287f5' };
			}
			case EGamesId.BONOLOTO: {
				return { color: '#007454' };
			}
		}
		return {};
	}

	styleBet(): object {
		if (this.options.idGame === EGamesId.EUROMILLONES) {
			// Color of color-euromillones, I can't put it like $color-euromillones
			//return {'border' : '1px solid #4287f5'};
			return {};
		}
		if (this.options.idGame === EGamesId.BONOLOTO || this.options.idGame == EGamesId.EL_GORDO) {
			return { 'border': '1px solid #ABF5DF' };
		}
		return {};
	}

	styleBetHeight(): object {
		switch (this.options.idGame) {
			case EGamesId.EUROMILLONES: {
				return { color: '#4287f5' };
			}
			case EGamesId.BONOLOTO: {
				return { height: '370px' };
			}
			case EGamesId.PRIMITIVA: {
				return { height: '370px' };
			}
		}
		return {};
	}

	styleIndexBet(): object {

		if (this.options.idGame === EGamesId.EUROMILLONES) {
			// Color of color-euromillones, I can't put it like $color-euromillones
			return { 'background-color': '#4287f5' };
		}
		if (this.options.idGame === EGamesId.BONOLOTO || this.options.idGame == EGamesId.EL_GORDO) {
			return { 'background-color': '#c92e2e' };
		}
		return {};
	}


	public emitPlay(): void {
		throw new Error('Method not implemented.');
	}

	protected createRandomValues(optionsType: string): number[] {

		if (optionsType === 'extraOptions' && this.options.idGame === EGamesId.EL_GORDO) {

			return knuthShuffle(Array.from({ length: this.options[optionsType].itemsPerBet }, (_, i) => i)).slice(0, this.options[optionsType].maxItemsPerBet);

		}

		return knuthShuffle(Array.from({ length: this.options[optionsType].itemsPerBet }, (_, i) => i + 1)).slice(0, this.options[optionsType].maxItemsPerBet);

	}

	public clearBets(): void {

		this.bets.forEach(bet => {
			this.clearBet(bet);
		});

		this.betsAux.forEach(bet => {
			this.clearBet(bet);
		});

	}

	protected createRandomManualBet(bet: IBet): void {

		console.log(bet)
		bet.numbersChecked = this.createRandomValues('numberBetOptions');
		bet.extraChecked = this.createRandomValues('extraOptions');
		bet.currentAction = 'clear';
		bet.leftCheckedNumbers = 0;
		bet.leftCheckedExtra = 0;

		if (this.options.idGame === EGamesId.PRIMITIVA && this.betService.getReintegro() === -1) {
			this.betService.setReintegro(bet.extraChecked[0]);
			this.checkExtraCell(bet.extraChecked[0].toString());
		}

		if(this.options.idGame === EGamesId.PRIMITIVA && bet.id != 0){
			bet.extraChecked = [];
		}


		this.betService.setBets(this.bets);



	}

	protected clearBet(bet: IBet): void {

		bet.numbersChecked = [];
		bet.extraChecked = [];
		bet.currentAction = 'auto';
		bet.leftCheckedNumbers = this.options.numberBetOptions.maxItemsPerBet;
		bet.leftCheckedExtra = this.options.extraOptions.maxItemsPerBet;
		const isSimpleBetPre = this.isSimpleBet;
		this.isSimpleBet = true;
		if (isSimpleBetPre !== this.isSimpleBet === true) {

			this.toastrService.info(changeToSimpleBet);

		}

		this.betService.setBets(this.bets);

	}

	public checkExtraCell(numberChecked: string): void {

		const numberChoosen = Number(numberChecked);


		if (this.options.idGame == 4 || this.options.idGame == 5) {

			if (this.extraGameChecked.includes(numberChoosen)) {

				this.extraGameChecked = this.removeItemFromArray(this.extraGameChecked, numberChecked);

			} else {

				if (this.extraGameChecked.length < this.options.extraOptions.maxItemsChoosen) {

					this.extraGameChecked.push(numberChoosen);

				} else {

					this.toastrService.clear();
					this.toastrService.error(limitMaxBet);

				}

			}

		}

		this.betService.setReintegro(this.extraGameChecked[0]);
		this.betService.setBets(this.bets);

	}

	public checkCell(numberChecked: string, idx: any, checkStar: boolean): void {

		const numberChoosen = Number(numberChecked);
		if (checkStar) {

			if (this.bets[idx].extraChecked.includes(numberChoosen)) {

				this.bets[idx].extraChecked = this.removeItemFromArray(this.bets[idx].extraChecked, numberChecked);
				const isSimpleBetPre = this.isSimpleBet;
				this.checkChangeBet(idx);
				if (isSimpleBetPre != this.isSimpleBet == true) {

					this.toastrService.clear();
					this.toastrService.info(changeToSimpleBet);
					this.bets[idx].leftCheckedExtra -= (this.options.extraOptions.maxItemsChoosen - this.options.extraOptions.maxItemsPerBet);
					this.bets[idx].leftCheckedNumbers -= (this.options.numberBetOptions.maxItemsChoosen - this.options.numberBetOptions.maxItemsPerBet);

				}
				this.bets[idx].leftCheckedExtra++;

			} else {

				if (this.bets[idx].extraChecked.length < this.options.extraOptions.maxItemsChoosen) {

					if (this.isSimpleBet) {

						if (this.options.extraOptions.maxItemsPerBet == this.bets[idx].extraChecked.length) {

							this.bets[idx].extraChecked.push(numberChoosen);
							this.bets[idx].leftCheckedExtra += (this.options.extraOptions.maxItemsChoosen - this.options.extraOptions.maxItemsPerBet);
							this.bets[idx].leftCheckedNumbers += (this.options.numberBetOptions.maxItemsChoosen - this.options.numberBetOptions.maxItemsPerBet);
							this.bets[idx].leftCheckedExtra--;
							this.checkChangeBet(idx);
							this.toastrService.clear();
							this.toastrService.info(changeToMultipleBet);

						} else {

							this.bets[idx].extraChecked.push(numberChoosen);
							this.bets[idx].leftCheckedExtra--;

						}

					} else {

						if (this.options.extraOptions.maxItemsChoosen == this.bets[idx].extraChecked.length) {

							this.toastrService.clear();
							this.toastrService.error(limitMaxBet);

						} else {

							this.bets[idx].extraChecked.push(numberChoosen);
							this.bets[idx].leftCheckedExtra--;

						}

					}

				} else {

					this.toastrService.clear();
					this.toastrService.error(limitMaxBet);

				}

			}

		} else {

			if (this.bets[idx].numbersChecked.includes(numberChoosen)) {

				this.bets[idx].numbersChecked = this.removeItemFromArray(this.bets[idx].numbersChecked, numberChecked);
				const isSimpleBetPre = this.isSimpleBet;
				this.checkChangeBet(idx);
				if (isSimpleBetPre != this.isSimpleBet == true) {

					this.toastrService.clear();
					this.toastrService.info(changeToSimpleBet);
					this.bets[idx].leftCheckedExtra -= (this.options.extraOptions.maxItemsChoosen - this.options.extraOptions.maxItemsPerBet);
					this.bets[idx].leftCheckedNumbers -= (this.options.numberBetOptions.maxItemsChoosen - this.options.numberBetOptions.maxItemsPerBet);

				}
				this.bets[idx].leftCheckedNumbers++;

			} else {

				if (this.bets[idx].numbersChecked.length == 4 && (this.options.idGame == 1 || this.options.idGame == 4)) {

					this.bets[idx].numbersChecked.push(numberChoosen);
					this.bets[idx].leftCheckedNumbers--;
					this.checkChangeBet(idx);
					this.toastrService.clear();
					this.toastrService.info(changeToMultiple44);

				} else {

					if (this.isSimpleBet) {

						if (this.options.numberBetOptions.maxItemsPerBet == this.bets[idx].numbersChecked.length) {

							this.bets[idx].numbersChecked.push(numberChoosen);
							this.bets[idx].leftCheckedExtra += (this.options.extraOptions.maxItemsChoosen - this.options.extraOptions.maxItemsPerBet);
							this.bets[idx].leftCheckedNumbers += (this.options.numberBetOptions.maxItemsChoosen - this.options.numberBetOptions.maxItemsPerBet);
							this.bets[idx].leftCheckedNumbers--;
							this.checkChangeBet(idx);
							this.toastrService.clear();
							this.toastrService.info(changeToMultipleBet);

						} else {

							this.bets[idx].numbersChecked.push(numberChoosen);
							this.bets[idx].leftCheckedNumbers--;

						}

					} else {

						if (this.options.numberBetOptions.maxItemsChoosen == this.bets[idx].numbersChecked.length) {

							this.toastrService.clear();
							this.toastrService.error(limitMaxBet);

						} else {

							this.bets[idx].numbersChecked.push(numberChoosen);
							this.bets[idx].leftCheckedNumbers--;
							this.checkChangeBet(idx)

						}

					}

				}

			}

		}
		if (this.bets[idx].numbersChecked.length > 0 || this.bets[idx].extraChecked.length > 0) {
			this.bets[idx].currentAction = 'clear';
		} else {
			this.bets[idx].currentAction = 'auto';
		}

		if (!this.isSimpleBet) {
			this.clearApuestasNoMultiples(idx)
			this.betService.setBets(this.betsAux)
		}
		else
			this.betService.setBets(this.bets);
	}

	private checkChangeBet(idx): void {

		// tslint:disable-next-line: max-line-length
		if (this.bets[idx].extraChecked.length > this.options.extraOptions.maxItemsPerBet || this.bets[idx].numbersChecked.length > this.options.numberBetOptions.maxItemsPerBet) { //Si es apuesta multiple

			this.isSimpleBet = false;
			this.bets[idx].currentAction = 'clear';

		} else {

			if (this.betsAux.length > 0) {
				//this.bets = this.betsAux
				this.betsAux = []
			}

			this.isSimpleBet = true;

		}

		/**
		 * Modo multiple especial de 44
		 */
		if (this.bets[idx].numbersChecked.length == 5 && (this.options.idGame == 1 || this.options.idGame == 4 || this.options.idGame == 2)) {
			this.isSimpleBet = false
		}

		//this.multipleBet = this.bets[idx];

	}

	private clearApuestasNoMultiples(idx) {

		var selft = this

		this.betsAux = this.bets.map<IBet>(function (x, i) {

			let betItem: IBet = {
				id: i,
				label: i + 1,
				mainMatrix: selft.initGameMatrix(selft.options.numberBetOptions.rows, selft.options.numberBetOptions.columns, selft.options.numberBetOptions.firstItem, selft.options.numberBetOptions.itemsPerBet, selft.options.numberBetOptions.orientation),
				extraMatrix: selft.initGameMatrix(selft.options.extraOptions.rows, selft.options.extraOptions.columns, selft.options.extraOptions.firstItem, selft.options.extraOptions.itemsPerBet, selft.options.extraOptions.orientation),
				numbersChecked: x.numbersChecked,
				extraChecked: x.extraChecked,
				currentAction: x.currentAction,
				leftCheckedNumbers: x.leftCheckedNumbers,
				leftCheckedExtra: x.leftCheckedExtra
			}

			if (i != idx) {
				betItem.numbersChecked = []
				betItem.extraChecked = []
				betItem.currentAction = 'auto'
				betItem.leftCheckedNumbers = selft.options.numberBetOptions.maxItemsPerBet,
					betItem.leftCheckedExtra = selft.options.extraOptions.maxItemsPerBet
			}

			return betItem
		})
	}

	private loadBets(): void {

		for (let i = 0; i < this.options.totalBets; i++) {

			const bet: IBet = {
				id: i,
				label: i + 1,
				mainMatrix: this.initGameMatrix(this.options.numberBetOptions.rows, this.options.numberBetOptions.columns, this.options.numberBetOptions.firstItem, this.options.numberBetOptions.itemsPerBet, this.options.numberBetOptions.orientation),
				extraMatrix: this.initGameMatrix(this.options.extraOptions.rows, this.options.extraOptions.columns, this.options.extraOptions.firstItem, this.options.extraOptions.itemsPerBet, this.options.extraOptions.orientation),
				numbersChecked: [],
				extraChecked: [],
				currentAction: 'auto',
				leftCheckedNumbers: this.options.numberBetOptions.maxItemsPerBet,
				leftCheckedExtra: this.options.extraOptions.maxItemsPerBet
			};
			this.bets.push(bet);
		}

		if (this.options.idGame == 4 || this.options.idGame == 5) {

			this.extraGame = this.initGameMatrix(this.options.extraOptions.rows, this.options.extraOptions.columns, this.options.extraOptions.firstItem, this.options.extraOptions.itemsPerBet, this.options.extraOptions.orientation);

		} else {

			this.bets.forEach(b => {

				b.extraMatrix = this.initGameMatrix(this.options.extraOptions.rows, this.options.extraOptions.columns, this.options.extraOptions.firstItem, this.options.extraOptions.itemsPerBet, this.options.extraOptions.orientation);

			});

		}


	}

	setInvitado() {
		this.playFreeService.setInvitadoJugadaGratis(this.invitadoFreePlay);
	}

}
