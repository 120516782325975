<app-spinner *ngIf="loading" forceTo='full-sized'></app-spinner>
<div class="register-wrapper">

	<div class="header">
		<app-auth-header [title]="'Registrate con email'"></app-auth-header>
	</div>

	<div class="body">

		<form id="register-form" autocomplete="off" novalidate [formGroup]="registerForm" (ngSubmit)="register()"
			class="register-form">

			<mat-form-field appearance="outline" class="form-input">
				<mat-label>Nombre</mat-label>
				<input matInput placeholder="Nombre" formControlName="name" required autocomplete="off">
			</mat-form-field>
	
			<mat-form-field appearance="outline" class="form-input">
				<mat-label>Email</mat-label>
				<input matInput type="email" placeholder="Email" formControlName="email" required autocomplete="off">
			</mat-form-field>
	
			<mat-form-field appearance="outline" class="form-input">
				<mat-label>Contraseña</mat-label>
				<input matInput [type]="hide ? 'password' : 'text'" formControlName="password"
					required (input)="nofirst()" autocomplete="off">
				<mat-button matSuffix (click)="hidePass()" class="button-pass">
					<img *ngIf="!hide" src="/assets/images/icons/loginYregistro/eye-off.svg" alt="Ocultar contraseña">
					<img *ngIf="hide" src="/assets/images/icons/loginYregistro/eye-show.svg" alt="Mostrar contraseña">
				</mat-button>
			</mat-form-field>
	
			<mat-form-field appearance="outline" class="form-input">
				<mat-label>Fecha de Nacimento</mat-label>
				<input matInput [matDatepicker]="datePick" placeholder="Fecha de nacimiento" formControlName="birthDate" id="birthDate"
					readonly (click)="datePick.open()" [max]="maxDate" required>
				<mat-datepicker-toggle matSuffix [for]="datePick"></mat-datepicker-toggle>
				<mat-datepicker #datePick [startAt]="age18"></mat-datepicker>
				<mat-error *ngIf="birthDateInvalid">Debe ser mayor de edad.</mat-error>
			</mat-form-field>

			<div class="errors">
				<span class="error" *ngIf="passwordInvalid" style="padding-bottom: 2rem;">La contraseña debe ser de al menos 8 caracteres y incluir al menos una mayúscula, minúscula, número y caracter especial.</span>
				<span class="info" *ngIf="first" style="padding-bottom: 2rem;">La contraseña debe ser de al menos 8 caracteres y incluir al menos una mayúscula, minúscula, número y caracter especial.</span>
			</div>

			<div style="padding-bottom: 2rem;"></div>
	
			<span class="error-message"
				*ngIf="registerMessage && (registerMessage.type == 'warning' || registerMessage.type == 'error')">{{
				registerMessage.message }}</span>
	
			<button mat-raised-button color="miloto" type="submit" [disabled]="!registerForm.valid || registerLoading"
				class="register-button">
				<span *ngIf="!registerLoading">Crear cuenta</span>
				<mat-icon *ngIf="registerLoading">
					<mat-spinner diameter="20"></mat-spinner>
				</mat-icon>
			</button>

		</form>

	</div>

	<div class="footer"></div>

</div>

<link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Open+Sans" />