import { VIRTUAL_SCROLL_STRATEGY } from "@angular/cdk/scrolling";
import { AfterViewChecked, Component, Inject, Renderer2 } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { CustomVirtualScroll } from "@classes/CustomVritualScoll";
import { MPlay } from "@models/api-models/plays-api.models";
import { ResultDraw } from '@models/resultDraw.model';
import { PlaysAPIService } from '@api/services/games/plays-api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector:'app-bottomSheetAbono',
    templateUrl:'./bottomSheetAbono.component.html',
    styleUrls:['./bottomSheetAbono.component.scss']
    ,providers:[{provide:VIRTUAL_SCROLL_STRATEGY,useClass:CustomVirtualScroll}]
})
export class bottomSheetAbonoComponent implements AfterViewChecked
{

    random = false;

    constructor(private bottomSheetAbono: MatBottomSheetRef<bottomSheetAbonoComponent>,
                private playsAPIService: PlaysAPIService,
                private router: Router,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: {},
                private renderer: Renderer2
    ){

    }

    ngAfterViewChecked()
    {

    }

    dimiss()
    {
        this.bottomSheetAbono.dismiss();
    }


}