<div class="modal">
	<h4 mat-dialog-title>{{data.title}}</h4>
	<div class="curpo" mat-dialog-content>{{data.texto}}</div>

	<div class="options">
		<label *ngFor="let option of data.options" class="custom-radio-checkbox">
			<input class="custom-radio-checkbox__input" type="radio" name="reason" [value]="option" [(ngModel)]="selectedOption" />
			<span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
			<span class="custom-radio-checkbox__text">{{ option }}</span>
		</label>
	</div>
	<input type="text" [(ngModel)]="inputValue" [placeholder]="data.placeholder" class="form-control" [disabled]="selectedOption !== 'Otro:'"/>

	<div class="footer">
		<button class="btn btn-outline-primary" (click)="cancel()">
			{{data.buttonCancel}}
		</button>
		<button class="btn btn-primary ml2"  (click)="confirm()">
			{{data.buttonConfirm}}
		</button>
	</div>
</div>
