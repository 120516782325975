import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MantenimientoService
{
    mantenimiento = false
    private _mantenimiento = new BehaviorSubject<boolean>(this.mantenimiento);
    mantenimientoOb: Observable<boolean> = this._mantenimiento.asObservable();

    setMantenimiento(mantenimiento: boolean) {
        this.mantenimiento = mantenimiento
        this._mantenimiento.next(mantenimiento);
    }
}