import { VIRTUAL_SCROLL_STRATEGY } from "@angular/cdk/scrolling";
import { Component, Inject, NgZone } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { Router } from "@angular/router";
import { GroupService } from "@api/services/group.service";
import { UserApiService } from "@api/services/user.service";
import { CustomVirtualScroll } from "@classes/CustomVritualScoll";
import { MPlay } from "@models/api-models/plays-api.models";
import { Mygroup } from "@models/group.model";
import { BetService } from "@modules/games/bet.service";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: 'app-bottom-sheet-repetir-jugada',
    templateUrl: './bottomSheetRepetirJugada.component.html',
    styleUrls: ['./bottomSheetRepetirJugada.component.scss'],
    providers:[{provide:VIRTUAL_SCROLL_STRATEGY,useClass:CustomVirtualScroll}]
})
export class BottomSheetRepetirJugadaComponent
{
    todaSemana = 0
    modoGrupo: Boolean = false
	escaneada: boolean = false
    play: MPlay = null
    disabledButton = false
    mygroup: Mygroup = null
    saldo = 0;
    precioTotal = 0

    constructor(
        private bottomSheetRepetirJugadaComponent: MatBottomSheetRef<BottomSheetRepetirJugadaComponent>,
        private router: Router,
        public userService: UserApiService,
        public groupService: GroupService,
		private toastr: ToastrService,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: {play: MPlay, modoGrupo: Boolean, escaneada: boolean},
        private betService: BetService,
        private _ngZone: NgZone){
        this.modoGrupo = data.modoGrupo
        this.play = data.play
		this.escaneada = data.escaneada

        if(this.modoGrupo)
            this.loadGroup()
        else
            this.loadClient()

        /*this.router.routeReuseStrategy.shouldReuseRoute = function(){ //Investigar esta opcion dado que podria ayudarnos a no destruir y recrear componentes constantemente
            return false
        }*/
    }

    loadGroup()
    {
        this.groupService.getGroup.subscribe(res=>{
            this.saldo = Number(res.saldo.saldo_tarjeta) + Number(res.saldo.saldo_premios) + Number(res.saldo.saldo_no_disponible)
            this.mygroup = res
        })
    }

    loadClient()
    {
        this.userService.usuarioObservable.subscribe(res=>{
            if(res)
                this.saldo = res.client.getSaldoTotal()
        })
    }

    repeat()
    {
        this.disabledButton = true

        let grupo_id = -1;

        if(this.mygroup)
            grupo_id =  this.mygroup.cliente_id

        this.betService.repeatPlay(this.data.play.id,grupo_id,this.todaSemana, this.escaneada).subscribe(res=>{
            if(res.status == 200)
            {
                this.betService.getPlayData(res.data.pedido_id).subscribe(res2 => this.onSuccessRepeatResponse(res2))
            }
            else{
                this.disabledButton = false 
				this.toastr.error(res.message)
			}

        })
    }

    private onSuccessRepeatResponse(res: any) {

        res.forEach( play => {
            this.precioTotal += Number(play.precio)
        });

        const betId = res[0].apuestas[0].jugada_id;
        
        //Actualizamos el saldo del usuario
        this.userService.setSaldo(this.saldo - this.precioTotal)

        this.dismiss()
        
        if(!this.modoGrupo)
                this.router.navigate(['/cliente/jugadas/'+betId]);
            else
            {
                this.groupService.get_grupo(this.mygroup.cliente_id).subscribe((myGroup: Mygroup) => {
                    this.groupService.set_group(myGroup)
                    this.router.navigate(['/','cliente','mis-grupos','detalles',this.mygroup.cliente_id,this.mygroup.nombre,'jugada',betId]);
                })
                
            }
        
    }

    dismiss()
    {
        this.bottomSheetRepetirJugadaComponent.dismiss();
    }
}