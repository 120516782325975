import { VIRTUAL_SCROLL_STRATEGY } from "@angular/cdk/scrolling";
import { AfterViewChecked, Component, Inject, Renderer2 } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { CustomVirtualScroll } from "@classes/CustomVritualScoll";
import { MPlay } from "@models/api-models/plays-api.models";
import { ResultDraw } from '@models/resultDraw.model';

@Component({
    selector:'app-bottomSheetQuinielaResult',
    templateUrl:'./bottomSheetQuinielaResult.component.html',
    styleUrls:['./bottomSheetQuinielaResult.component.scss']
    ,providers:[{provide:VIRTUAL_SCROLL_STRATEGY,useClass:CustomVirtualScroll}]
})
export class bottomSheetQuinielaResultComponent implements AfterViewChecked
{
    draw: ResultDraw;
    prueba = ['1','2','3'];

    constructor(private bottomSheetQuinielaResult: MatBottomSheetRef<bottomSheetQuinielaResultComponent>,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: {draw: ResultDraw},
                private renderer: Renderer2
    ){
        this.draw=data.draw;
    }

    ngAfterViewChecked()
    {
        this.setRandomColor();
    }

    dimiss()
    {
        this.bottomSheetQuinielaResult.dismiss();
    }

    getFirstLetter(letra: string)
    {
        return letra.toLocaleUpperCase()[0];
    }

    setRandomColor() 
    {
        document.querySelectorAll("div[class='letter']").forEach(x=>{
            this.renderer.setStyle(x,"background-color",this.generateColors());
        })
    }

    private generateColors()
    {
        let letters = '0123456789ABCDEF';
        let color = '#';

        for (var i = 0; i < 6; i++){
            color += letters[Math.floor(Math.random() * 16)];
        }

        return color;
    }
}