<div class="login-wrapper">
	<form id="login-form" [formGroup]="loginForm" (ngSubmit)="login()" class="login-form">

		<mat-form-field class="form-input" appearance="outline">
			<mat-label>Email</mat-label>
			<input matInput type="email" placeholder="Email" formControlName="email" required>
		</mat-form-field>

		<mat-form-field class="form-input" appearance="outline">
			<mat-label>Contraseña</mat-label>
			<input matInput placeholder="Contraseña" [type]="hide ? 'password' : 'text'" formControlName="password"
				required>
			<button matSuffix type="button" (click)="hidePass()" class="button-pass">
				<img *ngIf="!hide" src="/assets/images/icons/loginYregistro/eye-off.svg" alt="Ocultar contraseña">
				<img *ngIf="hide" src="/assets/images/icons/loginYregistro/eye-show.svg" alt="Mostrar contraseña">
			</button>
		</mat-form-field>

		<span class="error-message"
			*ngIf="loginMessage && (loginMessage.type == 'warning' || loginMessage.type == 'error')">{{
			loginMessage.message }}</span>

		<button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid || loadingLogin"
			class="login-button">
			<span *ngIf="!loadingLogin">Acceder a Miloto</span>
			<mat-icon *ngIf="loadingLogin">
				<mat-spinner diameter="20"></mat-spinner>
			</mat-icon>
		</button>


	</form>



	<div class="social-wrapper">

		<!-- Quitar Cuando
		<button mat-raised-button class="social-button" (click)="loginGoogle()">
			<img src="/assets/images/pages/options-auth/logo-google.svg" />
			Acceder con Google
		</button>
		-->

		<asl-google-signin-button class="social-button" type='standard' size='large' shape='pill' theme='outline' text="continue_with" logo_alignment="left" locale="es" width="240" (click)="this.closeModal()"></asl-google-signin-button>

		<button mat-raised-button class="social-button" (click)="loginApple($event)">
			<img src="/assets/images/icons/appel_icon.png" />
			Acceder con Apple
		</button>
		<!--
		<button mat-raised-button class="social-button" (click)="loginFacebook()">
			<img src="/assets/images/pages/options-auth/logo-facebook.svg" />
			Acceder con Facebook
		</button>
		-->
	</div>

	<div class="notice">
		<a class="text-info">¿Aun no tienes cuenta?
			<strong class="miloto-primary" (click)="changeAction('register')">
				<u>Registrate</u>
			</strong>
		</a>
	</div>

	<div class="notice">
		<a class="text-info">
			¿Has Olvidado tu contraseña?
			<strong (click)="changeAction('recover')" class="miloto-primary">
				<u>Recuperar</u>
			</strong>
		</a>
	</div>

</div>


<link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Open+Sans" />