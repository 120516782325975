import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn:'root'
})
export class FooterService
{
    private showFooter: boolean = true;
    
    private showFooter$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.showFooter);
    public ShowFooter: Observable<boolean> = this.showFooter$.asObservable();

    set_Show_footer(show: boolean)
    {
        this.showFooter$.next(show);
    }
}