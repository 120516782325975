<div class="bottomSheetContainer" >
	<div class="line" (click)="dimiss()"></div>
	<span class="title" (click)="dimiss()">Agenda</span>
	<mat-form-field appearance="outline" class="buscador">
		<mat-label>Contacto</mat-label>
		<input matInput aria-label="Contacto" [formControl]="ContactCtrl" placeholder="Contacto..."/>
	</mat-form-field>
    <div class="container">
		<div *ngFor="let contact of filteredContacts | async" class="contact" (click)="selectContact(contact)">
			<div class="initial-wrapper" style="background-color:{{colorGenerator.strToColor(contact.name)}}">
				<span>{{contact.initials}}</span>
			</div>
			<div class="names">
				<span class="text">{{contact.name}}</span>
				<span class="text number">{{contact.number}}</span>
			</div>
			<img class="icon" src="./assets/images/icons/miloto.svg">
		</div>
    </div>
</div>