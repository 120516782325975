<div class="tour-empresa-core-wrapper">

	<ng-container *ngIf="device === devices.DESKTOP">
  
	  <app-modal-tour-desktop (onDismissal)="dismiss()" [slides]="data.slidesDesktop"></app-modal-tour-desktop>
  
	</ng-container>
  
	<ng-container *ngIf="device === devices.MOBILE">
  
	  <app-modal-tour-mobile (onDismissal)="dismiss()" [slides]="data.slidesMobile"></app-modal-tour-mobile>
  
	</ng-container>
  
  </div>