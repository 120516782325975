import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HeaderService } from "@api/services/header.service";

@Component({
    selector:'app-modal-autoexclusion',
    templateUrl:'./modalAutoexclusion.component.html',
    styleUrls:['./modalAutoexclusion.component.scss']
})
export class modalAutoexclusionComponent
{

    constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, texto: string, buttonConfirm: string}
    ){}
}