import { AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PlaysAPIService } from '@api/services/games/plays-api.service';
import { PlaysService } from '@api/services/play.service';
import { faHeart, faRedo, faShare, faSlidersH, faStar } from '@fortawesome/free-solid-svg-icons';
import { CustomVirtualScroll } from '@classes/CustomVritualScoll';
import { ApplicationStateService } from '@shared/services/application-state.service';
import { EDevice } from '@enums/common/sizes.enum';
import { CCoreComponent } from '@classes/core/core-component.class';
import { filter, map, pairwise, take, throttleTime, pipe, Subscription } from 'rxjs';
import { PlaysFilter } from '@models/playsFilter';
import { FiltrosService } from '@api/services/filtros.service';
import { Mygroup } from '@models/group.model';
import { HeaderService } from '@api/services/header.service';
import { GroupService } from '@api/services/group.service';
import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { FooterService } from '@api/services/footer.service';


@Component({
	selector: 'app-participations-products-list',
	templateUrl: './participations-products-list.component.html',
	styleUrls: ['./participations-products-list.component.scss'],
	providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScroll }]
})
export class ParticipationsProductsListComponent extends CCoreComponent implements OnDestroy, OnInit {

	//Variables
	public selectedValue: String;
	public filterPlay: String;
	public filterContainer: boolean;
	public plays: any[];
	public finalPlays: Array<any> = [];
	loading: boolean;
	//Icons
	public iconHeart = faHeart;
	public iconRepeat = faRedo;
	public iconShare = faShare;
	public iconFilter = faSlidersH;
	public iconStar = faStar;
	public selectedId = 0;
	public spinnerBottomLoadVisibility = false;
	public device: EDevice;
	public page = 1;
	public lastPage = false;
	
	public modeGroup: boolean = false;
	public grupo: Mygroup;

	private AbonosSubscription: Subscription

	@ViewChild("scroll") scroller: CdkVirtualScrollViewport;

	constructor(
		private playsService: PlaysAPIService,
		private ngZone: NgZone,
		private AuxPlaysService: PlaysService,
		applicationStateService: ApplicationStateService,
		private headerService: HeaderService,
		private groupService: GroupService,
		private footerService: FooterService
	) {

		super(applicationStateService);

		this.loading = true;
		this.plays = [];
		this.initializeSubscriptions();
	}

	/**
	 * Method to initialize subscriptions of the component.
	 */
	private initializeSubscriptions(): void {

		this.applicationStateService.applicationDeviceObservable.subscribe((device: EDevice) => {
			this.device = device;
		});

	}

	ngOnInit(): void {
		this.headerService.modeGrupoCoolOb.subscribe(res => { 
			this.modeGroup = res;
			if (this.modeGroup) {
				this.groupService.getGroup.pipe(take(1)).subscribe(res=>{
					this.grupo = res;
					this.getParticipaciones(this.page);
				});
				this.footerService.set_Show_footer(false);
			}
			else {
				this.getParticipaciones(this.page);
			}
		});
	}

	public dropAbonoList(abono: any) {
		this.finalPlays = this.finalPlays.filter(x => x.id != abono.id)
	}

	public getParticipaciones(page = 1): void {
		if (this.modeGroup) {
			this.playsService.getParticipationsApi(page, this.grupo.cliente_id);
		}
		else {
			this.playsService.getParticipationsApi(page);
		}

		this.playsService.participacionApi$.subscribe(res => {
			this.spinnerBottomLoadVisibility = false;
			if (res.data.data.length > 0) {
				this.plays = res.data.data;
				this.loadPlays();
				this.loading = false;
			}
			else {
				this.loading = false;
				this.page--;
				this.lastPage = true
			}
		});

	}

	/**
	 * 
	 * Load the plays to show on template
	 * @returns void
	 */
	private loadPlays(): void {
		if (this.finalPlays == undefined)
			this.finalPlays = [];
		this.finalPlays.push(...this.plays);
		console.log(this.finalPlays);
	}

	printDate(index: number) {

		if (index === 0) {
			return true;
		}

		if (this.finalPlays[index].fecha === this.finalPlays[index - 1].fecha) {
			return false;
		}

		return true;

	}
	
	ngOnDestroy(): void {
		if (this.AbonosSubscription)
			this.AbonosSubscription.unsubscribe();

		if (this.modeGroup) {
			this.footerService.set_Show_footer(true);
		}
	}

	public onScroll() {
		this.scroller.elementScrolled().pipe(
			map(() => this.scroller.measureScrollOffset('bottom')),
			pairwise(),
			filter(([y1, y2]) => (y2 < y1 && y2 > 100)),
			throttleTime(1500),
		).subscribe(() => {
			this.ngZone.run(() => {
				if (!this.lastPage) {
					this.page++;

					if (this.page > 1)
						this.spinnerBottomLoadVisibility = true;

					this.getParticipaciones(this.page);
				}
			})
		})
	}

}