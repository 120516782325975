import { Location } from '@angular/common';
import { Component, DoCheck, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from '@api/services/header.service';

@Component({
  selector: 'app-header-grupo-mode',
  templateUrl: './header-grupo-mode.html',
  styleUrls: [
    './header-grupo-mode.scss',
  ]
})
export class HeaderGrupoModeComponent implements OnInit, DoCheck
{
  public title: string;
  public titleAux: string;
  public showBtnRight;
  public iconRightButton: string;
  public ruteIconRight: string;
  public mode: string;
  public grupoWhiteMode: boolean;
  public grupoCoolMode: boolean;

  constructor(private router: Router,
              private headerService: HeaderService,
              private location: Location
  ) {
    this.title="";
    this.titleAux="";
    this.showBtnRight = false;
    this.iconRightButton = "";
    this.ruteIconRight = "";
    this.mode="Ajustes";
    this.grupoWhiteMode=false;
    this.grupoCoolMode=false;
  }

  ngOnInit()
  {
    this.ModeBtn();
    this.loadCoolModeGrupo();
    this.showRightButton();
    this.get_IconRigth();
    this.get_ruteIconRight();
    this.loadWhiteModeGrupo();
  }

  ngDoCheck()
  {
    this.loadTitle();
    this.title=this.titleAux;
  }

  loadWhiteModeGrupo()
  {
    this.headerService.modeGrupoWhiteOb.subscribe(x=>this.grupoWhiteMode=x);
  }

  loadCoolModeGrupo()
  {
    this.headerService.modeGrupoCoolOb.subscribe(x=>this.grupoCoolMode=x);
  }

  loadTitle()
  {
    this.headerService.getTitle.subscribe(x=>this.titleAux=x);
  }

  showRightButton()
  {
    this.headerService.showSeconButtonRightOb.subscribe(x=>this.showBtnRight=x);
  }

  get_IconRigth()
  {
    this.headerService.SeconButtonRightIconOb.subscribe(x=> this.iconRightButton=x);
  }

  get_ruteIconRight()
  {
    this.headerService.SeconButtonRightRouteOb.subscribe(x=>this.ruteIconRight=x);
  }

  checkRoute() {

    if(this.router.url.includes('/cliente/jugadas/') || this.router.url.includes('/resultados/')){
      return true;
    }
    else {
      return false;
    }
  }

  ModeBtn()
  {
    this.headerService.getBtnActionLeftMode.subscribe(x=>{
      this.mode = x;
    });
  }

  navigate()
  {
    this.router.navigate(['/cliente/menu']);
  }

  back()
  {
    this.location.back();
  }

  navRightIconClick()
  {
    this.router.navigate([this.ruteIconRight]);
  }
}
