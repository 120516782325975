import { NgModule } from "@angular/core";
import { GlobalModule } from "@shared/modules/global.module";
import {
	IPublicClientApplication,
	PublicClientApplication,
	BrowserCacheLocation,
	InteractionType,
} from '@azure/msal-browser';
import {
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalInterceptorConfiguration,
	MSAL_GUARD_CONFIG,
	MsalGuardConfiguration,
	MsalBroadcastService,
	MsalService,
	MsalGuard,
	MsalRedirectComponent,
	MsalModule,
	MsalInterceptor,
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from "@angular/common/http";

const GRAPH_ENDPOINT = 'Enter_the_Graph_Endpoint_Herev1.0/me';

const isIE =
	window.navigator.userAgent.indexOf('MSIE ') > -1 ||
	window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: 'e91ade2a-f645-4cfe-bd6c-e61b5a710b2c',
			authority: 'https://login.microsoftonline.com/c3b33ecd-9c1d-4e8e-99c1-aa308d7234c3',
			redirectUri: 'http://localhost:4200/cliente/loteriaempresas/auth/testazuread',
			navigateToLoginRequestUrl: false,
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: isIE, // set to true for IE 11
		},
		system: {

		},
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set(GRAPH_ENDPOINT, ['user.read']);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: ['user.read'],
		},
	};
}
@NgModule({
	imports: [
		GlobalModule,
		MsalModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true,
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService,
	],
	bootstrap: [MsalRedirectComponent],
})
export class AzureLoginModule { }
