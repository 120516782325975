import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";
import { MantenimientoService } from "./mantenimiento.service";
import { UserApiService } from "./user.service";
import { ModalTwoFactorComponent } from "@shared/components/modals/modalTwoFactor/modalTwoFactor.component";

@Injectable()
export class HttpInterceptCodes implements HttpInterceptor {

	private twoFactorOpen: boolean = false;

	constructor(
		private authService: AuthenticationService,
		private router: Router,
		private userService: UserApiService,
		private toastr: ToastrService,
		private mantenimiento: MantenimientoService,
		private dialog: MatDialog
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(tap(
			(response: any) => {
				if (response instanceof HttpErrorResponse) {
					if (response.status == 401) {
						this.authService.removeToken()
						this.userService.setSaldo(0)
						this.userService.setUsuario(null)
						if (this.toastr.previousToastMessage != "Usuario no encontrado, por favor vuelva a iniciar sesión.")
							this.toastr.error("Usuario no encontrado, por favor vuelva a iniciar sesión.")
						this.router.navigate(['/'])
					}

					if (response.status == 503) {
						this.mantenimiento.setMantenimiento(true);
						this.router.navigate(['/mantenimiento']);
					}
				}
				if (response.status == 269) {
					if (!this.twoFactorOpen) {
						this.twoFactorOpen = true;
						const twoFactorDialog = this.dialog.open(ModalTwoFactorComponent, {
							panelClass: 'modal-custom',
							data: {
								title: "Verificación de dos factores",
								mode: "verify",
								parameters: response.body || {}
							},
							disableClose: true
						});

						twoFactorDialog.afterClosed().subscribe(result => {
							this.router.navigate(['/']);
							this.twoFactorOpen = false;
						});
					}


				}
			}
		))
	}
}