<div class="recover-password-wrapper">

  <div class="header">

    <div class="logo">

      <app-auth-header [title]="'Acceder con email'"></app-auth-header>

    </div>

    

  </div>

  <div class="body">

    <div class="title">Recibiras las intrucciones en tu email para recuperar la contraseña.</div>

    <form [formGroup]="recoverPasswordForm" id="recover-password-form" (ngSubmit)="recoverPassword()" class="recover-password-form">

      <div class="form-input">
        <input matInput type="text" placeholder="Email" formControlName="email" required>
      </div>

      <div *ngIf="recoverPasswordMessage" [ngClass]="{
        'box-success': recoverPasswordMessage.type === 'success',
        'box-warning': recoverPasswordMessage.type === 'warning',
        'box-error': recoverPasswordMessage.type === 'error'
      }">
        <span>{{ recoverPasswordMessage.message }}</span>
      </div>

      <div class="actions">

        <button class="recover-password-submit" type="submit" mat-raised-button color="primary">Recuperar contraseña</button>

      </div>

    </form>

  </div>

</div>
