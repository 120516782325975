export class LoginUserModel {

  public email: string | undefined;
  public password: string | undefined;
  public playerId: number | undefined;

  constructor(
    email?: string,
    password?: string,
    playerId?: number
  ) {

    this.email = email;
    this.password = password;
    this.playerId = playerId;

  }

}
