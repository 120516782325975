import { Component, Inject, ViewChildren, QueryList, ElementRef, AfterViewInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserApiService } from "@api/services/user.service";
import { ToastrService } from "ngx-toastr";
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
	selector: 'app-modal-two-factor',
	templateUrl: './modalTwoFactor.component.html',
	styleUrls: ['./modalTwoFactor.component.scss']
})
export class ModalTwoFactorComponent implements AfterViewInit, OnDestroy {
	@ViewChildren('digit1, digit2, digit3, digit4, digit5') digitInputs: QueryList<ElementRef>;
	public pinForm: FormGroup;
	public disabled: boolean = false;
	public showError: boolean = false;
	public resendDisabled: boolean = false;
	public resendCountdown: number = 0;
	private countdownSubscription: Subscription;

	constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, mode: string, parameters: any },
		public userService: UserApiService,
		private dialogRef: MatDialogRef<ModalTwoFactorComponent>,
		private fb: FormBuilder,
		private toastrService: ToastrService) {

		this.pinForm = this.fb.group({
			digit1: new FormControl('', [Validators.required, Validators.pattern('^[0-9]$')]),
			digit2: new FormControl('', [Validators.required, Validators.pattern('^[0-9]$')]),
			digit3: new FormControl('', [Validators.required, Validators.pattern('^[0-9]$')]),
			digit4: new FormControl('', [Validators.required, Validators.pattern('^[0-9]$')]),
			digit5: new FormControl('', [Validators.required, Validators.pattern('^[0-9]$')]),
		});
	}

	ngAfterViewInit() {
		// Focus on the first input after view is initialized
		setTimeout(() => {
			this.digitInputs.first.nativeElement.focus();
		}, 0);
	}

	isFormValid(): boolean {
		return this.pinForm.valid;
	}

	onDigitInput(event: Event, index: number) {
		const input = event.target as HTMLInputElement;
		const value = input.value;

		// Only allow numbers
		if (value && !/^\d$/.test(value)) {
			input.value = '';
			return;
		}

		// Auto-focus to next input if value is entered
		if (value && index < 4) {
			const inputs = this.digitInputs.toArray();
			inputs[index + 1].nativeElement.focus();
		}

		this.showError = false;
	}

	onPaste(event: ClipboardEvent) {
		// Prevent default paste behavior
		event.preventDefault();

		// Get pasted content
		const clipboardData = event.clipboardData;
		const pastedText = clipboardData.getData('text');

		// Extract only digits from pasted text
		const digits = pastedText.replace(/\D/g, '').substring(0, 5);

		if (digits.length > 0) {
			// Fill the inputs with the digits
			const inputs = this.digitInputs.toArray();

			// Reset form before filling
			this.pinForm.reset();

			// Fill each input with the corresponding digit
			for (let i = 0; i < Math.min(digits.length, 5); i++) {
				const control = this.pinForm.get(`digit${i + 1}`);
				control.setValue(digits[i]);
				inputs[i].nativeElement.value = digits[i];
			}

			// Focus on the next empty input or the last one if all are filled
			if (digits.length < 5) {
				inputs[digits.length].nativeElement.focus();
			} else {
				// If all inputs are filled, focus on the last one
				inputs[4].nativeElement.focus();
			}

			this.showError = false;
		}
	}

	onKeyDown(event: KeyboardEvent, index: number) {
		const inputs = this.digitInputs.toArray();

		// Handle backspace - clear current and move to previous
		if (event.key === 'Backspace') {
			const input = event.target as HTMLInputElement;

			// If current input has value, clear it
			if (input.value) {
				input.value = '';
				return;
			}

			// If current input is empty and not the first one, move to previous
			if (index > 0) {
				inputs[index - 1].nativeElement.focus();
			}
		}

		// Handle left arrow - move to previous input
		if (event.key === 'ArrowLeft' && index > 0) {
			inputs[index - 1].nativeElement.focus();
		}

		// Handle right arrow - move to next input
		if (event.key === 'ArrowRight' && index < 4) {
			inputs[index + 1].nativeElement.focus();
		}
	}

	resendCode() {
		// Prevent resending if already disabled
		if (this.resendDisabled) {
			return;
		}

		// Disable the resend button and start countdown
		this.resendDisabled = true;
		this.resendCountdown = 60;

		// Start the countdown timer
		this.countdownSubscription = interval(1000).pipe(
			take(60)
		).subscribe(
			() => {
				this.resendCountdown--;
				if (this.resendCountdown === 0) {
					this.resendDisabled = false;
				}
			}
		);

		// Implement the logic to resend the verification code
		this.userService.resendTwoFactorCode().subscribe(
			(res: any) => {
				if (res.status == 200) {
					this.toastrService.info('Se ha enviado un nuevo código de verificación');
				}
				else {
					this.toastrService.error(res.u_message || "Error al enviar el código");
				}
			},
		);
	}

	stop() {
		this.dismiss();
	}

	dismiss() {
		this.dialogRef.close(false);
	}

	ngOnDestroy() {
		// Clean up subscription when component is destroyed
		if (this.countdownSubscription) {
			this.countdownSubscription.unsubscribe();
		}
	}

	close() {
		this.dialogRef.close();
	}

	submit() {
		if (this.pinForm.valid) {
			// Combine all digits to form the complete PIN
			const pin =
				this.pinForm.get('digit1').value +
				this.pinForm.get('digit2').value +
				this.pinForm.get('digit3').value +
				this.pinForm.get('digit4').value +
				this.pinForm.get('digit5').value;

			// Call the service to verify the code
			this.verifyTwoFactorCode(pin);
		} else {
			this.showError = true;
			this.toastrService.error("Todos los campos son requeridos");
		}
	}

	verifyTwoFactorCode(pin: string) {
		// This is a placeholder for the actual verification logic
		// You would typically call a service method here
		this.userService.verifyTwoFactorCode(pin).subscribe(
			(res: any) => {
				if (res.status == 200) {
					this.userService.getDataUser();
					this.dialogRef.close(true);
				}
				else {
					this.toastrService.error(res.u_message || "Código de verificación incorrecto");
				}
			},
			error => {
				this.toastrService.error("Error al verificar el código");
			}
		);
	}
}