
<!--
<div class="modal">
    <h4 mat-dialog-title>{{data.title}}</h4>
    <div class="curpo" mat-dialog-content [innerHTML]="data.texto"></div>
    <div class="footer">
        <button class="btn ml2 btn-primary" [ngClass]="{'btn-cool': modeGroup}" [mat-dialog-close]="true">{{data.buttonConfirm}}</button>
        <div class="noMostrar" *ngIf="data.showOptionCheck">
            <label class="custom-radio-checkbox">
                <input class="custom-radio-checkbox__input" type="checkbox" name="metodoPago" value="tarjeta"/>
                <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
                <span class="custom-radio-checkbox__text">No volver a mostrar</span>
            </label>
        </div>
    </div>
</div>
-->

<div class="share-modal">
    <div class="title-wrapper">
        <span class="title-text">{{data.title}}</span>
        <img class="close-button" src="/assets/images/icons/x-icon.svg" [mat-dialog-close]="true">
    </div>
    <span class="text-label">Comparte via:</span>
	<div class="socials">
		<div id="whatsapp" (click)="enviarWhatsapp()">
			<img src="/assets/images/icons/socials/whatsapp.svg" alt="WhatsApp">
		</div>
		<div id="telegram" (click)="enviarTelegram()">
			<img src="/assets/images/icons/socials/telegram.svg" alt="Telegram">
		</div>
		<div id="facebook" (click)="enviarFacebook()">
			<img src="/assets/images/icons/socials/facebook.svg" alt="Facebook">
		</div>
		<div id="email" (click)="enviarMail()">
			<img src="/assets/images/icons/socials/envelope-regular.svg" alt="Correo">
		</div>
	</div>
    <span class="text-label">Copiando este enlace: </span>
    <div class="link-wrapper">
        <span class="share-link">{{link}}</span>
		<div id="copy" (click)="copyClipboard()">
			<img src="/assets/images/icons/socials/copy-regular.svg" alt="Copiar al Portapapeles">
		</div>
    </div>
</div>