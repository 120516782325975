<div class="bottomSheetContainer" (click)="dimiss()">
    <div class="button">
        <div class="btn-ln">
            <div class="line">

            </div>
            <h4>Participantes</h4>
        </div>
    </div>
    <div class="container">
        <div class="card">
            <div class="body1">
                <div class="menssge-cabecera">
                    <p *ngIf="play.simulacion && play.simulacion.premio>0">¡Premiado!</p>
                    <p class="sinPremioColor" *ngIf="play.simulacion && play.simulacion.premio==0">Vaya...</p>
                    <p *ngIf="!play.simulacion">No sorteado</p>
                </div>  

                <div class="titulo">
                    <h4 *ngIf="play.simulacion && play.simulacion.premio>0">{{play.simulacion.premio | currency:'EUR'}}</h4>
                    <h4 class="sinPremioColor sinPremioColor-dark" *ngIf="play.simulacion && play.simulacion.premio==0">Sin premio</h4>
                    <h4 *ngIf="!play.simulacion">0.00 €</h4>
                </div>

                <div class="message">
                    <p *ngIf="play.simulacion && play.simulacion.premio>0">{{totalPorIntgrante | currency:'EUR'}} cada uno</p>
                    <p *ngIf="!play.simulacion">Ya queda menos</p>
                    <p *ngIf="play.simulacion && play.simulacion.premio==0">Vuelva a intentarlo</p>
                </div>
            </div>
            
            <div class="body2">
                <img *ngIf="play.simulacion && play.simulacion.premio>0" src="/assets/images/bootomSheet/premiado.svg" alt="img" />
                <img *ngIf="!play.simulacion" src="/assets/images/bootomSheet/espera.svg" alt="img" />
            </div>
        </div>

        <ul class="lista">
            <ng-scrollbar>
                <li class="list-item" *ngFor="let participante of participantes">
                    <div class="icon">
                        <div class="letter">
                            {{getFirstLetter(participante.nombre)}}
                        </div>
                    </div>
                    <div class="content">
                        <p>{{participante.nombre+" "+participante.apellido_1}}</p>
                    </div>
                    <div class="currecy">
                        <p>{{totalPorIntgrante | currency:'EUR'}}</p>
                    </div>
                </li>
            </ng-scrollbar>
        </ul>
    </div>
</div>