import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BetService } from '@modules/games/bet.service';
import * as moment from 'moment';

/**
 * Component that represents a Date
 */
@Component({
    selector: 'app-day',
    templateUrl: './day.component.html',
    styleUrls: [
        './day.component.scss',
    ]
})
export class DayComponent implements  OnInit, AfterViewInit, OnChanges{

    public selected = false;

    @Input() date: {date: string, dayName: string, day: number, month: string, gameid: number, selected: boolean };
    @Output() selectedEvent = new EventEmitter<string>();
    @ViewChild("day") day: ElementRef;
    @Output() scrollEvent: EventEmitter<number> = new EventEmitter<number>();
    @Input() freePlay: Boolean = false

    constructor(private routerActive: ActivatedRoute, private betService: BetService) {

        this.date = {date : '', dayName: '', day: 0, month: '', gameid: 0, selected: false};

    }

    ngOnInit(): void {

        const today = moment();
        const date = moment(this.date.date, 'YYYY-MM-DD');
        let dateInitial = this.routerActive.snapshot.queryParams.date? moment(this.routerActive.snapshot.queryParams.date,'YYYY-MM-DD'): null;

        if(!dateInitial)
        {
            if (date.isSame(today.startOf('week').format('YYYY-MM-DD')) || date.isSame(today.endOf('week').format('YYYY-MM-DD'))){

                this.switchSelection();
    
            }
            if (date.isBetween(today.startOf('week').format('YYYY-MM-DD') , today.endOf('week').format('YYYY-MM-DD'))){
    
                this.switchSelection();
    
            }
        }
        else
        {
            if(date.isSame(dateInitial))
                this.switchSelection();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.freePlayApplyRules()
    }

    freePlayApplyRules()
    {
        if(this.freePlay)
        {
            const today = moment();
            const date = moment(this.date.date, 'YYYY-MM-DD');
            let dateInitial = this.routerActive.snapshot.queryParams.date? moment(this.routerActive.snapshot.queryParams.date,'YYYY-MM-DD'): null;

            this.selected = false

            if(dateInitial)
            {
                if(date.isSame(dateInitial))
                    this.switchSelection()
            }
            else
            {
                if(this.date.selected)
                    this.switchSelection()
            }

            this.betService.getDate().subscribe((res: String)=>{
                console.log(res)
                if(this.date.date == res)
                    this.selected = true
                else
                    this.selected = false
            })

            this.selected = this.date.selected
        }
    }

    ngAfterViewInit()
    {
        const date = moment(this.date.date, 'YYYY-MM-DD');

        let dateInitial = this.routerActive.snapshot.queryParams.date? moment(this.routerActive.snapshot.queryParams.date, 'YYYY-MM-DD'): null;

        if(date.isSame(dateInitial))
        {
            if(this.day.nativeElement.offsetLeft > screen.width)
                this.scrollEvent.emit(Number(this.day.nativeElement.offsetLeft) - (screen.width/2));
        }
    }

    /**
     * Select the currect date
     */
    public switchSelection(): void {

        if(!this.freePlay)
            this.selected = !this.selected;
            
        this.selectedEvent.emit(this.date.date)

    }

}
