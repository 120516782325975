import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HeaderService } from "@api/services/header.service";
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastrService } from "ngx-toastr";

@Component({
    selector:'app-modal-share',
    templateUrl:'./modalShare.component.html',
    styleUrls:['./modalShare.component.scss'],
})
export class modalShareComponent
{
    public modeGroup: boolean = false;
    public link: string = '';
    public descripcion = '';
    constructor(@Inject(MAT_DIALOG_DATA) public data: {
        title: string,
        link: string
        descripcion: string},
                headerService: HeaderService,
				private clipboard: Clipboard,
				private toastrService: ToastrService
    ){
        this.link = data.link;
        this.descripcion = data.descripcion;
        headerService.modeGrupoCoolOb.subscribe(res=>this.modeGroup=res);
    }

	enviarWhatsapp() {
        let url = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(this.link);
        window.open(url,'_blank');
	}

	enviarTelegram() {
		let url = 'https://t.me/share/url?url=' + encodeURIComponent(this.link);
        window.open(url,'_blank');
	}

	enviarFacebook() {
		let url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.link);
        window.open(url,'_blank');
	}

	enviarMail() {
		let url = 'mailto:?body=' + this.link;
        window.open(url);
	}

	copyClipboard() {
		this.clipboard.copy(this.link);
		this.toastrService.success('Link copiado al portapapeles')
	}
}