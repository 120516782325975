<div class="automatic-bet-wrapper">
	<div class="automatic-bet-container card">
		<div class="bet-text">
			<span>Numero de apuestas </span>
		</div>
		<div class="bet-elements">
			<div class="decrement-bet" (click)="decrementBet()" *ngIf="!desactivaAutomatico">
				<img src="/assets/images/icons/minos.svg" alt="img" />
			</div>

			<div class="decrement-bet disabled-automatic" *ngIf="desactivaAutomatico">
				<img src="/assets/images/icons/minos-disabled.svg" alt="img" />
			</div>
			<div class="bet">
				<span>{{numberBets}}</span>
			</div>
			<div class="increment-bet" (click)="incrementBet()" *ngIf="!desactivaAutomatico">
				<img src="/assets/images/icons/Plus.svg" alt="img">
			</div>

			<div class="increment-bet disabled-automatic" *ngIf="desactivaAutomatico">
				<img src="/assets/images/icons/Plus-disabled.svg">
			</div>
		</div>

	</div>
	<!-- Mostrar las apuestas
        <div class="bet-list">
            <div class="bet-element-list" *ngFor="let bet of bets; let idx = index">
                <div class="label-container">
                    <span class="label-bet">A{{bet.label}}</span>
                </div>
                <div class="numbers-container">
                    <div class="number-bet" *ngFor="let number of bet.numbersChecked">{{number}}</div>
                </div>
                <div class="extra-container">
                  <div class="extra-bet" *ngFor="let extra of bet.extraChecked" [ngStyle]="styleBet()">{{extra}}</div>
                </div>
                <div class="options-container">
                    <div class="options-bet" (click)="clearAutoBet(idx)">
                        <fa-icon [icon]="iconCross" class="icon-delete"></fa-icon>
                    </div>
                </div>
            </div>
            <div class="reintegro-container" *ngIf="reintegro >= 0">
                <div class="reintegro-title">R:</div>
                <div class="reintegro">{{reintegro}}</div>
            </div>
        </div>
		-->
	<!-- Jugada gratis  -->

	<div class="free-bet-mobile-wrapper"
		*ngIf="device === 'mobile' && freeBet && (options.idGame != 6 && options.idGame != 7 && options.idGame != 8)">
		<span class="share-text">Compartir</span>
		<div class="contacts-wrapper">
			<div class="contact me">{{contactName}}</div>
			<div class="contact default" (click)="googleContactsMobile()" *ngIf="!contact">
				<img class="no-contact" src="../../../../../../assets/images/icons/games/add_user.svg">
			</div>
			<div class="contact other" style="background-color:{{colorGenerator.strToColor(contact.name)}}"
				*ngIf="contact" (click)="googleContactsMobile()">{{contact.initials}}</div>
		</div>
	</div>

	<div class="free-bet-wrapper"
		*ngIf="device === 'desktop' && freeBet && (options.idGame != 6 && options.idGame != 7 && options.idGame != 8)">
		<div class="contacts-wrapper">
			<span class="contact-text" *ngIf="states.length === 0">No tienes contactos en la agenda, carta tus contactos
				para poder compartir el
				boleto</span>
			<span class="contact-text" *ngIf="states.length !== 0">Busca los contactos de tu agenda y comparte este
				boleto</span>
			<mat-form-field *ngIf="states.length !== 0">
				<mat-label>Buscar contacto...</mat-label>
				<input matInput aria-label="Contacto" [matAutocomplete]="auto" [formControl]="stateCtrl"
					optionSelected="test()" />
				<mat-autocomplete #auto="matAutocomplete">
					<mat-option *ngFor="let contact of filteredStates | async" [value]="contact.name">
						<div class="wrapper" (click)="selectContact(contact)">
							<div class="initial-wrapper"
								style="background-color:{{colorGenerator.strToColor(contact.name)}}">
								<span>{{contact.initials}}</span>
							</div>
							<div class="names">
								<span class="text">{{contact.name}}</span>
								<span class="text">{{contact.number}}</span>
							</div>
							<!--<img class="example-option-img to-right" aria-hidden src="../../../../../../assets/images/icons/games/add_user.svg" height="25" />-->
						</div>
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<span class="contact-text" *ngIf="states.length !== 0">O bien, carga o sincroniza tu agenda si lo
				necesitas.</span>
			<div class="import-buttons">
				<button mat-raised-button class="social-button" (click)="googleContacts()">
					<img src="/assets/images/pages/options-auth/logo-google.svg" />
					Google
				</button>
				<div>
					<fa-icon class="social-icon" [icon]="iconApple" size="lg"
						onclick="location.href='https://apps.apple.com/us/app/miloto-loterías-y-apuestas/id1128706215?l=es&ls=1';"></fa-icon>
					<fa-icon class="social-icon" [icon]="iconAndroid" size="lg"
						onclick="location.href='https://play.google.com/store/apps/details?id=com.miloto';"></fa-icon>
				</div>
				<img src="/assets/images/icons/games/info_i.svg" class="info-icon" #tooltip="matTooltip"
					matTooltip="Puedes cargar la agenda de la cuenta de google o descargar la aplicación de Miloto y compartir el boleto desde la aplicacion usando la agenda del movil."
					matTooltipClass="tooltip-free-bet-color" (click)="tooltip.show()">
			</div>
		</div>
		<div class="options-wrapper">
			<span class="slide-toogle">
				<mat-checkbox [checked]=true disabled class="options-text">Repartir Premio</mat-checkbox>
				<img src="/assets/images/icons/games/info_i.svg" class="to-right" #tooltip="matTooltip"
					matTooltip="El premio se reparte automáticamente entre los amigos que hayas seleccionado."
					matTooltipClass="tooltip-free-bet-color" (click)="tooltip.show()">
			</span>

			<span class="slide-toogle">
				<mat-checkbox [checked]=true disabled class="options-text">Visible entre compartidos</mat-checkbox>
				<img src="/assets/images/icons/games/info_i.svg" class="to-right" #tooltip="matTooltip"
					matTooltip="Todos los usuarios compartidos del boleto podrán ver con quien se ha compartido el boleto."
					matTooltipClass="tooltip-free-bet-color" (click)="tooltip.show()">
			</span>
			<mat-form-field class="options-text">
				<mat-label>Enviar un mensaje</mat-label>
				<textarea matInput></textarea>
			</mat-form-field>
		</div>
	</div>
</div>