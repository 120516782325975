import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CCoreComponent } from '@classes/core/core-component.class';
import { BetService } from '@modules/games/bet.service';
import { ApplicationStateService } from '@shared/services/application-state.service';
import * as moment from 'moment';

/**
 * Component that represents a Date
 */
@Component({
    selector: 'app-day-lottery',
    templateUrl: './day-lottery.component.html',
    styleUrls: [
        './day-lottery.component.scss',
    ]
})
export class DayLotteryComponent extends CCoreComponent implements  OnInit, AfterViewInit{

    public selected = false;

    @Input() date: {date: string, dayName: string, day: number, month: string, selected: boolean, precio: number | string}
    @Output() selectedEvent = new EventEmitter<any>();
    @ViewChild('day') day: ElementRef;
    @Output() scrollEvent: EventEmitter<number> = new EventEmitter();

    constructor(private activeRouter: ActivatedRoute,
                applicationService: ApplicationStateService,
                private betService: BetService
        ) {

        super(applicationService);

        this.date = {date : '', dayName: '', day: 0, month: '', selected: false, precio: 0};

    }

    ngOnInit(): void {

        const today = moment();
        const date = moment(this.date.date, 'YYYY-MM-DD');

        let dateInitial = this.activeRouter.snapshot.queryParams.date? moment(this.activeRouter.snapshot.queryParams.date, 'YYYY-MM-DD'): null;

        if(this.date.selected && !dateInitial){
            this.date.selected = !this.date.selected;
            this.switchSelection();
        }

        if(date.isSame(dateInitial))
        {
            this.date.selected = !this.date.selected;
            this.switchSelection();
        }

        this.betService.getDate().subscribe((res: String)=>{
            if(this.date.date == res)
                this.selected = true
            else
                this.selected = false
        })
    }

    ngAfterViewInit()
    {
        const date = moment(this.date.date, 'YYYY-MM-DD');

        let dateInitial = this.activeRouter.snapshot.queryParams.date? moment(this.activeRouter.snapshot.queryParams.date, 'YYYY-MM-DD'): null;

        if(date.isSame(dateInitial))
        {
            if(this.day.nativeElement.offsetLeft > screen.width)
                this.scrollEvent.emit(Number(this.day.nativeElement.offsetLeft) - (screen.width/2));
        }
    }

    /**
     * Select the currect date
     */
    public switchSelection(): void {
        this.date.selected = !this.date.selected;
        this.selected = !this.selected;
        this.selectedEvent.emit(this.date);
    }

}
