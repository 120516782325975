import { MPlay } from "./plays-api.models";

export class CancerPlay {

    constructor(
		public cliente_id: number,
		public tipo: string,
        public fecha: Date | String,
		public importe: number,
		public nombre: string,
		public imagen: string,
		public jugada?: MPlay,
		public participacion?: any,
    ){}
}
