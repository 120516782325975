<div class="played-wrapper" *ngIf="automatic || (!automatic && !manual)">
    <div class="item" *ngFor="let item of bets.slice(0, 2); let i = index">
        <div class="number" *ngFor="let number of item.numbersChecked">{{number}}</div>
        <div class="number extra" *ngFor="let number of item.extraChecked">{{number}}</div>
		<div *ngIf="i != 0 && invisibleSpace > 0" class="number invisible"></div>
        <button class="number option" (click)="randomBet(item, i)"><img src="/assets/images/icons/games/button/dice.svg"></button>
        <button class="number option" (click)="deleteBet(item, i)"><img src="/assets/images/icons/games/button/trash.svg"></button>
    </div>
    <div *ngIf="showAll">
        <div class="item" *ngFor="let item of bets.slice(2); let i = index">
            <div class="number" *ngFor="let number of item.numbersChecked">{{number}}</div>
            <div class="number extra" *ngFor="let number of item.extraChecked">{{number}}</div>
			<div *ngIf="invisibleSpace" class="number invisible"></div>
            <button class="number option" (click)="randomBet(item, i + 2)"><img src="/assets/images/icons/games/button/dice.svg"></button>
            <button class="number option" (click)="deleteBet(item, i + 2)"><img src="/assets/images/icons/games/button/trash.svg"></button>
        </div>
    </div>
    <button class="visibility-button" (click)="showAll = !showAll" *ngIf="bets.length > 2">{{ showAll ? 'Ver menos' : 'Ver más' }}</button>
</div>