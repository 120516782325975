<app-spinner *ngIf="loadingLogin" forceTo='full-sized'></app-spinner>
<div class="login-wrapper">
  
  <div class="header">
    <app-auth-header [title]="'Acceder con email'"></app-auth-header>
  </div>

  <div class="body">

    <form id="login-form" [formGroup]="loginForm" (ngSubmit)="login()" class="login-form">

      <div class="form-input">
        <input matInput type="text" placeholder="Email" formControlName="email" required>
      </div>

      <div class="form-input formPassword">
        <input #inputPassword matInput type="password" placeholder="Password" formControlName="password" required>
        <img (click)="changePasswordVisible()" src="/assets/images/icons/loginYregistro/open_eye.svg" alt="img" />
      </div>

      <div *ngIf="loginMessage" [ngClass]="{
        'box-success': loginMessage.type === 'success',
        'box-warning': loginMessage.type === 'warning',
        'box-error': loginMessage.type === 'error'
      }">
        <span>{{ loginMessage.message }}</span>
      </div>

      <div class="actions">

        <div class="login-actions">

          <button mat-raised-button color="primary" class="btn-block btn-lg m-t-20 m-b-20 login-jwt" type="submit"
            [disabled]="!loginForm.valid">

            Acceder a Miloto

          </button>
          
          <ng-container *ngIf="device == devices.DESKTOP">

            <div class="buttons">
				<!--
              <button class="btn-acceder btn-acceder-icon-left" (click)="loginFacebook()">
                <img src="/assets/images/pages/options-auth/logo-facebook.svg"/>
                <p>Acceder con Facebook</p>
              </button>

              <button class="btn-acceder btn-acceder-icon-left" (click)="loginGoogle()">
                <img src="/assets/images/pages/options-auth/logo-google.svg"/>
                <p> Acceder con Google </p>
              </button>
				-->
				
              <button class="btn-acceder btn-acceder-icon-left" (click)="loginApple($event)">
                <img src="/assets/images/icons/appel_icon.png"/>
                <p> Acceder con Apple </p>
              </button>
            </div>
  
            <div class="notice">
              <a class="text-info">¿Aun no tienes cuenta? <strong [routerLink]="['/','acceso','registro']"><u>Registrate</u></strong></a>
            </div>
            
          </ng-container>

          <div class="notice">
            <a class="text-info">¿Has Olvidado tu contraseña? <strong [routerLink]="['../recuperar-contrasena']"><u>Recuperar</u></strong></a>
          </div>

        </div>

      </div>

    </form>

  </div>

</div>


<link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Open+Sans" />
