export class RegisterUserModel {

  name: string | undefined;
  email: string | undefined;
  password: string | undefined;
  repeatPassword: string | undefined;
  birthDate: Date | undefined;
  playerId: number | undefined;

  constructor(name?: string, email?: string, password?: string, repeatPassword?: string, birthDate?: Date, playerId?: number) {

    this.name = name;
    this.email = email;
    this.password = password;
    this.repeatPassword = repeatPassword;
    this.birthDate = birthDate;
    this.playerId = playerId;

  }

}