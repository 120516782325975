import { Component } from "@angular/core";

@Component({
    selector:'app-direccion-compra-desktop',
    templateUrl:'./direccion-compra-desktop.component.html',
    styleUrls:['./direccion-compra-desktop.component.scss']
})
export class direccionCompraDesktopComponent
{
    constructor(){}
}