<div class="bottomSheetContainer" >
    <div class="button" (click)="dimiss()">
        <div class="btn-ln">
            <div class="line">

            </div>
        </div>
    </div>
    <div class="container">

        <div class="gift-wrapper">
            <span class="title-gift">Tu amigo {{apiResponse.data.original.nombre}} te regala esta jugada</span>
    
            <div class="separator"></div>
    
            <span *ngIf="this.isLogged; then logged else notLogged"></span>
    
            <ng-template #notLogged>
                <mat-tab-group dynamicHeight>
                    <mat-tab label="Ya tengo cuenta">
                        <form [formGroup]="loginForm" (ngSubmit)="loginAcceptGiftPlay(this.loginForm)" class="form-gift">
        
                            <mat-form-field class="email-gift-input" appearance="outline">
                                <mat-label>Email</mat-label>
                                <input id="emailLogin" name="emailLogin" formControlName="emailLogin" type="email" matInput [errorStateMatcher]="matcher"
                                    placeholder="Ex. pat@example.com">
                                <mat-error *ngIf="emailLogin.invalid && (emailLogin.dirty || emailLogin.touched)">
                                  ¡Introduce un email valido!
                                </mat-error>
                                <mat-error *ngIf="emailLogin.errors?.required">
                                  Es necesario introducir un email
                                </mat-error>
                            </mat-form-field>
                            
                            <mat-form-field class="password-gift-input" appearance="outline">
                                <mat-label>Contraseña</mat-label>
                                <input matInput [type]="hide ? 'password' : 'text'" id="passwordLogin" name="passwordLogin" formControlName="passwordLogin" [errorStateMatcher]="matcher">
                                <button matSuffix mat-icon-button type="button" (click)="hidePass()">
									<img *ngIf="!hide" src="/assets/images/icons/loginYregistro/eye-off.svg" alt="Ocultar contraseña">
									<img *ngIf="hide" src="/assets/images/icons/loginYregistro/eye-show.svg" alt="Mostrar contraseña">
								</button>
                            </mat-form-field>
    
                            <mat-checkbox matInput class="checkbox-aceptar"
                                [errorStateMatcher]="matcher"
                                id="aceptarLogin" name="aceptarLogin"
                                type="checkbox"
                                [color]="primary"
                                formControlName="aceptarLogin">
                                Acepto el regalo
                            </mat-checkbox>
    
                            <button mat-button class="regalar-button" [disabled]="!loginForm.valid" type="submit" >Aceptar y acceder</button>
                    
                        </form>
                    </mat-tab>
                    <mat-tab label="Registrarme">
                        <form [formGroup]="registerForm" (ngSubmit)="registerAcceptGiftPlay(this.registerForm)" class="form-gift">
        
                            <mat-form-field class="name-gift-input" appearance="outline">
                                <mat-label>Nombre</mat-label>
                                <input id="nameRegister" name="nameRegister" formControlName="nameRegister" type="text" matInput [errorStateMatcher]="matcher">
                                <mat-error *ngIf="nameRegister.errors?.required">
                                  Es necesario introducir un nombre
                                </mat-error>
                            </mat-form-field>
    
                            <mat-form-field class="email-gift-input" appearance="outline" disabled>
                                <mat-label>Email</mat-label>
                                <input id="emailRegister" name="emailRegister" formControlName="emailRegister" type="email" matInput [errorStateMatcher]="matcher"
                                    placeholder="Ex. pat@example.com">
                                <mat-error *ngIf="emailRegister.invalid && (emailRegister.dirty || emailRegister.touched)">
                                  ¡Introduce un email valido!
                                </mat-error>
                                <mat-error *ngIf="emailRegister.errors?.required">
                                  Es necesario introducir un email
                                </mat-error>
                            </mat-form-field>
                            
                            <mat-form-field class="password-gift-input" appearance="outline">
                                <mat-label>Contraseña</mat-label>
                                <input matInput [type]="hide ? 'password' : 'text'" id="passwordRegister" name="passwordRegister" formControlName="passwordRegister" [errorStateMatcher]="matcher" (input)="nofirst()">
								<button matSuffix mat-icon-button type="button" (click)="hidePass()">
									<img *ngIf="!hide" src="/assets/images/icons/loginYregistro/eye-off.svg" alt="Ocultar contraseña">
									<img *ngIf="hide" src="/assets/images/icons/loginYregistro/eye-show.svg" alt="Mostrar contraseña">
								</button>
                                <mat-error *ngIf="passwordRegisterInvalid">La contraseña debe ser de al menos 8 caracteres y incluir al menos una
									mayúscula, minúscula, número y caracter especial.</mat-error>
								<mat-hint *ngIf="first">La contraseña debe ser de al menos 8 caracteres y incluir al menos una mayúscula,
									minúscula, número y caracter especial.</mat-hint>
                            </mat-form-field>
    
                            <mat-checkbox matInput class="checkbox-aceptar"
                                [errorStateMatcher]="matcher"
                                id="aceptarRegister" name="aceptarRegister"
                                type="checkbox"
                                [color]="primary"
                                formControlName="aceptarRegister">
                                Acepto el regalo
                            </mat-checkbox>
    
                            <button mat-button class="regalar-button" [disabled]="!registerForm.valid" type="submit" >Aceptar y acceder</button>
                    
                        </form>
                    </mat-tab>
                </mat-tab-group>
    
                <div class="buttons">
					<!--
                    <button class="btn-acceder btn-acceder-icon-left" (click)="facebookAcceptGiftPlay()">
                      <img src="/assets/images/pages/options-auth/logo-facebook.svg"/>
                      <p>Acceder con Facebook</p>
                    </button>
                    <button class="btn-acceder btn-acceder-icon-left" (click)="googleAcceptGiftPlay()">
                      <img src="/assets/images/pages/options-auth/logo-google.svg"/>
                      <p> Acceder con Google </p>
                    </button>
					-->
                    <button class="btn-acceder btn-acceder-icon-left" (click)="loginApple()">
                        <img src="/assets/images/icons/appel_icon.png"/>
                        <p> Acceder con Apple </p>
                      </button>
                  </div>
    
            </ng-template>
            <ng-template #logged>
                <form [formGroup]="loggedForm" (ngSubmit)="logged_acceptGiftedPlay()" class="logged-form">
    
                    <mat-checkbox matInput class="checkbox-aceptar"
                        [errorStateMatcher]="matcher"
                        id="aceptarLogged" name="aceptarLogged"
                        type="checkbox"
                        [color]="primary"
                        formControlName="aceptarLogged">
                        Acepto el regalo
                    </mat-checkbox>
    
                    <button mat-button class="regalar-button" [disabled]="!loggedForm.valid" type="submit" >Aceptar y acceder</button>
            
                </form>
            </ng-template>
    
        </div>

    </div>

    
</div>