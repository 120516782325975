import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@api/services/authentication.service';
import { HeaderService } from '@api/services/header.service';
import { RegisterService } from '@api/services/register.service';
import { UserApiService } from '@api/services/user.service';
import { ValidatorsService } from '@api/services/validators.service';
import { CCoreComponent } from '@classes/core/core-component.class';
import { EAuthenticationAvailableOptions } from '@enums/common/authentication-options.enum';
import { logEvent } from '@firebase/analytics';
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { RegisterUserModel } from '@models/register-user.model';
import { ApplicationStateService } from '@shared/services/application-state.service';
import { SocialUser } from "@abacritt/angularx-social-login";;
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import { Output, EventEmitter } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MatFormFieldModule } from '@angular/material/form-field';
import { modalLegalComponent } from '@shared/components/modals/modalLegal/modalLegal.component';
import { MatDialog } from '@angular/material/dialog';

interface RegisterMessage {
	type: 'error' | 'warning' | 'success';
	message: string;
}

export interface OwnerForCreation {
	name: string;
	dateOfBirth: Date;
	address: string;
}

@Component({
	selector: 'app-register-desktop',
	templateUrl: './register-desktop.component.html',
	styleUrls: [
		'./register-desktop.component.scss',
	]
})
export class RegisterDesktopComponent extends CCoreComponent implements OnInit {

	iconFacebook = faFacebookF;
	iconGoogle = faGoogle;
	recaptchaMode = 'invisible'
	registerMessage: RegisterMessage | null;
	registerForm: FormGroup;

	user: RegisterUserModel;
	loading: boolean;
	socialUser: SocialUser;

	registerLoading = false;

	age18 = new Date(Date.now());
	maxDate = new Date(Date.now());

	@Output() closeEvent = new EventEmitter<boolean>();
	@Output() changeActionEvent = new EventEmitter<string>();

	get passwordInput() { return this.registerForm.get('password'); }
	public hide = true;
	public visible: boolean = false;
	public first: boolean = true;

	/**
	 * Register Component constructor.
	 * @param auth AuthenticationService.
	 * @param registerService RegisterService.
	 * @param validatorsService ValidatorsService.
	 * @param fb FormBuilder.
	 * @param router Router.
	 */
	constructor(
		private auth: AuthenticationService,
		private registerService: RegisterService,
		private validatorsService: ValidatorsService,
		private fb: FormBuilder,
		private router: Router,
		private renderer: Renderer2,
		private userService: UserApiService,
		private recaptchaV3Service: ReCaptchaV3Service,
		applicationStateService: ApplicationStateService,
		headerService: HeaderService,
		private dialog: MatDialog
	) {

		super(applicationStateService)

		if (this.device == this.devices.MOBILE)
			headerService.set_rutaBack("acceso/options/registrate");
		else
			headerService.set_rutaBack("/");

		this.loading = false;
		this.user = new RegisterUserModel();
		this.socialUser = new SocialUser();
		this.getMinAge();

		this.registerForm = this.fb.group({
			name: new FormControl(this.user.name, [Validators.required, Validators.minLength(3)]),
			email: new FormControl(this.user.email, [Validators.required, Validators.email]),
			password: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_\\-#=+%$?&@!\\.*:\/]).{8,}$')])],
			birthDate: new FormControl(this.maxDate, [Validators.required, this.validatorsService.checkLegalAge])
		});

		this.registerMessage = null;

	}

	/**
	 * Initializes the component.
	 */
	public ngOnInit(): void {

		if (this.auth.isAuthenticated()) {

			this.router.navigateByUrl('');

		}

	}

	nofirst() {
		this.first = false;
	}

	hidePass() {
		this.hide = !this.hide;
	}

	closeModal() {
		this.closeEvent.emit(true);
	}

	changeAction() {
		this.changeActionEvent.emit('login');
	}


	public getMinAge() {

		this.age18 = this.maxDate;

		// setting the calendar's start date and youngest birth dates for > 18 years old
		this.maxDate.setDate(this.maxDate.getDate());
		this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
		this.age18 = this.maxDate;

	}

	/**
	 * Validator error value.
	 * @returns Validator error.
	 */
	get nameInvalid(): boolean | undefined {
		return this.registerForm.get('name')?.invalid && this.registerForm.get('name')?.touched;
	}

	/**
	 * Validator error value.
	 * @returns Validator error.
	 */
	get emailInvalid(): boolean | undefined {
		return this.registerForm.get('email')?.invalid && this.registerForm.get('email')?.touched;
	}

	/**
	 * Validator error value.
	 * @returns Validator error.
	 */
	get passwordInvalid(): boolean | undefined {
		return this.registerForm.get('password')?.invalid && this.registerForm.get('password')?.touched;
	}

	/**
	 * Validator error value.
	 * @returns Validator error.
	 */
	get birthDateInvalid(): boolean | undefined {
		return this.registerForm.get('birthDate')?.invalid && this.registerForm.get('birthDate')?.touched;
	}


	/**
	 * Register a user with the data of form
	 */
	public register(): void {

		if (this.registerForm.valid) {

			let dialogAlert = this.dialog.open(modalLegalComponent, {
				panelClass: 'modal-custom',
				disableClose: true
			});
	
			dialogAlert.afterClosed().subscribe(res => {
				if (res == true) {
					this.userService.setlegal();
					this.registerMessage = null;
					this.loading = true;
		
					this.user.email = this.registerForm.controls.email.value;
					this.user.name = this.registerForm.controls.name.value;
					this.user.password = this.registerForm.controls.password.value;
					this.user.birthDate = this.registerForm.controls.birthDate.value;
					this.user.playerId = 123456;
		
					this.recaptchaV3Service.execute('importantAction')
						.subscribe((token) => {
							this.registerService.registerUser(this.user).subscribe((authResponse: any) => {
		
								this.loading = false;
								this.registerMessage = { type: 'success', message: 'Registro completado con éxito' };
								this.auth.saveToken(authResponse.token, EAuthenticationAvailableOptions.JWT_LOGIN);
		
								this.userService.getDataUser();
								
								logEvent(this.analytics, "sign_up");
								if (localStorage.getItem('af'))
									localStorage.removeItem('af');
		
								if (!localStorage.getItem("last-url"))
									this.router.navigateByUrl('');
								else {
									var url = localStorage.getItem("last-url")
		
									if (localStorage.getItem("empresa"))
										url = '/cliente/ingresar'
									else
										localStorage.removeItem("last-url")
		
									this.router.navigate([url])
		
		
								}
								this.closeModal();
							}, (error) => {		
								this.loading = false;
								var mensaje = 'Error al realizar el registro.';
								if(error.status == 496){
									mensaje = 'El email ya está registrado.';
								}
								this.registerMessage = { type: 'error', message: mensaje };

							});
						},
							(error) => {
								this.loading = false;
								this.registerMessage = { type: 'error', message: 'Captcha no pasado.' };
							}
						);
				}
			})
		} else {

			this.registerMessage = { type: 'error', message: 'Datos incorrectos.' };

		}

	}

	errorCaptcha(): void { console.log('a') }
	resolvedCaptcha(message: string, token: string | null): void { console.log(token) }

	loginApple(event): void {
		event.preventDefault();
		const provider = new OAuthProvider("apple.com") // com.miloto.MilotoWebService apple.com

		const auth = getAuth()
		signInWithPopup(auth, provider)
			.then((res) => {

				//user data
				const user = res.user;

				// Apple credential
				const credential = OAuthProvider.credentialFromResult(res);
				//const accessToken = credential.accessToken;
				const idToken = credential.idToken;

				this.auth.sendLoginAppleAPI(idToken, user.uid).subscribe((res: any) => {
					this.auth.saveToken(res.token, EAuthenticationAvailableOptions.JWT_LOGIN);

					this.userService.getDataUser();

					if (!localStorage.getItem("last-url"))
						this.router.navigateByUrl('');
					else {
						var url = localStorage.getItem("last-url")

						if (localStorage.getItem("empresa"))
							url = '/cliente/ingresar'
						else
							localStorage.removeItem("last-url")

						this.router.navigate([url])

					}
					this.closeModal();
				}, err => {
					this.registerMessage = { type: 'error', message: 'Error al iniciar sesión con Apple: ' + err.message };
				})

			})
			.catch((err) => {
				this.registerMessage = { type: 'error', message: 'Error al iniciar sesión con Apple: ' + err.message };
			})
	}

	/**
	 * Login on the App with Google services.
	 */
	public loginGoogle(): void {

		this.auth.loginGoogle().then(social => {

			this.socialUser = social;
			this.auth.sendLoginGoogleAPI(this.socialUser.authToken).subscribe((resp) => {

				this.auth.saveToken(resp.token, EAuthenticationAvailableOptions.GOOGLE_LOGIN);
				this.userService.setlegal();
				this.userService.getDataUser();
				
				if (!localStorage.getItem("last-url"))
					this.router.navigateByUrl('');
				else {
					var url = localStorage.getItem("last-url")

					if (localStorage.getItem("empresa"))
						url = '/cliente/ingresar'
					else
						localStorage.removeItem("last-url")

					this.router.navigate([url])

				}
				this.closeModal();
			}, (error) => {

				this.registerMessage = { type: 'error', message: 'Error al iniciar sesión con Google: ' + error.error };

			});

		}).catch(err => {

			this.registerMessage = { type: 'error', message: 'Error al iniciar sesión con Google: ' + err.error };

		});

	}

	/**
	 * Login on the App with Facebook services.
	 */
	public loginFacebook(): void {

		this.auth.loginFacebook().then(social => {

			this.socialUser = social;
			this.auth.sendLoginFacebookAPI(this.socialUser.authToken, this.socialUser.id).subscribe((resp) => {

				this.auth.saveToken(resp.token, EAuthenticationAvailableOptions.FACEBOOK_LOGIN);

				this.userService.getDataUser();
				
				if (!localStorage.getItem("last-url"))
					this.router.navigateByUrl('');
				else {
					var url = localStorage.getItem("last-url")

					if (localStorage.getItem("empresa"))
						url = '/cliente/ingresar'
					else
						localStorage.removeItem("last-url")

					this.router.navigate([url])

				}
				this.closeModal();
			}, (error) => {

				this.registerMessage = { type: 'error', message: 'Error al iniciar sesión con Facebook: ' + error.error };

			});

		}).catch(err => {

			this.registerMessage = { type: 'error', message: 'Error al iniciar sesión con Facebook: ' + err.error };

		});

	}

}
