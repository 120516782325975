import { Injectable } from '@angular/core';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IToast } from 'src/app/interfaces/toast.interface';

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  private toastDataSource: Subject<IToast> = new Subject();
  public toastObservable: Observable<IToast> = this.toastDataSource.asObservable();

  /**
   * Method to display a toast with a message.
   * @param newValue Message to be displayed inside the toast.
   *
   * @return void
   */
  public displayToast(newToast: IToast): void {

    this.toastDataSource.next(newToast);

  }

}
