<div class="bottomSheetContainer" (click)="dimiss()">
	<div class="timeline-container">
		<div class="timeline-header">Seguimiento Jugada</div>
		<div class="timeline" *ngFor="let seguimiento of listaSeguimiento">
			<div class="timeline-item">
				<div class="timeline-item-content">
					<div>
						<div class="timestamp">{{seguimiento.fecha | date: 'dd/MM/YY HH:mm'}}</div>
						<div class="status">{{seguimiento.nombre}}</div>
					</div>
					<div class="view-button" *ngIf="seguimiento.url" (click)="goToLink(seguimiento.url)">Ver</div>
				</div>
			</div>
		</div>
	</div>
</div>