import { Component } from "@angular/core";

@Component({
    selector:'app-inicio-auth',
    templateUrl:'./inicio-auth.component.html',
    styleUrls:['./inicio-auth.component.scss']
})
export class inicioAuthComponent
{
    constructor()
    {
        
    }
}