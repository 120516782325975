import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, Input } from '@angular/core';
import { ToastService } from '@shared/services/toast.service';
import { IToast } from 'src/app/interfaces/toast.interface';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
  })
export class ToastComponent{

  public title: string;
  public message: string;

  public lastTitle: string;
  public lastMessage: string;

  constructor(

    public toastService: ToastService,

  ){

    this.toastService.toastObservable.subscribe((newToast: IToast) => {

      this.createToast(newToast);

    })

  }

  private checkToast(){

    if(this.title || this.message){

      this.title = undefined;
      this.message = undefined;
      setTimeout(() => {

        this.lastTitle = this.title;
        this.lastMessage = this.message;

      }, 800)

    }

  }

  private createToast(toast: IToast){

    this.checkToast();

    setTimeout( () => {

      this.title = toast.title;
      this.message = toast.message;
      if(this.title || this.message){

        this.lastTitle = this.title;
        this.lastMessage = this.message;

        setTimeout( () => {

            this.title = undefined;
            this.message = undefined;
            setTimeout(() => {

              this.lastTitle = this.title;
              this.lastMessage = this.message;

            }, 800)

        }, this.min(this.calcTimeOut(this.title, this.message), 5000));

      }

    }, 800)

  }

  private calcTimeOut(title: string, message: string): number{
    return (title.length/20)*1000 + (message.length/20)*1000;
  }

  private min(amount1: number, amount2: number){
    
    if(amount1 < amount2){
      return amount2;
    } else {
      return amount1;
    }

  }

}
