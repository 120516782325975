import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RegisterUserModel } from '@models/register-user.model';
import { ApiRouterService } from '@shared/services/api-router.service';
import { Observable } from 'rxjs';

/**
 * Register service. Provides the app with an authentication service connected with the API.
 */
@Injectable({
	providedIn: 'root'
})
export class RegisterService extends ApiRouterService {

	constructor(

		private http: HttpClient

	) {

		super();

	}

	/**
	 * Method register to connect with the API
	 * @param user User data for register.
	 * @returns post request to API register
	 */
	public registerUser(user: RegisterUserModel): Observable<any> {

		this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
		const httpOptions = {
			headers: this.httpHeaders
		};
		const registerData = {
			nombre: user.name,
			password: user.password,
			email: user.email,
			playerId: user.playerId,
			afiliado: (localStorage.getItem('af') ? localStorage.getItem('af') : null),
			accepta_legal: true
		};
		return this.http.post(this.URL + 'registro', registerData, httpOptions);

	}

}
