import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { AuthenticationService } from "@api/services/authentication.service";
import { MantenimientoService } from "@api/services/mantenimiento.service";
import { CCoreComponent } from "@classes/core/core-component.class";
import { ApplicationStateService } from "@shared/services/application-state.service";

@Injectable({
    providedIn:'root'
})
export class MantenimientoGuard 
{
    constructor(private mantenimientoService: MantenimientoService,
                private router: Router
        )
    {}

    getUrl(route: ActivatedRouteSnapshot)
    {
        return route.pathFromRoot.map(v=> v.url.map(segment => segment.toString()).join("/"))
                                .join("/");
    }

    canActivate(route: ActivatedRouteSnapshot): boolean
    {
        if(this.mantenimientoService.mantenimiento)
            return true;
        else
        {
            this.router.navigate(['/'])
            return false;
        }
    }
}