import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { AuthenticationService } from "@api/services/authentication.service";
import { CCoreComponent } from "@classes/core/core-component.class";
import { ApplicationStateService } from "@shared/services/application-state.service";
import { authModalComponent } from "src/app/layouts/authentication/desktop/authModal/authModal.component";
import { LoginComponent } from "src/app/layouts/authentication/login/login.component";
import { RegisterComponent } from "src/app/layouts/authentication/register/register.component";

@Injectable({
    providedIn:'root'
})
export class loginDesktopGuard extends CCoreComponent 
{
    constructor(aplicationStateService: ApplicationStateService,
                private authenticateService: AuthenticationService,
                private router: Router,
                private dialog: MatDialog,
        )
    {
        super(aplicationStateService);
    }

    getUrl(route: ActivatedRouteSnapshot)
    {
        return route.pathFromRoot.map(v=> v.url.map(segment => segment.toString()).join("/"))
                                .join("/");
    }

    canActivate(route: ActivatedRouteSnapshot): boolean
    {
        if(!this.authenticateService.isAuthenticated())
        {

			if(this.device == this.devices.DESKTOP){

				if(this.getUrl(route) == "/acceso//register"){
					this.dialog.open(authModalComponent,{
						panelClass:'modal-custom',
						data:{
							type:'register',
						}
					  });
					return false;
				} else if(this.getUrl(route) == "/acceso//recover"){
					this.dialog.open(authModalComponent,{
						panelClass:'modal-custom',
						data:{
							type:'recover',
						}
					  });
					return false;
				} else {
					this.dialog.open(authModalComponent,{
						panelClass:'modal-custom',
						data:{
							type:'login',
						}
					  });
					return false;
				} 

				/*
				if(this.getUrl(route) == "/acceso//login"){
					this.dialog.open(authModalComponent,{
						panelClass:'modal-custom',
						data:{
							type:'login',
						}
					  });
					return false;
				} else if(this.getUrl(route) == "/acceso//register"){
					this.dialog.open(authModalComponent,{
						panelClass:'modal-custom',
						data:{
							type:'register',
						}
					  });
					return false;
				} else if(this.device == this.devices.DESKTOP && (this.getUrl(route) == "/acceso//inicio-lobby" || this.getUrl(route) == "/acceso//options/acceder") )
                	this.router.navigateByUrl('/acceso/registro');
				*/
			}
            return true;
        }
        else
        {
            this.router.navigateByUrl('/');
            return false;
        }
    }
}