import { VIRTUAL_SCROLL_STRATEGY } from "@angular/cdk/scrolling";
import { AfterViewChecked, Component, Inject, Renderer2 } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { CustomVirtualScroll } from "@classes/CustomVritualScoll";
import { MPlay } from "@models/api-models/plays-api.models";
import { ResultDraw } from '@models/resultDraw.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationStateService } from '@shared/services/application-state.service';
import { CCoreComponent } from '@classes/core/core-component.class';
import { PlaysAPIService } from '@api/services/games/plays-api.service';
import { ToastrService } from "ngx-toastr";

@Component({
    selector:'app-bottomSheetRegalar',
    templateUrl:'./bottomSheetRegalar.component.html',
    styleUrls:['./bottomSheetRegalar.component.scss']
    ,providers:[{provide:VIRTUAL_SCROLL_STRATEGY,useClass:CustomVirtualScroll}]
})
export class bottomSheetRegalarComponent extends CCoreComponent
{

    giftForm: FormGroup;
    id: any;
    constructor(
        private bottomSheetRegalar: MatBottomSheetRef<bottomSheetRegalarComponent>,
        applicationStateService: ApplicationStateService,
        private playsAPIService: PlaysAPIService, 
        private toastr: ToastrService,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: {jugada_id},
                private renderer: Renderer2
    ){

        super(applicationStateService);
        this.id = data.jugada_id;
    }

    ngAfterViewChecked()
    {
        this.setRandomColor();
    }

    ngOnInit(): void{

        this.giftForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            aceptar: new FormControl('', [Validators.required])
        });

    }

    get email() {return this.giftForm.get('email'); }
    get aceptar() {return this.giftForm.get('aceptar'); }

    giftPlay(): void{
        if(this.giftForm.valid){
          this.playsAPIService.giftPlay(this.giftForm.controls.email.value, this.id).subscribe(res =>{
            if(res.status == 200){
                this.toastr.success('Se ha enviado la jugada');
              } else {
                this.toastr.error(res.u_message);
              }
          });
        }
  
    }

    dimiss()
    {
        this.bottomSheetRegalar.dismiss();
    }

    getFirstLetter(letra: string)
    {
        return letra.toLocaleUpperCase()[0];
    }

    setRandomColor() 
    {
        document.querySelectorAll("div[class='letter']").forEach(x=>{
            this.renderer.setStyle(x,"background-color",this.generateColors());
        })
    }

    private generateColors()
    {
        let letters = '0123456789ABCDEF';
        let color = '#';

        for (var i = 0; i < 6; i++){
            color += letters[Math.floor(Math.random() * 16)];
        }

        return color;
    }
}