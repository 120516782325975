import { VIRTUAL_SCROLL_STRATEGY } from "@angular/cdk/scrolling";
import { AfterViewChecked, Component, Inject, Renderer2 } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { CustomVirtualScroll } from "@classes/CustomVritualScoll";
import { ColorGenerator } from "@classes/ColorGenerator";
import { MPlay } from "@models/api-models/plays-api.models";
import { ResultDraw } from '@models/resultDraw.model';
import { playFreeService } from "@api/services/playFree.service";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { map, startWith } from 'rxjs/operators';

export interface Contact {
	name: string;
	number: string;
	initials: string;
}

@Component({
    selector:'app-bottomSheetAgenda',
    templateUrl:'./bottomSheetAgenda.component.html',
    styleUrls:['./bottomSheetAgenda.component.scss']
    ,providers:[{provide:VIRTUAL_SCROLL_STRATEGY,useClass:CustomVirtualScroll}]
})
export class bottomSheetAgendaComponent
{

	public colorGenerator: ColorGenerator;
    contacts: Contact[];
	selected: Contact;
	ContactCtrl = new FormControl();
	filteredContacts: Observable<Contact[]> = new Observable<Contact[]>();

    constructor(private bottomSheetQuinielaResult: MatBottomSheetRef<bottomSheetAgendaComponent>,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: {contacts: Contact[]},
				protected playFreeService: playFreeService,
    ){
        this.contacts = data.contacts;
		this.colorGenerator = new ColorGenerator();
		this.filteredContacts = this.ContactCtrl.valueChanges
		.pipe(
			startWith(''),
			map(state => state ? this._filterContacts(state) : this.contacts.slice())
		);
    }

	private _filterContacts(value: string): Contact[] {
		const filterValue = value.toLowerCase();

		return this.contacts.filter(state => state.name.toLowerCase().includes(filterValue));
	}

    dimiss()
    {
        this.bottomSheetQuinielaResult.dismiss();

    }

    getFirstLetter(letra: string)
    {
        return letra.toLocaleUpperCase()[0];
    }

	selectContact(contact: Contact){
		this.selected = contact;
		this.playFreeService.setContacto(contact);
		this.bottomSheetQuinielaResult.dismiss();
	}
}