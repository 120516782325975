import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MPlay } from '@models/api-models/plays-api.models';
import { ToastrService } from 'ngx-toastr';
import { UserApiService } from '@api/services/user.service';
import { Subscription } from 'rxjs';
import { ReservasService } from '@api/services/reservas.service';
import { BetService } from '@modules/games/bet.service';
import { logEvent } from 'firebase/analytics';
import { Router } from '@angular/router';
import { FireBase } from '@classes/fireBase';
import { modalAlertaComponent } from '@shared/components/modals/modalAlerta/modalAlerta.component';
import { modalYesNoComponent } from '@shared/components/modals/modalYesNo/modalYesNo.component';
import { MatDialog } from '@angular/material/dialog';
import { modalPinComponent } from '@shared/components/modals/modalPin/modalPin.component';

@Component({
	selector: 'app-bottom-sheet-direccion',
	templateUrl: 'bottomSheetDireccion.component.html',
	styleUrls: ['bottomSheetDireccion.component.scss']
})
export class bottomSheetDireccion extends FireBase implements OnInit {

	public play: MPlay;
	public nombreInicial: string;
	public nombre: string;

	public disabled: boolean = true;
	private compra: Subscription;

	public userDates: any;

	public cost: number;
	public costCents: string;
	private saldoCliente: number;

	constructor(protected userService: UserApiService,
	private bottomSheetDireccion: MatBottomSheetRef<bottomSheetDireccion>,
	protected toastrService: ToastrService,
	protected reservasService: ReservasService,
	private dialog: MatDialog,
	protected betService: BetService,
	private router: Router) {

		super();

		this.cost = betService.cost;

		betService.costObservable$.subscribe((newCost: number) => {

			this.cost = newCost;

			if (this.cost.toString().split('.')[1] === undefined) {

				this.costCents = '00';

			} else {

				this.costCents = this.cost.toString().split('.')[1];

			}
		});

		this.userDates = {
			"direccion":"",
			"codigo_postal":"",
			"ciudad":"",
			"provincia":""
		}
	}

	ngOnInit() {
		this.loadAddresCliente();
		this.loadSaldoCliente();
		this.betService.costObservable$.subscribe(cost => {
			if (cost - (Number(this.betService.sendMode.coste)) > 0)
				this.disabled = false;
			else
				this.disabled = true;
		})
	}

	loadAddresCliente() {
		this.userService.usuarioObservable.subscribe(x =>{
			if(x)
			{
				this.userDates.direccion=x.adress;
				this.userDates.codigo_postal=x.cp;
				this.userDates.ciudad=x.city;
				this.userDates.provincia=x.province;
				this.userDates.telefono=x.phone;
			}		
		});
	}

	loadSaldoCliente() {
		this.userService.usuarioObservable.subscribe(res => {
			if(res)
				this.saldoCliente = res.client.getSaldoTotal();
		});
	}

	saldoInsuficienteBet() {
		let texto = "Debes cargar saldo para poder confirmar esta jugada"
		let dialogAlert = this.dialog.open(modalYesNoComponent, {
			panelClass: 'modal-custom',
			data: {
				title: 'Saldo Insuficiente',
				texto,
				buttonConfirm: 'Cargar',
				buttonCancel: 'Cancelar',
				buttonConfirmColor: "blue",
				showIcon: false,
				showOptionCheck: false
			}
		});

		dialogAlert.afterClosed().subscribe(res => {
			if (res)
				this.router.navigate(['/cliente/saldo']);
		})
	}

	confirm(): void {
		this.userService.updateDataUser(this.userDates).subscribe((x: any) => {
			if (x) {
				let nuevoSaldo = this.saldoCliente - this.betService.cost;
				//Establecemos los datos del modal / set data to alert modal
				let texto = "Esta jugada tiene un coste de:</br></br>";
				texto += "<h2 class='currency'>" + this.betService.cost + " €</h2></br>";
				texto += "Saldo restante: <strong>" + nuevoSaldo + " €</strong>";

				if (nuevoSaldo < 0)
					this.saldoInsuficienteBet();
				else {
					let alertDialog = this.dialog.open(modalAlertaComponent, {
						panelClass: 'modal-custom',
						data: {
							title: 'Confirmar Jugada',
							texto,
							buttonConfirm: 'Pagar jugada',
							showOptionCheck: true
						}
					});

					alertDialog.afterClosed().subscribe(res => {
						if (res == true) {
							this.checkSecurity();
						}
					});
				}
			}
			else {
				this.toastrService.error("Debes rellenar la informacion de envio para poder completar la compra", "Error", {
					positionClass: 'toast-bottom-right'
				});
			}
		});
	}

	checkSecurity() {
		this.userService.getSeguridad().subscribe((res) => {
			if (res.status == 200) {
				if (res.data.compra_producto) {
					let checkPinDialog = this.dialog.open(modalPinComponent, {
						panelClass: 'modal-custom',
						disableClose: true,
						data: { 
							'title': "Escribe tu PIN de Seguridad",
							'mode': "Check",
							'parameters': null
						}
					});
					return checkPinDialog.afterClosed().subscribe((res) => {
						if (res) {
							this.comprar();
						}
					});
				}
				else {
					this.comprar();
				}
			}
			else {
				this.comprar();
			}
			return false;
		});
	}

	comprar() {
		this.disabled = true;
		//Se realiza el proceso de compra
		this.compra = this.reservasService.selectReservaOb.subscribe(x => {

			x.envio_id = this.betService.sendMode.id;
			x.precio = this.betService.cost - Number(this.betService.sendMode.coste);

			this.reservasService.comprar(x).subscribe((res: any) => {
				if (res.status == 200) {
					this.betService.getPlayData(JSON.parse(res.data.pedido_id)).subscribe((x: any[]) => {

						let paramFireBase = {
							currency: 'EUR',
							GAME_ID: 6,
							IS_SUCCESSFUL: true,
							METHOD: "BOOKING",
							CLIENT_ID: x[0].cliente_id,
							value: this.betService.cost
						}

						logEvent(this.analytics, "SPEND_VIRTUAL_CURRENCY", paramFireBase)
						this.userService.setSaldo(this.saldoCliente - this.betService.cost);
						this.router.navigate(['/', 'cliente', 'jugadas', x[0].id]);
						this.dismiss();
					});
				}
				else {
					this.toastrService.error(res.u_message);
				}
			});
		});
	}

	cancel() {
		this.dismiss();
	}

	dismiss() {
		this.bottomSheetDireccion.dismiss();
	}
}
