import { Injectable } from "@angular/core";
import { PlaysFilter } from "@models/playsFilter";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn:'root'
})
export class FiltrosService
{
    private filtros = new PlaysFilter();
    private abonosUpdate: boolean = false
    private jugadasUpdate: boolean = false

    private filtros$: BehaviorSubject<PlaysFilter> = new BehaviorSubject(this.filtros)
    public filtrosOb: Observable<PlaysFilter> = this.filtros$.asObservable();

    private abonosUpdate$: BehaviorSubject<boolean> = new BehaviorSubject(this.abonosUpdate)
    public AbonosUpdateOb: Observable<boolean> = this.abonosUpdate$.asObservable();

    private jugadasUpdate$: BehaviorSubject<boolean> = new BehaviorSubject(this.jugadasUpdate)
    public jugadasUpdateOb: Observable<boolean> = this.jugadasUpdate$.asObservable();

    constructor() { }

    setFiltros(playsFiltros: PlaysFilter)
    {
        this.filtros = playsFiltros
        this.filtros$.next(playsFiltros)
    }

    setUpdateAbonos(data: boolean)
    {
        this.abonosUpdate = data;
        this.abonosUpdate$.next(data)
    }

    setUpdateJugadas(data: boolean)
    {
        this.jugadasUpdate = data;
        this.jugadasUpdate$.next(data)
    }
}