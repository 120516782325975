export class TokenJWTModel {

  public idClient: string;
  public tokenExpires: string;
  public tokenInit: string;
  public token: string;
  public authToken: any;

  constructor(
    idClient: string,
    tokenExpires: string,
    tokenInit: string,
    token: string,
    authToken: any
  ) {

    this.idClient = idClient;
    this.tokenExpires = tokenExpires;
    this.tokenInit = tokenInit;
    this.token = token;
    this.authToken = authToken;

  }

}
