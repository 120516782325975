<div class="bottomSheetContainer">
  <div class="button" (click)="dismiss()">
      <div class="btn-ln">
          <div class="line" [ngClass]="{'line-cool': false}">

          </div>
          <h4></h4>
      </div>
  </div>
  <div class="container">
          <div class="card">
              <div class="body1">
                  <div class="titulo">
                      <h4>Cambia el nombre de tu boleto</h4>
                  </div>
                  <div>
                    <input type="text" [(ngModel)]="nombre" placeholder="Introduce el nuevo nombre">
                  </div>
              </div>
          </div>

      <div class="bottom-butons">
          <button class="btn-filtrar" (click)="confirmar()">Confirmar</button>
          <button class="btn-cancel" (click)="cancelar()">Cancelar</button>
      </div>

  </div>
</div>
