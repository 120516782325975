import { VIRTUAL_SCROLL_STRATEGY } from "@angular/cdk/scrolling";
import { AfterViewChecked, Component, Inject, Renderer2 } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { CustomVirtualScroll } from "@classes/CustomVritualScoll";
import { MPlay } from "@models/api-models/plays-api.models";
import { ResultDraw } from '@models/resultDraw.model';
import { PlaysAPIService } from '@api/services/games/plays-api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector:'app-bottomSheetJugadaRegalada',
    templateUrl:'./bottomSheetJugadaRegalada.component.html',
    styleUrls:['./bottomSheetJugadaRegalada.component.scss']
    ,providers:[{provide:VIRTUAL_SCROLL_STRATEGY,useClass:CustomVirtualScroll}]
})
export class bottomSheetJugadaRegaladaComponent implements AfterViewChecked
{
    prueba = ['1','2','3'];
    jugada_regalada : any;

    constructor(private bottomSheetJugadaRegalada: MatBottomSheetRef<bottomSheetJugadaRegaladaComponent>,
                private playsAPIService: PlaysAPIService,
                private router: Router,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: {jugada_regalada},
                private renderer: Renderer2
    ){
        this.jugada_regalada = data.jugada_regalada;
    }

    ngAfterViewChecked()
    {
        this.setRandomColor();
    }

    dimiss()
    {
        this.bottomSheetJugadaRegalada.dismiss();
    }

    getFirstLetter(letra: string)
    {
        return letra.toLocaleUpperCase()[0];
    }

    setRandomColor() 
    {
        document.querySelectorAll("div[class='letter']").forEach(x=>{
            this.renderer.setStyle(x,"background-color",this.generateColors());
        })
    }

    private generateColors()
    {
        let letters = '0123456789ABCDEF';
        let color = '#';

        for (var i = 0; i < 6; i++){
            color += letters[Math.floor(Math.random() * 16)];
        }

        return color;
    }

    cancelGiftedPlay(): void{
        this.playsAPIService.cancelGiftedPlay(this.jugada_regalada.jugada_id).subscribe((res) => {
            this.router.navigate(['/cliente/jugadas']);
        });
    }
}