import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ConfigurationApiService } from '@api/services/configuration.service';
import { HeaderService } from '@api/services/header.service';
import { FireBase } from '@classes/fireBase';
import { logEvent } from '@firebase/analytics';
import { ModalTourCoreComponent } from '@shared/components/modals/modalTour/modal-tour-core.component';
import { take } from 'rxjs';
import { GoogleLoginProvider, SocialAuthService } from "@abacritt/angularx-social-login";
import { SocialUser } from "@abacritt/angularx-social-login";
import { AuthenticationService } from '@api/services/authentication.service';
import { EAuthenticationAvailableOptions } from '@enums/common/authentication-options.enum';
import { UserApiService } from '@api/services/user.service';
import { ToastrService } from 'ngx-toastr';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent extends FireBase implements OnInit, AfterContentChecked {
	title = 'miloto';
	public modeGroup: Boolean = false;
	public socialUser: SocialUser;
	constructor(private routerActive: ActivatedRoute,
		private router: Router,
		private configService: ConfigurationApiService,
		headerService: HeaderService,
		titleService: Title,
		protected dialog: MatDialog,
		private authService: SocialAuthService,
		public auth: AuthenticationService,
		private userService: UserApiService,
		private toastrService: ToastrService,
	) {
		super()
		headerService.modeGrupoCoolOb.subscribe(res => this.modeGroup = res);
		this.googleState();
	}

	loadConfig() {
		this.configService.getConfig().pipe(take(1)).subscribe((res: any) => {
			if (res.status === 200) {
				this.configService.updateConfig(res.data.configuraciones);
				//this.configService.setGuindaConfig(JSON.parse(res.data.configuraciones[4].configuracion).guinda);
			}
		});
	}

	ngOnInit() {
		this.loadConfig();

		if (this.router.url.indexOf('legal') != -1) {
			if (!localStorage.getItem("first")) {
				localStorage.setItem("timesVisited", "1");
				this.firstTour();
				localStorage.setItem("first", "false")
				logEvent(this.analytics, "first_visit")
			}
			else {
				if (!localStorage.getItem("timesVisited")) {
					localStorage.setItem("timesVisited", "1");
					this.firstTour();
				}
				else {
					let timesVisited = parseInt(localStorage.getItem("timesVisited")) + 1;
					if (timesVisited == 5) {
						this.secondTour();
					}
					if (timesVisited == 10) {
						this.thirdTour();
					}
					localStorage.setItem("timesVisited", timesVisited.toString());
				}
			}
		}
		this.loadAfiliadoParams();
		this.loadTitle();
		this.loadGuindaConfig();

		logEvent(this.analytics, "session_start")

	}

	loadGuindaConfig() {
		// this.configService.guindaConfig().pipe(take(1)).subscribe((res: any) => {
		// 	this.configService.setGuindaConfig(res.data);
		// });
	}

	googleState() {
		this.authService.authState.subscribe((user) => {
			if (user != null) {

				this.socialUser = user;
				this.auth.sendLoginGoogleAPI(user.idToken).subscribe((resp: any) => {

					this.auth.saveToken(resp.token, EAuthenticationAvailableOptions.GOOGLE_LOGIN);

					this.userService.getDataUser();

					if (!localStorage.getItem("last-url"))
						this.router.navigateByUrl('');
					else {
						var url = localStorage.getItem("last-url")

						if (localStorage.getItem("empresa"))
							url = '/cliente/ingresar'
						else
							localStorage.removeItem("last-url")

						this.router.navigate([url])
					}
				}, (error) => {

					this.toastrService.error('Error al Acceder con Google intentelo de nuevo más tarde.');
				});

			}
		});

	}

	firstTour() {
		let tourDialog = this.dialog.open(ModalTourCoreComponent, {
			panelClass: 'modal-custom',
			data: {
				'slidesMobile': [
					{
						id: 0,
						src: '/assets/images/pages/tour/1-movil.png',
						title: 'Bienvenido a Miloto',
						subtitle: 'Ahora te contaremos como funciona, si ya lo sabes saltate este tour.',
					},
					{
						id: 1,
						src: '/assets/images/pages/tour/2-movil.png',
						title: 'Escáner de premios',
						subtitle: 'Utiliza nuestro escáner para comprobar los premios de tus boletos físicos.',
					},
					{
						id: 2,
						src: '/assets/images/pages/tour/3-movil.png',
						title: 'Todos los juegos en tu bolsillo',
						subtitle: 'Ingresa con tarjeta o Bizum y compra él juego que más te guste.',
					},
					{
						id: 3,
						src: '/assets/images/pages/tour/4-movil.png',
						title: 'Tus premios al instante',
						subtitle: 'Recibe en tu móvil él resultado de tus jugadas.',
					},
					{
						id: 4,
						src: '/assets/images/pages/tour/5-movil.png',
						title: 'Peñas y Grupos',
						subtitle: 'Crea un grupo con tus amigos y jugar juntos. O comparte una jugada por WhatsApp. Así de rápido y fácil.',
					}
				],
				'slidesDesktop': [
					{
						id: 0,
						src: '/assets/images/pages/tour/1-escritorio.jpg',
						title: 'Bienvenido a Miloto',
						subtitle: 'Ahora te contaremos como funciona, si ya lo sabes saltate este tour.',
					},
					{
						id: 1,
						src: '/assets/images/pages/tour/2-escritorio.jpg',
						title: 'Escáner de premios',
						subtitle: 'Utiliza nuestro escáner para comprobar los premios de tus boletos físicos.',
					},
					{
						id: 2,
						src: '/assets/images/pages/tour/3-escritorio.jpg',
						title: 'Todos los juegos en tu bolsillo',
						subtitle: 'Ingresa con tarjeta o Bizum y compra él juego que más te guste.',
					},
					{
						id: 3,
						src: '/assets/images/pages/tour/4-escritorio.jpg',
						title: 'Tus premios al instante',
						subtitle: 'Recibe en tu móvil él resultado de tus jugadas.',
					},
					{
						id: 4,
						src: '/assets/images/pages/tour/5-escritorio.jpg',
						title: 'Peñas y Grupos',
						subtitle: 'Crea un grupo con tus amigos y jugar juntos. O comparte una jugada por WhatsApp. Así de rápido y fácil.',
					}
				]
			}
		});
		return tourDialog.afterClosed();
	}

	secondTour() {
		let tourDialog = this.dialog.open(ModalTourCoreComponent, {
			panelClass: 'modal-custom',
			data: {
				'slidesMobile': [
					{
						id: 0,
						src: '/assets/images/pages/tour/6-movil.png',
						title: 'Jugada rápida 🌩️',
						subtitle: 'Donde veas un rayo puedes hacer una jugada en un toque.',
					},
					{
						id: 1,
						src: '/assets/images/pages/tour/7-movil.png',
						title: 'Suscribete a tus jugadas favoritas ❤️',
						subtitle: 'Si quieres que no se te olviden nunca, suscríbete a una jugada y se renovará automáticamente.',
					},
					{
						id: 2,
						src: '/assets/images/pages/tour/8-movil.png',
						title: 'Todos los botes en un gesto',
						subtitle: 'Desliza a la izquierda desde la pantalla de juegos  y accede a todos los botes en un gesto.',
					}
				],
				'slidesDesktop': [
					{
						id: 0,
						src: '/assets/images/pages/tour/6-escritorio.png',
						title: 'Jugada rápida 🌩️',
						subtitle: 'Donde veas un rayo puedes hacer una jugada en un toque.',
					},
					{
						id: 1,
						src: '/assets/images/pages/tour/7-escritorio.png',
						title: 'Suscribete a tus jugadas favoritas ❤️',
						subtitle: 'Si quieres que no se te olviden nunca, suscríbete a una jugada y se renovará automáticamente.',
					},
					{
						id: 2,
						src: '/assets/images/pages/tour/8-escritorio.png',
						title: 'Todos los botes en un gesto',
						subtitle: 'Desliza a la izquierda desde la pantalla de juegos  y accede a todos los botes en un gesto.',
					}
				]
			}
		});
		return tourDialog.afterClosed();
	}

	thirdTour() {
		let tourDialog = this.dialog.open(ModalTourCoreComponent, {
			panelClass: 'modal-custom',
			data: {
				'slidesMobile': [
					{
						id: 0,
						src: '/assets/images/pages/tour/9-movil.png',
						title: 'Juega con tus amigos',
						subtitle: 'Crea un grupo para jugar y compartir los premios.',
					},
					{
						id: 1,
						src: '/assets/images/pages/tour/10-movil.png',
						title: 'Comparte por WhatsApp',
						subtitle: 'Quieres compartir una jugada con alguien instantáneamente, hazlo  por WhatsApp.',
					},
					{
						id: 2,
						src: '/assets/images/pages/tour/11-movil.png',
						title: 'Lotería para amigos y familia.',
						subtitle: 'Puedes crear tú mismo una reserva para tu familia y amigos para jugar todos él mismo número.',
					},
					{
						id: 3,
						src: '/assets/images/pages/tour/12-movil.png',
						title: 'Lotería para empresas',
						subtitle: 'Crear tú mismo la lotería de tu empresa en menos de 1 minuto. Elige entre más de 70.000 números disponibles. Fácil y sencillo.',
					}
				],
				'slidesDesktop': [
					{
						id: 0,
						src: '/assets/images/pages/tour/9-escritorio.png',
						title: 'Juega con tus amigos',
						subtitle: 'Crea un grupo para jugar y compartir los premios.',
					},
					{
						id: 1,
						src: '/assets/images/pages/tour/10-escritorio.png',
						title: 'Comparte por WhatsApp',
						subtitle: 'Quieres compartir una jugada con alguien instantáneamente, hazlo  por WhatsApp.',
					},
					{
						id: 2,
						src: '/assets/images/pages/tour/11-escritorio.png',
						title: 'Lotería para amigos y familia.',
						subtitle: 'Puedes crear tú mismo una reserva para tu familia y amigos para jugar todos él mismo número.',
					},
					{
						id: 3,
						src: '/assets/images/pages/tour/12-movil.png',
						title: 'Lotería para empresas',
						subtitle: 'Crear tú mismo la lotería de tu empresa en menos de 1 minuto. Elige entre más de 70.000 números disponibles. Fácil y sencillo.',
					}
				]
			}
		});
		return tourDialog.afterClosed();
	}

	loadTitle() {
		this.router.events.subscribe(e => {

			if (e instanceof NavigationEnd) {
				if (localStorage.getItem('url') && (!this.router.url.includes("cliente/saldo") &&
					!this.router.url.includes("cliente/ingresar") &&
					!this.router.url.includes("cliente/cobrar") && //Pensar si dejar o no
					!this.router.url.includes("pago_realizado_ok"))) {
					localStorage.removeItem('url');
				}
			}

			//get all titles when all ready

		})
	}

	ngAfterContentChecked() {
		this.changerColorCrisp()
	}

	/**
	 * Function for changer color to crisp chat
	 */
	changerColorCrisp() {
		let crisp = document.getElementsByClassName("cc-7doi cc-1ada")[0];

		if (this.modeGroup && crisp) {
			crisp.setAttribute("style", "background-color: #7545FF !important")
		}
		else {
			if (crisp && crisp.hasAttributes())
				crisp.removeAttribute("style")
		}
	}

	loadAfiliadoParams() {
		this.routerActive.queryParams
			.subscribe(x => {
				if (x.af)
					localStorage.setItem('af', x.af);
			})
	}
}
