import { animate, animation, state, style, transition, trigger } from "@angular/animations";
import { Location } from "@angular/common";
import { Component, ElementRef, Renderer2, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "@api/services/authentication.service";
import { HeaderService } from "@api/services/header.service";
import { EAuthenticationAvailableOptions } from "@enums/common/authentication-options.enum";
import { SocialUser } from '@abacritt/angularx-social-login';
import { ToastrService } from "ngx-toastr";
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import { UserApiService } from "@api/services/user.service";

@Component({
	selector: 'app-options-menu',
	templateUrl: './options-menu.component.html',
	styleUrls: ['options-menu.component.scss'],
	animations: [
		trigger('enter', [
			state('void', style({
				opacity: 0,
				transform: 'translateY(-200%)'
			})),

			transition(':enter', [
				animate(200, style({
					opacity: 1,
					transform: 'translateY(0)'
				}))
			])
		])
	]
})
export class optionsMenuComponent {
	public mode: string;
	private socialUser: SocialUser;
	@ViewChild('component') component: ElementRef;

	constructor(private activatedRoute: ActivatedRoute,
		private router: Router,
		headerService: HeaderService,
		private auth: AuthenticationService,
		private toastrService: ToastrService,
		private renderer2: Renderer2,
		private userService: UserApiService,
	) {
		this.mode = this.activatedRoute.snapshot.params.mode;
		headerService.set_rutaBack("acceso/inicio-lobby");
	}

	messageHeader() {
		if (this.mode == 'acceder')
			return "Acceder a Miloto";
		else
			return "Registrate en Miloto";
	}

	messageLoginFacebook() {
		if (this.mode == 'acceder')
			return "Acceder con Facebook";
		else
			return "Registrate con Facebook";
	}


	messageLoginGoogle() {
		if (this.mode == 'acceder')
			return "Acceder con Google";
		else
			return "Registrate con Google";
	}

	messageLoginApple() {
		if (this.mode == 'acceder')
			return "Acceder con Apple";
		else
			return "Registrate con Apple";
	}

	navto() {
		if (this.mode == 'acceder') {
			this.renderer2.setStyle(this.component.nativeElement, "transform", 'translateY(-500%)');

			this.router.navigate(['/acceso/options/registrate']).then(() => {
				this.mode = 'registrate';
			});

			let interval = setInterval(() => {
				this.renderer2.setStyle(this.component.nativeElement, "transform", 'translateY(0)');
				clearInterval(interval)
			}, 250);
		}
		else {
			this.renderer2.setStyle(this.component.nativeElement, "transform", 'translateY(-500%)');
			this.router.navigate(['/acceso/options/acceder']).then(() => {
				this.mode = 'acceder';
			});
			let interval = setInterval(() => {
				this.renderer2.setStyle(this.component.nativeElement, "transform", 'translateY(0)');
				clearInterval(interval);
			}, 250);
		}
	}

	/**
   * Login on the App with Google services.
   */
	public loginGoogle(): void {

		this.auth.loginGoogle().then(social => {

			this.socialUser = social;
			this.auth.sendLoginGoogleAPI(this.socialUser.authToken).subscribe((resp: any) => {

				this.auth.saveToken(resp.token, EAuthenticationAvailableOptions.GOOGLE_LOGIN);

				this.userService.getDataUser();				

				if (localStorage.getItem("last-url")) {
					var url = localStorage.getItem("last-url")

					if (localStorage.getItem("empresa"))
						url = '/cliente/ingresar'
					else
						localStorage.removeItem("last-url")

					this.router.navigate([url])
				}
				else
					this.router.navigateByUrl('');

			}, (error) => {

				this.toastrService.error('Error al iniciar sesión con Google: ' + error.error);

			});

		}).catch(err => {

			this.toastrService.error('Error al iniciar sesión con Gooogle: ' + err.error);

		});

	}

	/**
	 * Login on the App with Facebook services.
	 */
	public loginFacebook(): void {

		this.auth.loginFacebook().then(social => {

			this.socialUser = social;
			this.auth.sendLoginFacebookAPI(this.socialUser.authToken, this.socialUser.id).subscribe((resp: any) => {

				this.auth.saveToken(resp.token, EAuthenticationAvailableOptions.FACEBOOK_LOGIN);

				this.userService.getDataUser();

				if (localStorage.getItem("last-url")) {
					var url = localStorage.getItem("last-url")

					if (localStorage.getItem("empresa"))
						url = '/cliente/ingresar'
					else
						localStorage.removeItem("last-url")

					this.router.navigate([url])
				}
				else
					this.router.navigateByUrl('');

			}, (error) => {

				this.toastrService.error('Error al iniciar sesión con Facebook: ' + error.error);

			});

		}).catch(err => {

			this.toastrService.error('Error al iniciar sesión con Facebook: ' + err.error);

		});

	}

	loginApple(event): void {
		event.preventDefault();
		const provider = new OAuthProvider("apple.com") // com.miloto.MilotoWebService apple.com

		const auth = getAuth()
		signInWithPopup(auth, provider)
			.then((res) => {

				//user data
				const user = res.user;

				// Apple credential
				const credential = OAuthProvider.credentialFromResult(res);
				//const accessToken = credential.accessToken;
				const idToken = credential.idToken;

				this.auth.sendLoginAppleAPI(idToken, user.uid).subscribe((res: any) => {
					this.auth.saveToken(res.token, EAuthenticationAvailableOptions.JWT_LOGIN);

					this.userService.getDataUser();
					
					if (!localStorage.getItem("last-url"))
						this.router.navigateByUrl('');
					else {
						var url = localStorage.getItem("last-url")

						if (localStorage.getItem("empresa"))
							url = '/cliente/ingresar'
						else
							localStorage.removeItem("last-url")

						this.router.navigate([url])
					}
				}, err => {
					this.toastrService.error('Error al iniciar sesión con Apple: ' + err.messag);
				})

			})
			.catch((err) => {
				this.toastrService.error('Error al iniciar sesión con Apple: ' + err.message);
			})
	}
}