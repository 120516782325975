<div class="bottomSheetContainer" >
  <div class="button">
    <div class="btn-ln" (click)="dimiss()">
      <div class="line">

      </div>
      <h4><img class="img-abono" src="/assets/images/icons/play-detail/abono-play-full.svg">Suscripción</h4>
    </div>
  </div>
  <div class="container">
    <div class="abono-container">
      <div class="abono-info">

        <span>
          Estas jugadas se renovarán automaticamente cada día que haya sorteo de este juego a las 9:00. Se cobrarán de tu tarjeta o del saldo en tu cuenta de usuario.
        </span>
        <span></span>

      </div>
      <div class="options-container">

        <div class="boton-abono" [ngClass]="{'': random, 'selected': !random}" (click)="this.random = false">Mismos números</div>
        <div class="boton-abono" [ngClass]="{'selected': random, '': !random}" (click)="this.random = true">Aleatorio</div>

      </div>

      <div>
        <button class="boton-abonarse">Suscribirse</button>
      </div>

    </div>
  </div>
</div>
