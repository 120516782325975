<div class="modalTwoFactor-wrapper">
	<div class="head">
		<div class="titulo">{{data.title}}</div>
	</div>

	<form id="two-factor-form" autocomplete="off" novalidate [formGroup]="pinForm" (ngSubmit)="submit()"
		class="modalTwoFactor-form">

		<div class="verification-code-container">
			<label class="verification-code-label">Código de verificación</label>
			<div class="verification-code-inputs">
				<input type="text" maxlength="1" class="digit-input" formControlName="digit1" (input)="onDigitInput($event, 0)" (keydown)="onKeyDown($event, 0)" (paste)="onPaste($event)" #digit1>
				<input type="text" maxlength="1" class="digit-input" formControlName="digit2" (input)="onDigitInput($event, 1)" (keydown)="onKeyDown($event, 1)" #digit2>
				<input type="text" maxlength="1" class="digit-input" formControlName="digit3" (input)="onDigitInput($event, 2)" (keydown)="onKeyDown($event, 2)" #digit3>
				<input type="text" maxlength="1" class="digit-input" formControlName="digit4" (input)="onDigitInput($event, 3)" (keydown)="onKeyDown($event, 3)" #digit4>
				<input type="text" maxlength="1" class="digit-input" formControlName="digit5" (input)="onDigitInput($event, 4)" (keydown)="onKeyDown($event, 4)" #digit5>
			</div>
			<div class="error-message" *ngIf="showError">Todos los campos son requeridos</div>
		</div>

		<div class="resend-code">
			<a [class.disabled]="resendDisabled" (click)="resendCode()">
				Volver a enviar código <span *ngIf="resendDisabled">({{resendCountdown}}s)</span>
			</a>
		</div>

		<button mat-raised-button color="miloto" type="submit" [disabled]="!isFormValid()"
			class="modalTwoFactor-button">
			<span>Verificar</span>
		</button>
	</form>
</div>