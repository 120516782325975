import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";
import { MantenimientoService } from "./mantenimiento.service";
import { UserApiService } from "./user.service";

@Injectable()
export class HttpInterceptCodes implements HttpInterceptor
{
    constructor(private authService: AuthenticationService, private router: Router, private userService: UserApiService, private toastr: ToastrService,private mantenimiento: MantenimientoService ){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(
            ()=> {},
            (err: any) => {
                if(err instanceof HttpErrorResponse){
                    if(err.status == 401)
                    {
                        this.authService.logout()
						this.userService.setSaldo(0)
                        this.userService.setUsuario(null)						
                        if(this.toastr.previousToastMessage != "Usuario no encontrado, por favor vuelva a iniciar sesión.")
                            this.toastr.error("Usuario no encontrado, por favor vuelva a iniciar sesión.")
                        this.router.navigate(['/'])
                    }

                    if(err.status == 503)
                    {
                        this.mantenimiento.setMantenimiento(true);
                        this.router.navigate(['/mantenimiento']);
                    }
                }
            }
        ))
    }
}