import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GamesApiservice } from '@api/services/games/games-api.service';
import { EGamesId } from '@enums/common/games.enum';
import { faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BetService } from '@modules/games/bet.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ILottery } from 'src/app/interfaces/plays/play-bet.interface';

@Component({
	selector: 'app-played',
	templateUrl: './played.component.html',
	styleUrls: ['./played.component.scss']
})
export class PlayedComponent implements OnInit {

	public ticketsVisible = true;
	public bets: any;
	public invisibleSpace = false;
	showAll = false;

	@Input() gameId: EGamesId;
	@Input() manual: boolean;
	@Input() automatic: boolean;

	constructor(

		private betService: BetService,

	) {
	}

	ngOnInit() {
		this.betService.observableBets.pipe(
			map(bets => bets
				.filter(bet => bet.numbersChecked && bet.numbersChecked.length > 0)
				.map(bet => {
					bet.numbersChecked.sort((a, b) => a - b);
					return bet;
				})
			)
		).subscribe(bets => {
			this.bets = bets;
			const hasMore = bets.slice(1).length > 0;
			if (bets.length > 0 && bets[0].extraChecked && bets[0].extraChecked.length > 0 && hasMore) {
				const restAreEmpty = bets.slice(1).every(bet => bet.extraChecked.length === 0);
				if (restAreEmpty) {
					this.invisibleSpace = true;
				} else {
					this.invisibleSpace = false;
				}
			}
		});
	}

	
	randomBet(bet, betToRandomize: number) {
		this.betService.randomizeBet(betToRandomize);
	}

	deleteBet(bet, betToDelete: number) {
		this.betService.removeBet(betToDelete);
	}

	toggleTicketsVisibility() {
		this.ticketsVisible = !this.ticketsVisible;
	}
}
