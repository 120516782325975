import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * @title Stepper overview
 */
@Component({
	selector: 'app-modal-tour-desktop',
	templateUrl: './modal-tour-desktop.component.html',
	styleUrls: ['./modal-tour-desktop.component.scss'],
})

export class ModalTourDesktopComponent {

	@Input() slides: any[];

	@Output() onDismissal = new EventEmitter<any>();

	constructor() { }

	dismiss(): void {
		this.onDismissal.emit(true);
	}
}