<div class="games-footer-wrapper">

    <div class="footer-row">

        <div class="saldo-wrapper">

            <div class="saldo">

                <span>{{ saldoCliente | currency:'EUR'}}</span>

            </div>
            <span class="saldo-text">Tu Saldo</span>
        </div>

        <div class="separator"></div>

        <div class="cost-wrapper">

            <div class="cost">

                <span>{{ cost + (guinda ? guindaCost : 0) | currency:'EUR' }}</span>

            </div>
            <span class="cost-text">Apuesta</span>
        </div>

        <div class="separator" *ngIf="donacion"></div>

        <div class="donation-wrapper" *ngIf="donacion">

            <div class="cost">

                <span>{{ donacion | currency:'EUR' }}</span>
                <img class="icon" matTooltip="Cada décimo tendrá un recargo de {{ donacion | number: '.2-2'}}€ como donativo" src="/assets/images/icons/balance/icono info.png"/>

            </div>
            <span class="cost-text">Donación</span>
        </div>

        <button (click)="playBet()" [disabled]='disabled' [ngClass]="disabled ? 'disabled-button' : activeClass">
            <ng-container *ngIf="!loading">
                {{titlebutton}}
            </ng-container>

            <mat-spinner *ngIf="loading" [diameter]="30"></mat-spinner>
        </button>

    </div>

</div>