<div class="spinner" *ngIf="!load">
  <app-spinner forceTo='component-sized'></app-spinner>
</div>

<div class="puntos-venta-wapper" *ngIf="load">
    <div class="header-wapper">
        <img src="{{urTicket}}" alt="img">
    </div>

    <div class="body-wrapper">
        <ul class="list-administraciones">
            <cdk-virtual-scroll-viewport #scroll itemSize="15" class="scroll">
                <li class="filaAdministracion" *ngFor="let ad of listaAdministrations">
                    <div class="header">
                        <div class="text">
                            <img src="/assets/images/icons/puntos-venta/Pin.svg" alt="img">
                            <h4>Administración nº {{ad.administration.selaeID}}</h4>
                        </div>
                        <div class="icons">
                            <img class="mr1" src="/assets/images/icons/puntos-venta/mail.svg" alt="img">
                            <img src="/assets/images/icons/puntos-venta/phone.svg" alt="img"/>
                        </div>
                    </div>
                    <div class="body">
                        <p>{{ad.administration.address}} ({{ad.administration.province}})</p>
                        <p>Reciben {{ad.fractions}} decimos</p>
                    </div>
                </li>

                <div class="bottom-spinner">
                    <mat-progress-spinner class="spinner" *ngIf="spinnerBottomLoadVisibility" [mode]="'indeterminate'" [diameter]="35"> </mat-progress-spinner>
                </div>
            </cdk-virtual-scroll-viewport>
        </ul>
    </div>
</div>