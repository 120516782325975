

<div class="bottomSheetContainer">
    <div class="button" (click)="dismiss()">
        <div class="btn-ln">
            <div class="line" [ngClass]="{'line-cool': modoGrupo}">

            </div>
            <h4>Ajustes de filtro</h4>
        </div>
    </div>
    <div class="container">

        <cdk-virtual-scroll-viewport #scroll itemSize="15" class="scroll">
            <div class="card">
                <div class="body1">
                    <div class="titulo">
                        <h4>Estado</h4>
                    </div>
    
                    <div class="body2">
                        <mat-radio-group
                          class="radio-group"
                          (change)="selectedEstado=$event.value">
    
                          <mat-radio-button class="radio-button" *ngFor="let estado of estados" [labelPosition]="'before'" [checked]="estado.check" [value]="estado.estado">
                            <p style="width: 100%;">{{estado.estado | titlecase}}</p>
                          </mat-radio-button>
    
                        </mat-radio-group>
                    </div>
    
                </div>
            </div>
    
            <div class="card">
                <div class="body1">
                    <div class="titulo">
                        <h4>Juegos</h4>
                    </div>
                    
                    <div class="body2">
                        <mat-radio-group
                          class="radio-group"
                          (change)="selectedGame=$event.value">
    
                          <mat-radio-button class="radio-button" *ngFor="let juego of juegos" [labelPosition]="'before'" [checked]="juego.check" [value]="juego.value">
                            <p style="width: 100%;">{{juego.juego}}</p>
                          </mat-radio-button>
    
                        </mat-radio-group>
                    </div>
                </div>
            </div>
    
            <div class="card" *ngIf="!modoGrupo">
                <div class="body1">
                    <div class="titulo">
                        <h4>Propiedad</h4>
                    </div>
                    <div class="body2">
                        <mat-radio-group
                          class="radio-group"
                          (change)="selectPropiedades=$event.value">
    
                          <mat-radio-button class="radio-button" *ngFor="let propiedad of propiedades" [labelPosition]="'before'" [checked]="propiedad.check" [value]="propiedad.estado">
                            <p style="width: 100%;">{{propiedad.estado}}</p>
                          </mat-radio-button>
    
                        </mat-radio-group>
                    </div>
                </div>
            </div>
    
            
        </cdk-virtual-scroll-viewport>

        <div class="bottom-butons">
            <button class="btn-reset" (click)="reset()">Resetear</button>
            <button class="btn-filtrar" [ngClass]="{'btn-filtrar-cool': modoGrupo}" (click)="filtrar()">Filtrar</button>
        </div>

    </div>
</div>