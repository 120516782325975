<div class="modalPin-wrapper">
	<div class="head">
		<div class="btn-accion" class="arrow" (click)="stop()">
			<img src="/assets/images/icons/header/arrow-left.svg" alt="img"/>
		</div>
		<div class="titulo">{{data.title}}</div>
	</div>

	<form id="pin-form" autocomplete="off" novalidate [formGroup]="pinForm" (ngSubmit)="submit()"
		class="modalPin-form">

		<mat-form-field appearance="outline" class="form-input" *ngIf="data.mode == 'Check' || !data.parameters.new">
			<mat-label>Pin Actual</mat-label>
			<input matInput type="password" placeholder="Pin Actual" formControlName="oldPin" required [(ngModel)]="oldPin">
			<mat-error>Mínimo 4 dígitos</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" class="form-input" *ngIf="data.mode != 'Check' && (data.parameters.new || data.parameters.changePin)">
			<mat-label>Nuevo Pin</mat-label>
			<input matInput type="password" placeholder="Nuevo Pin" formControlName="newPin" required [(ngModel)]="newPin">
			<mat-error>Mínimo 4 dígitos</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" class="form-input" *ngIf="data.mode != 'Check' && (data.parameters.new || data.parameters.changePin)">
			<mat-label>Repetir Pin</mat-label>
			<input matInput type="password" placeholder="Repetir Pin" formControlName="newRepeatPin" required [(ngModel)]="newRepeatPin">
			<mat-error>Mínimo 4 dígitos</mat-error>
		</mat-form-field>

		<button mat-raised-button color="miloto" type="submit" [disabled]="!pinForm.valid"
			class="modalPin-button">
			<span *ngIf="data.mode != 'Check'">Guardar Configuración</span>
			<span *ngIf="data.mode == 'Check'">Comprobar y Continuar</span>
		</button>
	</form>
</div>