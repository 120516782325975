import { DataSource } from '@angular/cdk/table';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IBet, IBetOptions, ILottery } from 'src/app/interfaces/plays/play-bet.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '@api/services/authentication.service';
import { ApiRouterService } from '@shared/services/api-router.service';
import { EGamesId } from '@enums/common/games.enum';
import { NumberSymbol } from '@angular/common';
import { formsEnvioModel } from '@models/api-models/fromsEnvio.model';
import { loteriaEmpresaModel } from '@models/api-models/loteria_empresa.models';

/**
 * Service for play a bet
 */
@Injectable({
	providedIn: 'root'
})
export class BetService extends ApiRouterService {

	// Table for calcalating the amount of bets based on the numbers and stars checked in one bet
	public betEuromillonesTable = [
		[1, 3, 6, 10],
		[6, 18, 36, 60],
		[21, 63, 126, 210],
		[56, 168, 336, 560],
		[126, 378, 756, 1260],
		[252, 756, 1512, 2520]];

	// Min numbers of the bets
	private minNumbers = 5;

	// Cost of 1 Euromillones bet and minimal Numbers and Stars
	public euromillonesSimpleBetCost = 2.5;
	public euromillonesMinStars = 2;

	// Cost of 1 EuroDreams bet and minimal Numbers and Stars
	public eurodreamsSimpleBetCost = 2.5;
	public eurodreamsStars = 1;
	public betArrayEuroDreams = [35, 1, 7, 28, 84, 210];

	// Table for calculating total bets in Bonoloto and Primitiva Based on numbers
	public betArray = [44, 1, 7, 28, 84, 210, 462];

	// Cost of 1 Bonoloto bet and Minimal Numbers
	private bonolotoSimpleBetCost = 0.5;

	// Cost of 1 Ticket of Loteria Nacional
	public lotterySimpleTicketCost = 3;

	// Cost of 1 Primitiva bet and Minimal Numbers and Stars
	private primitivasSimpleCost = 1;
	private jokerCost = 1;
	public primitivaMinStars = 0;

	// Table for calculating total bets in Bonoloto and Primitiva Based on numbers
	public betGordoArray = [1, 6, 21, 56, 126, 256, 462];

	// Cost of 1 El Gordo bet and Minimal Numbers and Stars
	private gordoSimpleCost = 1.5;
	public gordoMinStars = 1;

	// Cost of 1 Quiniela bet
	private quinielaSimpleCost = 0.75;


	// Observable for the options
	public options: IBetOptions;
	public optionsObservable$: Observable<IBetOptions>;
	private optionsSubject: BehaviorSubject<IBetOptions>;

	// Observable for the loading state
	public dataSourceLoading: BehaviorSubject<any> = new BehaviorSubject<any>('');
	public observableLoading: Observable<any> = this.dataSourceLoading.asObservable();

	// Atribute and Observable for the list of days to bet
	public addedDays: Array<{}>;
	public addedDaysObservable$: Observable<Array<{}>>;
	private adedDaysSubject: Subject<Array<{}>>;

	// Atribute and Observable for cost
	public cost: number;
	public costObservable$: Observable<number>;
	private costSubject: Subject<number>;

	// Observable date
	public date: string;
	public dateObservable$: Observable<string>;
	private dateSubject: Subject<string>;

	// Observable date
	public sorteoId: number;
	public sorteoIdObservable$: Observable<number>;
	private sorteoIdSubject: Subject<number>;

	// Obsarvable show numbers;
	public showNumbers: ILottery[];
	public showNumbersObservable$: Observable<ILottery[]>;
	private showNumbersSubject: Subject<ILottery[]>;


	public totalBets: number;
	public totalBetsObservable$: Observable<number>;
	private totalBetsSubject: Subject<number>;

	// Stored user's bets
	public bets: any;
	public simpleBet: boolean;
	public multiBetNumber: number[];
	public multiPlayBetLottery: Boolean;

	// Special number in some bets
	public reintegro: any;

	// Special number in some bets
	public joker: any;

	// Special values in Quiniela
	public pleno15: any;

	// Atribute and Observable for free bet
	public freeBet: boolean;
	public freeBetObservable$: Observable<boolean>;
	private freeBetSubject: Subject<boolean>;

	// Atribute and Observable for abono
	public abono: boolean = false;
	public abonoObservable$: Observable<boolean>;
	private abonoSubject: Subject<boolean>;

	// Atribute and Observable for la Guinda
	public guinda: boolean = false;
	public guindaObservable$: Observable<boolean>;
	private guindaSubject: Subject<boolean>;

	// Atribute and Observable for la Guinda price
	public guindaPrice: number = 0;
	public guindaPriceObservable$: Observable<number>;
	private guindaPriceSubject: Subject<number>;

	// Atribute and Observable for Random Bet
	public randomBet: boolean = false;
	public randomBetObservable$: Observable<boolean>;
	private randomBetSubject: Subject<boolean>;

	//Atribute and Observable for send mode
	public sendMode: formsEnvioModel;
	public sendModeObservable$: Observable<formsEnvioModel>;
	private sendModeSubject: Subject<formsEnvioModel>;

	//Atribute and Observable for Grupo id
	public grupo_id: number;
	public grupo_id$: Observable<number>;
	public grupo_id_subject: Subject<number>;

	//Atribute and Observable for empresa
	public empresa: loteriaEmpresaModel;
	public empresa$: Observable<loteriaEmpresaModel>;
	public empresaSubject: Subject<loteriaEmpresaModel>;

	//Manual mode Observer
	private manualMode: boolean;
	public manualModeObservable$: Observable<boolean>;
	private manualModeSubject: Subject<boolean>;

	//Manual mode Observer
	public desactivaAutomaticoMode: boolean;
	public desactivaAutomaticoModeObservable$: Observable<boolean>;
	private desactivaAutomaticoModeSubject: Subject<boolean>;

	//Manual mode Observer
	private numberBets: number;
	public numberBetsObservable$: Observable<number>;
	private numberBetsSubject: Subject<number>;

	//Manual mode Observer
	private drawId: number;
	private drawIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public drawIdObservable$: Observable<number> = this.drawIdSubject.asObservable();

	public numberBetsSource: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public observablenumberBets: Observable<number> = this.numberBetsSource.asObservable();

	public betsSource: BehaviorSubject<any> = new BehaviorSubject<any>([]);
	public observableBets: Observable<any> = this.betsSource.asObservable();

	public changeRandomTriggerFunction: EventEmitter<void> = new EventEmitter<void>();

	//Link compra sin stock
	public compraSinStock = "";

	constructor(private http: HttpClient, private authService: AuthenticationService,) {

		super();

		this.optionsSubject = new BehaviorSubject<IBetOptions>(undefined);
		this.optionsObservable$ = this.optionsSubject.asObservable();

		this.totalBetsSubject = new Subject<number>();
		this.totalBetsObservable$ = this.totalBetsSubject.asObservable();

		this.costSubject = new Subject<number>();
		this.costObservable$ = this.costSubject.asObservable();

		this.dateSubject = new Subject<string>();
		this.dateObservable$ = this.dateSubject.asObservable();

		this.sorteoIdSubject = new Subject<number>();
		this.sorteoIdObservable$ = this.sorteoIdSubject.asObservable();

		this.showNumbersSubject = new Subject<ILottery[]>();
		this.showNumbersObservable$ = this.showNumbersSubject.asObservable();

		this.adedDaysSubject = new Subject<Array<{}>>();
		this.addedDaysObservable$ = this.adedDaysSubject.asObservable();

		this.freeBetSubject = new Subject<boolean>();
		this.freeBetObservable$ = this.freeBetSubject.asObservable();

		this.abonoSubject = new Subject<boolean>();
		this.abonoObservable$ = this.freeBetSubject.asObservable();

		this.guindaSubject = new Subject<boolean>();
		this.guindaObservable$ = this.guindaSubject.asObservable();

		this.guindaPriceSubject = new Subject<number>();
		this.guindaPriceObservable$ = this.guindaPriceSubject.asObservable();

		this.randomBetSubject = new Subject<boolean>();
		this.randomBetObservable$ = this.freeBetSubject.asObservable();

		this.manualModeSubject = new Subject<boolean>();
		this.manualModeObservable$ = this.manualModeSubject.asObservable();

		this.desactivaAutomaticoModeSubject = new Subject<boolean>();
		this.desactivaAutomaticoModeObservable$ = this.desactivaAutomaticoModeSubject.asObservable();

		this.numberBetsSubject = new Subject<number>();
		this.numberBetsObservable$ = this.numberBetsSubject.asObservable();

		this.sendModeSubject = new Subject<formsEnvioModel>();
		this.sendModeObservable$ = this.sendModeSubject.asObservable();

		this.grupo_id_subject = new Subject<number>();
		this.grupo_id$ = this.grupo_id_subject.asObservable();

		this.empresaSubject = new Subject<loteriaEmpresaModel>();
		this.empresa$ = this.empresaSubject.asObservable();

		this.bets = [];
		this.simpleBet = true;
		this.multiPlayBetLottery = false;
		this.multiBetNumber = [];
		this.addedDays = [];
		this.cost = 0;
		this.date = "";
		this.sorteoId = 0;
		this.reintegro = -1;
		this.manualMode = false;
		this.desactivaAutomaticoMode = false;
		this.numberBets = 0;

	}

	/**
	 * Set the Options state
	 * @param options Options of the current game
	 */
	public setOptions(options: IBetOptions): void {

		this.options = options;
		this.optionsSubject.next(options);

	}


	/**
	 * Set the loading state
	 * @param manual State of manual bet
	 */
	public setManual(manual: boolean): void {

		this.manualMode = manual;
		this.manualModeSubject.next(this.manualMode);

	}

	/**
   * Set the loading state
   * @param manual State of manual bet
   */
	public setDesactivaAutomatico(desactivaAutomatico: boolean): void {

		this.desactivaAutomaticoMode = desactivaAutomatico;
		this.desactivaAutomaticoModeSubject.next(this.desactivaAutomaticoMode);

	}

	/**
	 * Set the loading state
	 * @param loading State of loading
	 */
	public setLoading(loading: boolean): void {

		this.dataSourceLoading.next(loading);

	}

	/**
	 * Set the days that the bet will be played
	 * @param days Aray of days to play the bets
	 */
	setAddedDays(days: Array<{}>): void {

		this.addedDays = [];
		this.addedDays = days;
		this.adedDaysSubject.next(this.addedDays);
		this.calculateCost();
	}

	/**
	 * Set the cost of the bets
	 * @param cost Cost of the bets
	 */
	setCost(cost: number): void {

		this.cost = cost;
		this.costSubject.next(this.cost);

	}

	setTotalBets(bets: number): void {

		this.totalBets = bets;
		this.totalBetsSubject.next(this.totalBets);

	}

	setDrawId(id: number): void {
		this.drawId = id;
		this.drawIdSubject.next(this.drawId);
	}
	
	setSorteoId(id: number): void {
		this.sorteoId = id;
		this.sorteoIdSubject.next(this.sorteoId);
	}
	/**
	 * Set the cost of the bets
	 * @param cost Cost of the bets
	 */
	setShowNumbers(showNumbers: ILottery[]): void {

		this.showNumbers = showNumbers;
		this.showNumbersSubject.next(this.showNumbers);

	}

	/**
	 * Set the date of the bets
	 * @param date Date of the bets
	 */
	setDate(date: string): void {

		this.date = date;
		this.dateSubject.next(this.date);

	}

	/**
	 * Set the bets that want to be played
	 * @param bets Bets
	 */
	setBets(bets: any): void {
		console.log(this.bets)
		this.bets = bets;
		this.calculateCost();
		this.numberBets = 0;
		this.numberBets = bets.length;

		if (this.bets[0] && this.bets[0].numbersChecked) {
			let betsFiltered = this.bets.filter(bet => bet.numbersChecked.length > 0) //Para que mierda se usa esto // Se usa porque el manual pone todas vacias es un cancer
			this.numberBets = betsFiltered.length;
		}

		this.numberBetsSource.next(this.numberBets);
		this.betsSource.next(this.bets);
		console.log(this.bets)
	}

	/**
	 * Set type of bet for Quiniela
	 * @param status 
	 */
	setBetType(status: boolean): void {
		this.simpleBet = status;
	}

	/**
	 * Set number of doubles and triples for Quiniela bet
	 * @param doubles 
	 * @param triples 
	 */
	setMultiBetNumber(doubles: number, triples: number): void {
		this.multiBetNumber = [doubles, triples];
	}

	/**
	 * Set multi play mode Lotteri 
	 */
	setMultiPlayBetLottery(mode: Boolean) {
		this.multiPlayBetLottery = mode;
	}

	/**
	 * Set send Mode for a ticket of lottery
	 * @param sendMode
	 */
	setSendMode(sendMode: formsEnvioModel): void {
		this.sendMode = sendMode;
		this.sendModeSubject.next(sendMode);
	}

	setGrupoId(grupo_id: number) {
		this.grupo_id = grupo_id;
		this.grupo_id_subject.next(grupo_id);
	}

	setEmpresa(empresa: loteriaEmpresaModel) {
		this.empresa = empresa;
		this.empresaSubject.next(empresa);
	}

	/**
	 * Get number of doubles and triples for Quiniela bet
	 * @returns array of numbers
	 */
	getMultiBetNumber() {
		return this.multiBetNumber;
	}

	/**
	 * Set the reintegro that want to be
	 * @param reintegro Reintegro
	 */
	setReintegro(reintegro: number): void {
		this.reintegro = reintegro;
		this.calculateCost();
	}

	/**
	 * Set the reintegro that want to be
	 * @param reintegro Reintegro
	 */
	setJoker(joker: boolean): void {
		this.joker = joker;
		this.calculateCost();
	}

	/**
	 * Set pleno 15 that want to be
	 * @param pleno15 Pleno 15
	 */
	setPleno15(pleno15: String[]): void {
		this.pleno15 = pleno15;
	}

	/**
	 * Get the reintegro
	 */
	getReintegro(): number {
		return this.reintegro;
	}

	getDate() {
		return this.dateObservable$;
	}

	/**
	 * Get Send Mode selected
	 */
	getSendMode() {
		return this.sendModeObservable$;
	}

	/**
	* Set type of bet (free or not)
	* @param free 
	*/
	setFreeBet(free: boolean): void {

		this.freeBet = free;
		this.freeBetSubject.next(this.freeBet);

	}

	setAbono(abono: boolean): void {

		this.abono = abono;
		this.abonoSubject.next(this.abono);

	}

	setGuinda(guinda: boolean): void {
		this.guinda = guinda;
		this.guindaSubject.next(this.guinda);
	}

	setGuindaPrice(guinda: number): void {
		this.guindaPrice = guinda;
		this.guindaPriceSubject.next(this.guindaPrice);
	}

	setRandomBet(random: boolean): void {

		this.randomBet = random;
		this.randomBetSubject.next(this.randomBet);

	}

	/**
	 * Clear the data from the bets, FreeBet and SendMode
	 */
	clearData(): void {
		this.setBets([]);
		this.setFreeBet(false);
		this.reintegro = -1;
		this.guinda = false;
	}

	clearSendMode() {
		this.setSendMode(undefined);
	}

	/**
	 * Calculate the cost of the current bet stored in the service and update the cost
	 */
	calculateCost(): void {

		let minStars = 0;
		let simpleBetCost = 0;

		switch (this.options.idGame) {
			case 1: // Bonoloto
				simpleBetCost = this.bonolotoSimpleBetCost;
				break;
			case 2: // Bonoloto
				simpleBetCost = this.eurodreamsSimpleBetCost;
				minStars = this.eurodreamsStars;
				break;
			case 3: // Euromillones
				minStars = this.euromillonesMinStars;
				simpleBetCost = this.euromillonesSimpleBetCost;
				break;
			case 4: // Primitiva
				simpleBetCost = this.primitivasSimpleCost;
				break;
			case 5: // El Gordo
				minStars = this.gordoMinStars;
				simpleBetCost = this.gordoSimpleCost;
				break;
			case 6: // Loteria Nacional
				simpleBetCost = this.lotterySimpleTicketCost;
				break;
			case 8: // Loteria Ninyo
				simpleBetCost = this.lotterySimpleTicketCost;
				break;
			case 11: // Quiniela
				simpleBetCost = this.quinielaSimpleCost;
				break;
		}

		let temporalCost = 0;

		let totalBets = 0;

		this.bets.forEach(bet => {
			if (this.options.idGame == 7 || this.options.idGame == 6 || this.options.idGame == 8) {
				if (this.options.idGame == 6 || this.options.idGame == 8)
					this.lotterySimpleTicketCost = 20;
				if (this.empresa) {
					temporalCost += parseInt(this.empresa.precio_decimo);
					if (temporalCost > 0) {
						temporalCost += this.empresa.donacion;
					}
				}
				else {
					temporalCost += this.lotterySimpleTicketCost;
				}	
				
				totalBets += 1;
			}
			else if (this.options.idGame == 11) {
				if (this.simpleBet) {
					temporalCost += this.quinielaSimpleCost;
					totalBets += 1;
				} else {
					temporalCost += (Math.pow(2, this.multiBetNumber[0]) * Math.pow(3, this.multiBetNumber[1])) * simpleBetCost;
					totalBets += (Math.pow(2, this.multiBetNumber[0]) * Math.pow(3, this.multiBetNumber[1]));
				}
			}
			else {
				if (bet.numbersChecked.length >= this.minNumbers && bet.extraChecked.length >= minStars) {

					if (this.options.idGame === EGamesId.EUROMILLONES) { // Si es Euromillones

						temporalCost += this.betEuromillonesTable[bet.numbersChecked.length - this.minNumbers][bet.extraChecked.length - minStars] * simpleBetCost;
						totalBets += this.betEuromillonesTable[bet.numbersChecked.length - this.minNumbers][bet.extraChecked.length - minStars];

					} else if (this.options.idGame === EGamesId.EURODREAMS && bet.extraChecked.length == this.eurodreamsStars) {

						temporalCost += this.betArrayEuroDreams[bet.numbersChecked.length - this.minNumbers] * simpleBetCost;
						totalBets += this.betArrayEuroDreams[bet.numbersChecked.length - this.minNumbers];

					} else if (this.options.idGame === EGamesId.PRIMITIVA && this.reintegro !== -1) {

						temporalCost += this.betArray[bet.numbersChecked.length - this.minNumbers] * simpleBetCost;
						totalBets += this.betArray[bet.numbersChecked.length - this.minNumbers];

					} else if (this.options.idGame === EGamesId.EL_GORDO && bet.extraChecked.length >= minStars) {

						temporalCost += this.betGordoArray[bet.numbersChecked.length - this.minNumbers] * simpleBetCost;
						totalBets += this.betGordoArray[bet.numbersChecked.length - this.minNumbers];

					} else {

						temporalCost += this.betArray[bet.numbersChecked.length - this.minNumbers] * simpleBetCost;
						totalBets += this.betArray[bet.numbersChecked.length - this.minNumbers];

					}
				}
			}

		});

		if (this.options.idGame === EGamesId.PRIMITIVA && this.joker) {
			temporalCost += this.jokerCost;
		}

		if (this.addedDays.length > 0) {

			temporalCost = temporalCost * this.addedDays.length;
			totalBets = totalBets * this.addedDays.length;
		}

		if (this.sendMode && temporalCost > 0)
			temporalCost += Number(this.sendMode.coste);

		this.setCost(temporalCost);
		this.setTotalBets(totalBets);


	}

	/**
	 * Obatain the data from a user bets
	 * @param id User id
	 */
	getPlayData(id: number): any {

		if (id) {

			this.httpHeaders = new HttpHeaders({
				'Content-Type': 'application/json',
			});
			const httpOptions = { headers: this.httpHeaders };
			return this.http.get(`${this.URL}pedidos/${id}/jugadas`, httpOptions);

		}

	}

	removeBet(betIndex: number = 0): void {
		this.bets.splice(betIndex, 1);
		this.calculateCost();
		this.numberBets = this.bets.filter(bet => bet.numbersChecked.length > 0).length;

		this.numberBetsSource.next(this.numberBets);
		this.betsSource.next(this.bets);

	}

	randomizeBet(betIndex: number = 0): void {
		const bet = this.bets[betIndex];
		if (!bet) return;

		const mainMatrix = bet.mainMatrix;
		const numbersCheckedLength = bet.numbersChecked.length;

		if (!mainMatrix || numbersCheckedLength === 0) return;

		const flattenedMatrix = mainMatrix.flat();

		var smallestNumber = Math.min(...flattenedMatrix);

		if(smallestNumber < 1)
			smallestNumber = 1;

		const biggestNumber = Math.max(...flattenedMatrix);

		// Create an array containing all numbers from the smallest to the biggest
		const allNumbers = Array.from({ length: biggestNumber - smallestNumber + 1 }, (_, i) => i + smallestNumber);
	
		// Shuffle the array
		for (let i = allNumbers.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[allNumbers[i], allNumbers[j]] = [allNumbers[j], allNumbers[i]];
		}
	
		// Get the first `numbersCheckedLength` numbers from the shuffled array
		const newNumbersChecked = allNumbers.slice(0, numbersCheckedLength);
	
		// Sort the new numbersChecked array
		newNumbersChecked.sort((a, b) => a - b);

		// Replace the numbersChecked array with the new array
		bet.numbersChecked = newNumbersChecked;

		if (bet.extraChecked.length > 0) {
			this.randomizeExtra(betIndex);
		}
	}
	randomizeExtra(betIndex: number = 0): void {
		const bet = this.bets[betIndex];
		if (!bet) return;
	
		const extraMatrix = bet.extraMatrix;
		const extraCheckedLength = bet.extraChecked.length;
	
		if (!extraMatrix || extraCheckedLength === 0) return;
	
		// Flatten the extraMatrix
		const flattenedExtraMatrix = extraMatrix.flat();
	
		var smallestExtraNumber = Math.min(...flattenedExtraMatrix);

		if(smallestExtraNumber < 1)
			smallestExtraNumber = 1;

		const biggestExtraNumber = Math.max(...flattenedExtraMatrix);
	
		// Create an array containing all numbers from the smallest to the biggest for extraMatrix
		const allExtraNumbers = Array.from({ length: biggestExtraNumber - smallestExtraNumber + 1 }, (_, i) => i + smallestExtraNumber);
	
		// Shuffle the extra numbers array
		for (let i = allExtraNumbers.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[allExtraNumbers[i], allExtraNumbers[j]] = [allExtraNumbers[j], allExtraNumbers[i]];
		}
	
		// Get the first `extraCheckedLength` numbers from the shuffled array
		const newExtraChecked = allExtraNumbers.slice(0, extraCheckedLength);
	
		// Sort the new extraChecked array
		newExtraChecked.sort((a, b) => a - b);
	
		// Replace the extraChecked array with the new sorted array
		bet.extraChecked = newExtraChecked;
	
		console.log('Randomized Extra Finished');
		console.log(this.bets[betIndex]);
	}


	/**
	 * Takes the added days, the numbers on the bet stored in the sevice and plays the bet
	 * @returns the callback from the API
	 */
	public playBet(): Observable<any> {

		if (this.addedDays.length > 0 && this.cost > 0) {

			const token = this.authService.loadToken();
			const tokenJWT = this.authService.decodeToken(token);

			this.httpHeaders = new HttpHeaders({
				'Content-Type': 'application/json',
				token
			});

			let minStars = 0;

			switch (this.options.idGame) {
				case 2: // EuroDreams
					minStars = this.eurodreamsStars;
					break;
				case 3: // Euromillones
					minStars = this.euromillonesMinStars;
					break;
				case 4: // Primitiva
					minStars = this.primitivaMinStars;
					break;
				case 5: // El Gordo
					minStars = this.gordoMinStars;
					break;
			}

			const bets = [];
			const nums = [];

			if (this.options.idGame != 11 && this.options.idGame != 6 && this.options.idGame != 7 && this.options.idGame != 8) {
				this.bets.forEach(bet => {
					if (bet.numbersChecked.length >= this.minNumbers && bet.extraChecked.length >= minStars) {

						if (bet.numbersChecked.length > 0)
							bet.numbersChecked = bet.numbersChecked.sort((a, b) => a - b)

						if (bet.extraChecked.length > 0)
							bet.extraChecked = bet.extraChecked.sort((a, b) => a - b)

						if (this.options.idGame === EGamesId.EL_GORDO) {
							bets.push({
								numeros: bet.numbersChecked,
								estrellas: [],
								reintegro: bet.extraChecked
							});
						} else {
							bets.push({
								numeros: bet.numbersChecked,
								estrellas: bet.extraChecked,
								reintegro: [],
							});
						}

					}

				});
			} else if (this.options.idGame == 6 || this.options.idGame == 7 || this.options.idGame == 8) {
				this.bets.forEach(element => {
					if (element.cantidad_compra > 0) {
						nums.push(element.numero)
					}
				});
				bets.push({
					numeros: nums
				})
			} else {
				this.bets.forEach(bet => {
					bets.push({
						numeros: bet.numeros
					});
				});

			}

			const stringBets = JSON.stringify(bets);
			const stringAddedGames = JSON.stringify(this.addedDays);
			const stringPleno15 = JSON.stringify(this.pleno15);


			let petition = '';

			var body: any;


			if (this.options.idGame === EGamesId.PRIMITIVA) {

				body = {
					'jugadas': `[{"juego_id":${this.options.idGame},"sorteos":${stringAddedGames},"precio":${this.cost},"abono":${this.abono}, "aleatorio":${this.randomBet},"apuestas":${stringBets},"reintegro":${this.reintegro}}]`,
					'origin': "web",
					'jugada_gratis': this.freeBet ? '1' : null,
					'envio_id': this.sendMode ? this.sendMode.id : '1',
					'grupo_id': this.grupo_id ? this.grupo_id : '-1',
					'guinda': this.guinda ? this.guinda : 0,
				}

				//petition = `${this.URL}preview?jugadas=[{"juego_id":${this.options.idGame},"sorteos":${stringAddedGames},"precio":${this.cost},"abono":${this.abono}, "aleatorio":${this.randomBet},"apuestas":${stringBets},"reintegro":${this.reintegro}}]&origin=web` + (this.freeBet ? '&jugada_gratis=true' : '') + (this.sendMode ? `&envio_id=${this.sendMode.id}` : '') + (this.grupo_id ? `&grupo_id=${this.grupo_id}` : '');

			} else if (this.options.idGame === EGamesId.QUINIELA) {

				body = {
					'jugadas': `[{"juego_id":${this.options.idGame},"sorteos":${stringAddedGames},"precio":${this.cost},"abono":false,"pleno_15":${stringPleno15},"apuestas":${stringBets}}]`,
					'origin': "web",
					'jugada_gratis': this.freeBet ? '1' : null,
					'envio_id': this.sendMode ? this.sendMode.id : '1',
					'grupo_id': this.grupo_id ? this.grupo_id : '-1',
					'guinda': this.guinda ? this.guinda : 0,
				}

				//petition = `${this.URL}preview?jugadas=[{"juego_id":${this.options.idGame},"sorteos":${stringAddedGames},"precio":${this.cost},"abono":false,"pleno_15":${stringPleno15},"apuestas":${stringBets}}]&origin=web` + (this.freeBet ? '&jugada_gratis=true' : '') + (this.sendMode ? `&envio_id=${this.sendMode.id}` : '') + (this.grupo_id ? `&grupo_id=${this.grupo_id}` : '');

			} else if (this.options.idGame == EGamesId.LOTERIA_DE_NAVIDAD || this.options.idGame == EGamesId.LOTERIA_DE_EL_NIÑO || this.options.idGame == EGamesId.LOTERIA_NACIONAL) {


				var jugadas = [];
				let modoEnvio = this.sendMode && this.sendMode.sede ? "sede_id" : "envio_id";

				bets[0].numeros.forEach(bet => {

					let costPlay = (this.cost - Number((this.sendMode) ? this.sendMode.coste : 0)) / bets[0].numeros.length

					let stringBet = JSON.stringify([{ numeros: [bet] }]);

					let jugada = `{"juego_id":${this.options.idGame},"sorteos":${stringAddedGames},"precio":${costPlay},"abono":false,"apuestas":${stringBet}}`;

					if (this.options.idGame == EGamesId.LOTERIA_NACIONAL) {
						jugada = `{"juego_id":${this.options.idGame},"sorteos":${stringAddedGames},"precio":${costPlay},"abono":${this.abono}, "aleatorio":${this.randomBet},"apuestas":${stringBet}}`;

					}

					jugadas.push(jugada)

				})

				let envio = (this.sendMode ? `&${modoEnvio}=${this.sendMode.id}` : '');

				body = {
					'jugadas': `[${jugadas}]`,
					'origin': "web",
					'jugada_gratis': this.freeBet ? '1' : null,
					'envio_id': this.sendMode && !this.sendMode.sede ? this.sendMode.id : '-1',
					'sede_id': this.sendMode && this.sendMode.sede ? this.sendMode.id : '-1',
					'grupo_id': this.grupo_id ? this.grupo_id : '-1',
					'empresa_id': this.empresa ? this.empresa.id : '-1',
					'compraSinStock': this.compraSinStock != "" ? this.compraSinStock : null,
					'guinda': this.guinda ? this.guinda : 0,
				}

				//petition = `${this.URL}preview?jugadas=[${jugadas}]&origin=web` + (this.freeBet ? '&jugada_gratis=true' : '') + envio + (this.grupo_id ? `&grupo_id=${this.grupo_id}` : '') + (this.empresa ? `&empresa_id=${this.empresa.id}` : '') + (this.compraSinStock != "" ? `&compraSinStock=${this.compraSinStock}` : '') + (this.guinda ? `&guinda=${this.guinda}` : '');
			}
			else {

				body = {
					'jugadas': `[{"juego_id":${this.options.idGame},"sorteos":${stringAddedGames},"precio":${this.cost},"abono":${this.abono}, "aleatorio":${this.randomBet},"apuestas":${stringBets}}]`,
					'origin': "web",
					'jugada_gratis': this.freeBet ? '1' : null,
					'envio_id': this.sendMode ? this.sendMode.id : '1',
					'grupo_id': this.grupo_id ? this.grupo_id : '-1',
					'empresa_id': this.empresa ? this.empresa.id : '-1',
					'guinda': this.guinda ? this.guinda : 0,
				}
			}

			//petition = `${this.URL}preview?jugadas=[{"juego_id":${this.options.idGame},"sorteos":${stringAddedGames},"precio":${this.cost},"abono":${this.abono}, "aleatorio":${this.randomBet},"apuestas":${stringBets}}]&origin=web` + (this.freeBet ? '&jugada_gratis=true' : '') + (this.sendMode ? `&envio_id=${this.sendMode.id}` : '') + (this.grupo_id ? `&grupo_id=${this.grupo_id}` : '') + (this.empresa ? `&empresa_id=${this.empresa.id}` : '');

			body = JSON.stringify(body)
			return this.http.post(`${this.URL}newPreview`, body, { headers: this.httpHeaders, responseType: 'text' })

			//return this.http.get(petition, { headers: this.httpHeaders, responseType: 'text' });

		}

		return null;

	}

	/**
	 * * Takes the bets, days, id game, cost and refund and repeat bet
	 */
	public repeatPlay(playId: Number, grupo_id = -1, todaSemana = 0, escaneada = false): Observable<any> {

		let params = { jugada_id: playId, grupo_id, todaSemana }
		const token = this.authService.loadToken();

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		if (escaneada)
			return this.http.post(`${this.URL}repetir_escaneada`, params, { headers: this.httpHeaders })
		return this.http.post(`${this.URL}repetir_jugada`, params, { headers: this.httpHeaders })
	}

	public checkPlay(empresa_id){

		const token = this.authService.loadToken();

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		let numeros = []
		let params = { numeros }

		this.bets.forEach(element => {
			if (element.cantidad_compra > 0) {
				numeros.push(element.numero)
			}
		});

		return this.http.post(`${this.URL}empresas/check/${empresa_id}`, params, { headers: this.httpHeaders })
	}

	/**
	 * Saves the bet data to local storage
	 */
	public saveBetData(){
		localStorage.setItem('addedDays', JSON.stringify(this.addedDays));
		localStorage.setItem('bets', JSON.stringify(this.bets));
		localStorage.setItem('options', JSON.stringify(this.options));
	}

}
