import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector:'app-modalyesno',
    templateUrl:'./modalYesNo.component.html',
    styleUrls:['./modalYesNo.component.scss']
})
export class modalYesNoComponent
{
    constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string,texto: string, buttonConfirm: string, btnConfirmColor: string,  buttonCancel: string, showIcon: boolean, showOptionCheck: boolean}){}

    public dangerBtn()
    {
        if(this.data.btnConfirmColor == 'red')
            return true;
        return false;
    }

    public groupMode()
    {
        if(this.data.btnConfirmColor == 'cool')
            return true;
        return false;
    }
}